import React from "react";
import { Link, NavLink, Switch, Route, useRouteMatch } from "react-router-dom";

import Profile from "./Profile";

import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 25px 25px 50px;
`;

const Tabs = styled.div`
  margin-top: 25px;

  .settings-link {
    display: inline-block;
    color: #999;
    text-decoration: none;
    padding: 10px 15px;

    &:hover {
      color: #333;
    }

    &.active {
      color: #333;
      border-bottom: 2px solid #999;
    }
  }
`;

const Settings = props => {
  let { path, url } = useRouteMatch();

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb active">Account</h1>
          </div>

          <div className="actions"></div>
        </div>
      </div>

      <div className="container">
        <Tabs>
          <NavLink
            exact
            to={"/account"}
            className="settings-link"
            activeClassName="active"
          >
            Profile
          </NavLink>
        </Tabs>

        <Container>
          <Switch>
            <Route exact path={path}>
              <Profile {...props} />
            </Route>
          </Switch>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
