import React from "react";
import { useDrag } from "react-dnd";

import styled from "styled-components";

const Container = styled.div`
  flex: 1;
  border: 1px dashed #999;
  background-color: rgb(64, 64, 64);
  color: #fff;
  cursor: move;
  text-align: center;
  max-width: 31%;
  min-width: 31%;
  margin-bottom: 3%;
  padding: 10px 0px;

  &:hover {
    background-color: rgb(48, 48, 48);
  }
`;

const Icon = styled.div`
  font-size: 24px;
  padding: 0px 10px 5px;
`;

const Title = styled.div`
  font-size: 12px;
  color: rgb(166, 166, 166);
  padding: 0px 5px;
  height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Box = ({
  name,
  iconClass,
  htmlTag,
  htmlTagAttributes = {},
  componentId,
  setDropComponentId
}) => {
  const [{ isDragging }, drag] = useDrag({
    item: { htmlTag, htmlTagAttributes, componentId, type: "box" },
    end: (item, monitor) => {
      setDropComponentId(null);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <Container ref={drag} style={{ opacity }}>
      {iconClass && (
        <Icon>
          <i className={iconClass}></i>
        </Icon>
      )}
      <Title>{name || htmlTag}</Title>
    </Container>
  );
};
export default Box;
