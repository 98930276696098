import React from "react";

const Color = ({ id, name, value, onEditColor, onDeleteColor }) => {
  return (
    <div className="list-item">
      <div className="preview">
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: value,
            border: "1px solid #CCC"
          }}
        ></div>
      </div>

      <div className="list-info">
        <div className="title">{name}</div>
        <div className="description">{value}</div>
      </div>

      <div className="actions">
        <a
          className="action-item"
          onClick={() => onEditColor({ id, name, value })}
        >
          <i className="action-icon fas fa-edit"></i>
        </a>
        <a className="action-item" onClick={() => onDeleteColor(id)}>
          <i className="action-icon fas fa-trash"></i>
        </a>
      </div>
    </div>
  );
};

export default Color;
