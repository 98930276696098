import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch
} from "react-router-dom";

import Projects from "./Projects";
import Project from "./Project";
import NewProject from "./NewProject";

const ProjectContainer = props => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewProject {...props} />
      </Route>
      <Route exact path={`${path}/:projectId`}>
        <Project {...props} />
      </Route>
      <Route exact path={path}>
        <Projects {...props} />
      </Route>
    </Switch>
  );
};

export default ProjectContainer;
