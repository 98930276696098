import React, { useState } from "react";

import Modal from "../../common/Modal";

const CreateNewComponent = ({
  projectId,
  createComponent,
  refetchChildComponents,
  createOptions,
  setCreateOptions,
  createModalIsOpen,
  setCreateModalIsOpen,
  setActiveComponentId
}) => {
  const [selector, setSelector] = useState(null);
  const [selectorError, setSelectorError] = useState(false);
  const [textContent, setTextContent] = useState(null);

  const externalComponent = !!createOptions.externalComponentId;

  const options = {
    projectId: parseInt(projectId),
    selector: externalComponent ? null : selector,
    textContent,
    ...createOptions
  };

  const isHeadingTag = tag => {
    return ["h1", "h2", "h3", "h4", "h5", "h6"].indexOf(tag) > -1;
  };

  return (
    <Modal
      isOpen={createModalIsOpen}
      closeModal={() => setCreateModalIsOpen(false)}
    >
      <form
        className="form"
        onSubmit={async e => {
          e.preventDefault();

          if (!externalComponent && !selector) {
            setSelectorError(true);
            return;
          }

          const response = await createComponent({
            variables: options
          });

          if (
            response &&
            response.data &&
            response.data.createComponent &&
            response.data.createComponent.success
          ) {
            await refetchChildComponents();
            setCreateModalIsOpen(false);

            const component = response.data.createComponent.component;
            setActiveComponentId(component.id);
          }
        }}
      >
        <div className="modal-top">
          <div className="modal-title">
            <h3 className="headline">Add Component</h3>
          </div>
          <div className="modal-body">
            {!createOptions.externalComponentId ? (
              <React.Fragment>
                {isHeadingTag(createOptions.htmlTag) && (
                  <div className="form-group">
                    <label className="label">Heading Tag</label>
                    <select
                      className="form-select"
                      name="htmlTag"
                      defaultValue={createOptions.htmlTag}
                      onChange={e =>
                        setCreateOptions({
                          ...createOptions,
                          htmlTag: e.target.value
                        })
                      }
                    >
                      <option value="h1">H1</option>
                      <option value="h2">H2</option>
                      <option value="h3">H3</option>
                      <option value="h4">H4</option>
                      <option value="h5">H5</option>
                      <option value="h6">H6</option>
                    </select>
                  </div>
                )}

                <div className="form-group">
                  <label className="label">Selector Class</label>
                  <input
                    className="form-control"
                    type="text"
                    name="selector"
                    defaultValue={selector}
                    onChange={e => {
                      setSelector(e.target.value);
                      setSelectorError(false);
                    }}
                  />
                  {!selectorError && (
                    <p className="hint">
                      This would be your CSS class name. Examples: .form,
                      .container
                    </p>
                  )}
                  {selectorError && <p className="hint error">Required</p>}
                </div>

                <div className="form-group">
                  <label className="label">Default Value</label>
                  <input
                    className="form-control"
                    type="text"
                    name="textContent"
                    defaultValue={textContent}
                    onChange={e => setTextContent(e.target.value)}
                  />
                  <p className="hint">
                    {createOptions.htmlTag == "img"
                      ? "URL for the image"
                      : "Default value of this tag"}
                  </p>
                </div>
              </React.Fragment>
            ) : (
              <p className="modal-text">
                Are you sure you want to add this component?
              </p>
            )}
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn" onClick={() => setCreateModalIsOpen(false)}>
            Cancel
          </button>{" "}
          <button className="btn btn-primary" type="submit">
            Save
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default CreateNewComponent;
