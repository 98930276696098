import React from "react";

import Select from "react-select";

import classnames from "classnames";

import { EditorContainer, EditorTitle, DropdownContainer } from "../styles";

const Editor = ({ name, title, value, onChange, isChanged, hasValue }) => {
  const values = [
    { label: "Block", value: "block" },
    { label: "Flex", value: "flex" },
    { label: "Grid", value: "grid" },
    { label: "Inline Block", value: "inline-block" },
    { label: "Inline Flex", value: "inline-flex" },
    { label: "Inline Table", value: "inline-table" },
    { label: "Inline", value: "inline" },
    { label: "Table", value: "table" },
    { label: "None", value: "none" },
  ];

  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>
      <DropdownContainer>
        <Select
          defaultValue={value ? values.find((w) => w.value == value) : null}
          isClearable={true}
          isSearchable={true}
          name={name}
          onChange={(data) => onChange(name, data ? data.value : null)}
          options={values}
          classNamePrefix="react-select"
        />
      </DropdownContainer>
    </EditorContainer>
  );
};
export default Editor;
