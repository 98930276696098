import React, { useState, useRef, useEffect } from "react";

import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";
import CodeMirror from "codemirror/lib/codemirror";

import styled from "styled-components";

const Container = styled.div`
  .CodeMirror {
    border: 1px solid #ccc;
    height: auto;

    .CodeMirror-sizer {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
`;

const Well = styled.div`
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 30px 0px;

  &:last-child {
    margin-bottom: 0px;
  }

  .header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  p {
    font-size: 14px;
  }
`;

const Step = (props) => {
  const { project, goToNextStep, currentStep, setHideOnboarding } = props;

  const [submitting, setSubmitting] = useState(false);
  const cssFileTextarea = useRef(null);
  const jsFileTextArea = useRef(null);

  useEffect(() => {
    CodeMirror.fromTextArea(cssFileTextarea.current, {
      mode: { name: "htmlmixed" },
      lineNumbers: true,
      lineWrapping: true,
      readOnly: true,
    });

    CodeMirror.fromTextArea(jsFileTextArea.current, {
      mode: { name: "htmlmixed" },
      lineNumbers: true,
      lineWrapping: true,
      readOnly: true,
    });
  }, [project.id]);

  return (
    <>
      <Container>
        <Well>
          <div className="header">CSS</div>

          <form className="form">
            <div className="form-group">
              <p>
                {
                  "Copy-paste the stylesheet <link> into your <head> before all other stylesheets to load our CSS."
                }
              </p>

              <textarea
                ref={cssFileTextarea}
                defaultValue={`<link rel="stylesheet" type="text/css" href="${project.fullStylesUrl}" crossorigin="anonymous" />`}
              ></textarea>
            </div>

            <div className="header">JS</div>

            <div className="form-group">
              <p>
                {
                  "Place the following <script> near the end of your pages, right before the closing </body> tag."
                }
              </p>

              <textarea
                ref={jsFileTextArea}
                defaultValue={`<script src="${project.fullJavascriptUrl}" crossorigin="anonymous"></script>`}
              ></textarea>
            </div>
          </form>
        </Well>
      </Container>
      <div className="onboarding-action">
        <a
          className={
            submitting ? "btn btn-dark btn-lg" : "btn btn-primary btn-lg"
          }
          onClick={async () => {
            setSubmitting(true);
            setHideOnboarding(true);
            goToNextStep(currentStep);
          }}
        >
          {submitting ? <i className="fas fa-spinner fa-spin"></i> : "Done"}
        </a>
      </div>
    </>
  );
};

export default Step;
