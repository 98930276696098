import React from "react";

const Div = ({
  className,
  style,
  onClick,
  onDoubleClick,
  onMouseOver,
  onMouseOut,
  children,
  layerRef
}) => {
  return (
    <div
      ref={layerRef}
      className={className}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    >
      {children}
    </div>
  );
};

export default Div;
