import React, { useState, useContext, useEffect } from "react";
import { DndProvider, DndContext } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import Frame, { FrameContext } from "react-frame-component";
import { StyleSheetManager } from "styled-components";

let lastHeight;

const DndFrame = ({
  children,
  project,
  setHeight,
  forceUpdate,
  libFileVersion
}) => {
  const { dragDropManager } = useContext(DndContext);
  const { window, document } = useContext(FrameContext);

  const updateHeight = () => {
    if (window.document.body.scrollHeight != lastHeight) {
      lastHeight = window.document.body.scrollHeight;
      setHeight(window.document.body.scrollHeight);
    }
  };

  useEffect(() => {
    dragDropManager.getBackend().addEventListeners(window);
  });

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URI}/assets/${project.id}/full.js`;
    if (document.head.querySelectorAll(`script[src='${url}']`).length == 0) {
      var link = document.createElement("script");
      link.type = "text/javascript";
      link.src = url;
      document.head.appendChild(link);
    }
  });

  useEffect(() => {
    const stylesheets = document.head.querySelectorAll("link");
    const cssLink = [...stylesheets].find(s => s.href.indexOf("full.css") > -1);
    cssLink.href = `${cssLink.href.split("?")[0]}?v=${libFileVersion}`;
  }, [libFileVersion]);

  useEffect(() => {
    lastHeight = null;

    window.onload = () => {
      updateHeight();
      forceUpdate();
    };

    const id = setInterval(function() {
      updateHeight();
    }, 200);

    return () => clearInterval(id);
  });

  return (
    <StyleSheetManager target={document.head}>{children}</StyleSheetManager>
  );
};

const PreviewFrame = props => {
  const { iframeRef, forceUpdate, project } = props;
  const [height, setHeight] = useState(10);

  useEffect(() => {
    forceUpdate();
  }, [height]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Frame
        ref={iframeRef}
        style={{
          width: "100%",
          height: `${height}px`,
          border: 0,
          display: "block"
        }}
        onLoad={() => forceUpdate()}
        initialContent={`<!DOCTYPE html><html><head><link rel="stylesheet" type="text/css" href="${process.env.REACT_APP_API_URI}/assets/${project.id}/full.css" /><style>html, body { padding: 0px; margin: 0px; background-color: transparent !important; } .monad-empty { outline: 1px dashed #555 !important; outline-offset: -1px !important; box-shadow: inset 0 0 0 4px rgba(0, 0, 0, 0.1), inset 0 0 0 4px rgba(255, 255, 255, 0.2), inset 0 0 0 1px rgba(255, 255, 255, 0.3); padding-bottom: 75px; padding-right: 75px; font-size: 0; line-height: 0; vertical-align: middle; } .frame-content > * { margin: 0 auto; }</style></head><body><div></div></body></html>`}
      >
        <DndFrame {...props} setHeight={setHeight} forceUpdate={forceUpdate} />
      </Frame>
    </DndProvider>
  );
};

export default PreviewFrame;
