import React, { useState, useEffect } from "react";

import Tags from "./Tags";
import Layers from "./Layers";

import classnames from "classnames";
import styled from "styled-components";

const TabsContainer = styled.div`
  width: 300px;
  display: flex;
  max-width: 300px;
  background-color: rgb(64, 64, 64);

  .tabs {
    flex: 1 1 0%;
    max-width: 35px;
    background-color: rgb(43, 43, 43);

    .tab {
      cursor: pointer;
      color: rgb(255, 255, 255);
      width: 35px;
      height: 35px;
      display: flex;
      font-size: 20px;
      text-align: center;
      line-height: 35px;
      background-color: #383838;

      &.active {
        background-color: #535353;
      }

      .tab-link {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        .icon {
          flex: 1;
          width: 19px;
          height: 20px;
          display: block;
          max-width: 19px;
        }
      }
    }
  }

  .panels {
    flex: 1;
    border: 1px solid rgb(83, 83, 83);
    padding: 5px 0px;
    overflow-y: auto;
    background-color: rgb(83, 83, 83);

    .panel {
      display: none;

      &.active {
        display: block;
      }
    }
  }
`;

const tabs = {
  tags: Tags,
  layers: Layers
};

const Tabs = props => {
  const { activeComponentId } = props;
  const [activeTab, setActiveTab] = useState("tags");

  useEffect(() => {
    if (activeComponentId) {
      setActiveTab("layers");
    }
  }, [activeComponentId]);

  return (
    <TabsContainer>
      <div className="tabs">
        {Object.keys(tabs).map(t => (
          <div
            key={`tabs${t}`}
            className={`tab tab-${t} ${activeTab == t ? "active" : ""}`}
          >
            <a className="tab-link" onClick={() => setActiveTab(t)}>
              <i
                className={classnames({
                  fas: true,
                  "fa-plus-square": t == "tags",
                  "fa-layer-group": t == "layers"
                })}
              ></i>
            </a>
          </div>
        ))}
      </div>

      <div className="panels">
        {Object.keys(tabs).map(t => {
          const Cursor = tabs[t];
          return (
            <div
              key={`panel${t}`}
              className={`panel ${activeTab == t ? "active" : ""}`}
            >
              <Cursor {...props} />
            </div>
          );
        })}
      </div>
    </TabsContainer>
  );
};

export default Tabs;
