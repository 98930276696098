import React, { useState, useEffect } from "react";

import Progress from "./Progress";
import Colors from "./Colors";
import Fonts from "./Fonts";
import Typography from "./Typography";
import Storage from "./Storage";
import Publish from "./Publish";

import { useMutation } from "@apollo/client";

import { COMPLETE_ONBOARDING_STEP } from "./queries";

import styled from "styled-components";

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: "Inter", sans-serif;
  color: #41415a;

  .inner-container {
    max-width: 700px;
    flex: 1;

    h1 {
      font-weight: 400;
      margin: 0px;
      margin-bottom: 10px;
    }

    .onboarding-description {
      color: rgb(130, 130, 140);
      font-size: 16px;
      margin-bottom: 30px;
    }

    .onboarding-action {
      margin-top: 50px;
      padding-top: 20px;
      border-top: 1px solid #eee;
    }

    .onboarding-step {
      min-height: 520px;
    }
  }
`;

const ALL_STEPS = {
  fonts: {
    title: "Base Font",
    description:
      "Choose the base font family, size and weight for your project.",
    component: Fonts,
  },
  colors: {
    title: "Colors",
    description: "Choose the default colors for your project.",
    component: Colors,
  },
  publish: {
    title: "Get Started",
    description: "Add the following CSS and JS files to your project.",
    component: Publish,
  },
};

const OnboardingContainer = (props) => {
  const [hideOnboarding, setHideOnboarding] = useState(false);
  const [currentStep, setCurrentStep] = useState(null);
  const {
    projectId,
    project: { onboardingSteps },
  } = props;

  const [completeOnboardingStep] = useMutation(COMPLETE_ONBOARDING_STEP);

  const steps = Object.keys(ALL_STEPS);

  const getNextIncompleteStep = () => {
    let nextIncompleteStep;
    for (let step of steps) {
      if (onboardingSteps.indexOf(step) < 0) {
        nextIncompleteStep = step;
        break;
      }
    }
    return nextIncompleteStep;
  };

  const getNextStep = () => {
    let currentStepIndex = steps.indexOf(currentStep);
    return steps[currentStepIndex + 1];
  };

  useEffect(() => {
    if (!currentStep) {
      setCurrentStep(getNextIncompleteStep());
    }
  }, [currentStep]);

  if (hideOnboarding || !currentStep) return null;

  const title = ALL_STEPS[currentStep].title;
  const description = ALL_STEPS[currentStep].description;
  const RenderStep = ALL_STEPS[currentStep].component;

  const goToNextStep = async (name) => {
    await completeOnboardingStep({
      variables: {
        projectId,
        name,
      },
    });
    setCurrentStep(getNextStep());
  };

  const stepProps = {
    ...props,
    steps,
    currentStep,
    setCurrentStep,
    setHideOnboarding,
    goToNextStep,
    allSteps: ALL_STEPS,
  };

  return (
    <Container>
      <div className="inner-container">
        <Progress {...stepProps} />
        <h1>{title}</h1>
        <div className="onboarding-description">{description}</div>

        <div className="onboarding-step">
          <RenderStep {...stepProps} />
        </div>
      </div>
    </Container>
  );
};

export default OnboardingContainer;
