import { gql } from "@apollo/client";

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $email: String
    $name: String
    $currentPassword: String
    $newPassword: String
  ) {
    updateUser(
      input: {
        email: $email
        name: $name
        currentPassword: $currentPassword
        newPassword: $newPassword
      }
    ) {
      success
      error
      token
      user {
        id
        email
        name
      }
    }
  }
`;
