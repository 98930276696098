import React, { useState } from "react";

import classnames from "classnames";

import { Col, Row, EditorContainer, EditorTitle } from "../styles";

import Size from "./Size";
import BorderStyle from "./BorderStyle";
import ColorPicker from "./ColorPicker";

import styled from "styled-components";

const BorderContainer = styled.div``;

const BorderSides = styled.div`
  position: relative;
  height: 105px;
  width: 105px;
  font-size: 22px;
  color: #fff;

  .icon-container {
    padding: 2px 5px;
    cursor: pointer;

    &.active {
      background-color: #333;
    }
  }

  .border-top,
  .border-bottom {
    position: absolute;
    left: 0;
    right: 0;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-top {
    top: 0;
  }

  .border-bottom {
    bottom: 0;
  }

  .border-left,
  .border-right {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .border-left {
    left: 0;
  }

  .border-right {
    right: 0;
  }

  .border-outer {
    position: absolute;
    top: 35px;
    left: 35px;
    bottom: 35px;
    right: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Editor = ({ style, onChange, project }) => {
  const [activeSide, setActiveSide] = useState("outer");

  const { active, inherited } = style;

  const values = {
    ...inherited,
    ...active
  };

  const isChanged = name => !!active[name];
  const hasValue = name => !!values[name];

  const activeKey = activeSide == "outer" ? "border" : `border-${activeSide}`;

  return (
    <>
      <EditorContainer>
        <EditorTitle>
          <span>Borders</span>
        </EditorTitle>
      </EditorContainer>
      <BorderContainer>
        <Row>
          <Col style={{ paddingTop: 5 }}>
            <BorderSides>
              <div className="border-left">
                <div
                  className={classnames({
                    "icon-container": true,
                    active: activeSide == "left"
                  })}
                  onClick={() => setActiveSide("left")}
                >
                  <i className="fad fa-border-left"></i>
                </div>
              </div>
              <div className="border-top">
                <div
                  className={classnames({
                    "icon-container": true,
                    active: activeSide == "top"
                  })}
                  onClick={() => setActiveSide("top")}
                >
                  <i className="fad fa-border-top"></i>
                </div>
              </div>
              <div className="border-outer">
                <div
                  className={classnames({
                    "icon-container": true,
                    active: activeSide == "outer"
                  })}
                  onClick={() => setActiveSide("outer")}
                >
                  <i className="fad fa-border-outer"></i>
                </div>
              </div>
              <div className="border-right">
                <div
                  className={classnames({
                    "icon-container": true,
                    active: activeSide == "right"
                  })}
                  onClick={() => setActiveSide("right")}
                >
                  <i className="fad fa-border-right"></i>
                </div>
              </div>
              <div className="border-bottom">
                <div
                  className={classnames({
                    "icon-container": true,
                    active: activeSide == "bottom"
                  })}
                  onClick={() => setActiveSide("bottom")}
                >
                  <i className="fad fa-border-bottom"></i>
                </div>
              </div>
            </BorderSides>
          </Col>

          <Col style={{ flex: 2, maxWidth: "60%" }}>
            <BorderStyle
              key={`${activeKey}-style`}
              name={`${activeKey}-style`}
              title="Style"
              value={values[`${activeKey}-style`]}
              isChanged={isChanged(`${activeKey}-style`)}
              hasValue={hasValue(`${activeKey}-style`)}
              onChange={onChange}
            />

            <Size
              key={`${activeKey}-width`}
              name={`${activeKey}-width`}
              title="Size"
              value={values[`${activeKey}-width`]}
              isChanged={isChanged(`${activeKey}-width`)}
              hasValue={hasValue(`${activeKey}-width`)}
              onChange={onChange}
            />

            <ColorPicker
              key={`${activeKey}-color`}
              project={project}
              name={`${activeKey}-color`}
              title="Color"
              value={values[`${activeKey}-color`]}
              isChanged={isChanged(`${activeKey}-color`)}
              hasValue={hasValue(`${activeKey}-color`)}
              onChange={onChange}
            />
          </Col>
        </Row>
      </BorderContainer>
    </>
  );
};
export default Editor;
