import React from "react";

const Font = ({ id, name, value, url, onEditFont, onDeleteFont }) => {
  return (
    <>
      <link href={url} rel="stylesheet" />
      <div className="list-item">
        <div className="list-info">
          <div className="title" style={{ fontFamily: value }}>
            {name}
          </div>
          <div className="description">{value}</div>
        </div>

        <div className="actions">
          <a
            className="action-item"
            onClick={() => onEditFont({ id, name, value, url })}
          >
            <i className="action-icon fas fa-edit"></i>
          </a>
          <a className="action-item" onClick={() => onDeleteFont(id)}>
            <i className="action-icon fas fa-trash"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Font;
