import React from "react";

import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";

import { CREATE_COMPONENT, ROOT_COMPONENTS_QUERY } from "./queries";

import Loader from "../../components/common/Loader";
import tags from "../../components/common/tags.json";

import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
`;

const NewComponent = ({ projectId, history }) => {
  const [createComponent] = useMutation(CREATE_COMPONENT);

  const { loading, error, data } = useQuery(ROOT_COMPONENTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Components /</h1>
            <h1 className="breadcrumb active">New Component</h1>
          </div>
        </div>
      </div>

      <Container className="container">
        <Formik
          initialValues={{ htmlTag: "div", position: 0, selector: "" }}
          validate={values => {
            const errors = {};
            if (!values.htmlTag) {
              errors.htmlTag = "Required";
            }

            if (!values.selector) {
              errors.selector = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const response = await createComponent({
              variables: {
                ...values,
                position: parseInt(values.position),
                projectId: parseInt(projectId)
              }
            });
            if (
              response &&
              response.data &&
              response.data.createComponent &&
              response.data.createComponent.success
            ) {
              const componentId = response.data.createComponent.component.id;
              history.push(`/components/${componentId}`);
              return;
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <label className="label">HTML Tag</label>
                <select
                  name="htmlTag"
                  className="form-control"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.htmlTag}
                >
                  {tags.map(t => (
                    <option value={t.value}>{t.label}</option>
                  ))}
                </select>
                {errors.htmlTag && touched.htmlTag && (
                  <p className="hint error">{errors.htmlTag}</p>
                )}
              </div>

              <div className="form-group">
                <label className="label">Selector</label>
                <input
                  className="form-control"
                  type="text"
                  name="selector"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.selector}
                />
                {!(errors.selector && touched.selector) && (
                  <p className="hint">
                    CSS Selector for this component. For example: .btn, .modal
                  </p>
                )}
                {errors.selector && touched.selector && (
                  <p className="hint error">{errors.selector}</p>
                )}
              </div>

              <div>
                <input
                  type="hidden"
                  name="position"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.position}
                />
                {errors.position && touched.position && errors.position}
              </div>

              <button
                className={isSubmitting ? "btn btn-dark" : "btn btn-primary"}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating" : "Create Component"}
              </button>
            </form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default NewComponent;
