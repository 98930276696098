import Div from "./Div";
import Span from "./Span";
import Img from "./Img";
import Form from "./Form";
import Input from "./Input";
import Textarea from "./Textarea";
import Select from "./Select";
import Option from "./Option";
import Ol from "./Ol";
import Ul from "./Ul";
import Li from "./Li";

export default {
  div: Div,
  span: Span,
  img: Img,
  form: Form,
  input: Input,
  textarea: Textarea,
  select: Select,
  option: Option,
  ol: Ol,
  ul: Ul,
  li: Li
};
