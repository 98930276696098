import { isEqual, remove } from "lodash";

// Combine properties from multiple style rows
const combineStyles = styles => {
  let style = {};
  styles.forEach(s => {
    style = {
      ...style,
      ...s.properties
    };
  });
  return style;
};

// Sorting Styles based on Inheritance
const sortStyles = styles => {
  // SORTING
  // Sorting Priority
  // 1. Device
  // 2. Number of selectors
  // 3. Variation
  //
  // .btn-text desktop
  // .btn-text desktop 1
  // .btn-text:hover desktop
  // .btn-text:hover desktop 1
  // .btn-text:hover mobile
  // .btn-text:hover mobile 1

  // var test = [
  //   {selectors: [".btn-text"], device: "desktop", variation: null},
  //   {selectors: [".btn-text", ":hover"], device: "desktop", variation: null},
  //   {selectors: [".btn-text"], device: "mobile", variation: null},
  //   {selectors: [".btn-text", ":hover"], device: "mobile", variation: null},
  //   {selectors: [".btn-text"], device: "desktop", variation: 1},
  //   {selectors: [".btn-text", ":hover"], device: "desktop", variation: 1},
  //   {selectors: [".btn-text"], device: "mobile", variation: 1},
  //   {selectors: [".btn-text", ":hover"], device: "mobile", variation: 1},
  // ]

  return styles.slice().sort(function(a, b) {
    if (a.device == b.device) {
      if (isEqual(a.selectors, b.selectors)) {
        return (a.variation || 0) > (b.variation || 0) ? 1 : -1;
      }
      return a.selectors.length > b.selectors.length ? 1 : -1;
    }
    return a.device > b.device ? 1 : -1;
  });
};

// Get styles for exact combination of selectors
// For example, if selectors are .btn and .btn-success,
// This will get styles for a combination of those
const getStylesForSelectors = (styles, selectors, device, variationId) => {
  let output = styles.filter(s => isEqual(s.selectors, selectors));
  output = output.filter(s => s.variationId == variationId);
  output = output.filter(s => s.device == device.toUpperCase());
  return sortStyles(output);
};

// Run through all selectors and get inherited styles for the chain of
// selectors. For example, for .btn and .btn-success, it will first
// get styles for .btn and then for .btn and .btn-success both
const getInheritedStylesForSelectors = (
  styles,
  selectors,
  device,
  variationId,
  isOverride
) => {
  // .btn-text mobile null
  // Inheritance required:
  // - .btn-text desktop

  // .btn-text mobile 1
  // Inheritance required:
  // - .btn-text desktop
  // - .btn-text desktop 1
  // - .btn-text mobile

  // .btn-text desktop 1
  // Inheritance required:
  // - .btn-text desktop

  // Inherited selectors would include all selectors except last
  // If this is an override based on device or variation,
  // then all selectors would be included because then we need to
  // inherit from all selectors, active changes would  be in the
  // override instead.
  let inheritedSelectors = isOverride ? selectors : selectors.slice(0, -1);

  let classSelectors = inheritedSelectors.filter(s => s.startsWith("."));
  let stateSelectors = inheritedSelectors.filter(s => s.startsWith(":"));

  let output = [];

  let currentSelector = [];
  classSelectors.forEach(s => {
    currentSelector.push(s);
    output = output.concat(
      styles.filter(
        s => isEqual(s.selectors, currentSelector) && s.device == "DESKTOP"
      )
    );

    if (device != "desktop") {
      output = output.concat(
        styles.filter(
          s =>
            isEqual(s.selectors, currentSelector) &&
            s.device == device.toUpperCase()
        )
      );
    }
  });

  // Now that class selectors are done, we'll get styles for class selectors WITH state selectors
  // Such as .btn:hover
  currentSelector = [];
  classSelectors.forEach(s => {
    currentSelector.push(s);
    // with :hover or other state selectors
    stateSelectors.forEach(ss => {
      output = output.concat(
        styles.filter(
          s =>
            isEqual(s.selectors, [...currentSelector, ss]) &&
            s.device == "DESKTOP"
        )
      );
    });

    if (device != "desktop") {
      // with :hover or other state selectors
      stateSelectors.forEach(ss => {
        output = output.concat(
          styles.filter(
            s =>
              isEqual(s.selectors, [...currentSelector, ss]) &&
              s.device == device.toUpperCase()
          )
        );
      });
    }
  });

  output = sortStyles(output);

  // If this is an override based on device or variation,
  // we do not need to include the exact style match with device,
  // variation and selectors because that would be the active style.
  if (isOverride) {
    remove(
      output,
      s =>
        s.device == device.toUpperCase() &&
        s.variationId == variationId &&
        s.selectors.length == selectors.length
    );
  }

  return output;
};

export const getStyles = (styles, selectors, variationId, device, log) => {
  // We divide styles in 2 sections
  // Inherited and Active
  // Inherited styles are from all selectors other than the last one
  // Active styles include the last selector

  // [".btn", ".btn-succes", ":hover"]
  // Inherited: [".btn", ".btn-succes"]
  // Active: [".btn", ".btn-succes", ":hover"]

  // We only need styles from root (no variation) and active variation
  // Exclude any styles that have a variation but not the selected variation
  let data = sortStyles(
    styles.filter(s => !s.variationId || s.variationId == variationId)
  );

  // Override key determines whether the active element is a overriding styles
  // based on device or variationId. We only consider non-desktop devices
  // an override because desktop is default
  let overrides = [];
  if (device != "desktop") overrides.push(device);
  if (variationId) overrides.push(variationId);
  let isOverride = overrides.length > 0;

  // Inherited would include all selectors except last
  // In case this is an override, then all selectors would be included
  // Because then we need to inherit from all selectors, active changes
  // would be in the override
  // let inheritedSelectors = isOverride
  //   ? activeSelectors
  //   : activeSelectors.slice(0, -1);

  let inheritedStyles = getInheritedStylesForSelectors(
    data,
    selectors,
    device,
    variationId,
    isOverride
  );

  if (log) console.log("*** inherited", selectors, inheritedStyles);

  // Active styles are filtered based on all selectors including
  // variation and device if available
  let activeStyles = getStylesForSelectors(
    data,
    selectors,
    device,
    variationId
  );

  if (log) console.log("*** active", selectors, activeStyles);

  return {
    inherited: combineStyles(inheritedStyles),
    active: combineStyles(activeStyles)
  };
};

export const getStyleProperties = styles => {
  return combineStyles(sortStyles(styles));
};
