import React from "react";
import { useDrop } from "react-dnd";

import { isVoidElement } from "../../../common/utils";

import Component from "./Component";

const Layer = props => {
  const {
    component,
    rootComponent,
    activeComponentId,
    setCreateOptions,
    setCreateModalIsOpen,
    childComponents,
    isNestedParent,
    isDirectParent,
    getMaxPosition,
    updateComponent,
    refetchChildComponents,
    layerRef,
    setDropComponentId,
    activeComponentState,
    activeVariationId
  } = props;
  let isHovering = false;

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ["box", "layer"],
    drop: async (item, monitor) => {
      const sourceComponentId = item.componentId;
      const targetComponentId = component.id;

      // A child cannot be dropped into the same parent
      // and a parent cannot be dropped into a nested child
      if (
        item.type == "layer" &&
        (sourceComponentId == targetComponentId ||
          isNestedParent(sourceComponentId, targetComponentId) ||
          isDirectParent(targetComponentId, sourceComponentId))
      ) {
        return;
      }

      if (!monitor.didDrop()) {
        if (item.type == "layer") {
          await updateComponent({
            variables: {
              id: sourceComponentId,
              parentComponentId: targetComponentId,
              position: getMaxPosition(targetComponentId)
            }
          });
          refetchChildComponents();
        } else {
          const maxPosition =
            childComponents.length > 0
              ? Math.max.apply(
                  Math,
                  childComponents.map(c => c.position)
                )
              : 0;

          setCreateOptions({
            parentComponentId: component.id,
            htmlTag: item.componentId ? "div" : item.htmlTag || "div",
            htmlTagAttributes: item.htmlTagAttributes || {},
            position: (maxPosition || 0) + 1,
            externalComponentId: item.componentId
              ? parseInt(item.componentId)
              : null
          });
          setCreateModalIsOpen(true);
        }
      }

      setDropComponentId(null);
    },
    hover: (item, monitor) => {
      if (monitor.isOver({ shallow: true })) {
        setDropComponentId(component.id);
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop:
        monitor.canDrop() &&
        !isNestedParent(monitor.getItem().componentId, component.id)
    })
  });

  // Make sure this element is not a void element and can have children
  if (!isVoidElement(component.htmlTag)) {
    drop(layerRef);

    isHovering = canDrop && isOver;
  }

  return (
    <Component
      {...props}
      key={`layer-${component.id}-${component.parentComponentId}-${activeComponentState}-${activeVariationId}`}
      isRoot={activeComponentId == rootComponent.id}
      isHovering={isHovering}
    />
  );
};
export default Layer;
