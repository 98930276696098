import React, { useRef } from "react";
import { useDrop } from "react-dnd";
import styled from "styled-components";

import { isVoidElement } from "../../../common/utils";

import CanvasBlur from "./CanvasBlur";
import PreviewFrame from "./PreviewFrame";
import LayerOutline from "./LayerOutline";

const Container = styled.div`
  padding: 20px;
  max-width: ${props => (props.device == "mobile" ? "520px" : "100%")};
  transition: max-width 1s;
  margin: 0 auto;
  outline: ${props => (props.isOver ? "4px solid #178df7" : "0px")};
  background-color: #fff;
  background-image: linear-gradient(
      45deg,
      ${props => (props.isOver ? "#CCC" : "#EEE")} 25%,
      transparent 25%
    ),
    linear-gradient(
      -45deg,
      ${props => (props.isOver ? "#CCC" : "#EEE")} 25%,
      transparent 25%
    ),
    linear-gradient(
      45deg,
      transparent 75%,
      ${props => (props.isOver ? "#CCC" : "#EEE")} 75%
    ),
    linear-gradient(
      -45deg,
      transparent 75%,
      ${props => (props.isOver ? "#CCC" : "#EEE")} 75%
    );
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
`;

const Canvas = props => {
  const {
    children,
    activeDevice,
    layerRefs,
    iframeRef,
    symbolId,
    rootComponent,
    setSymbolId,
    setSymbolShadowId,
    setActiveComponentId,
    setActiveVariationId,
    activeComponent,
    activeComponentId,
    hoverComponentId,
    hoverComponent,
    dropComponentId,
    dropComponent,
    setDropComponentId,
    setCreateOptions,
    setCreateModalIsOpen,
    getMaxPosition,
    designVersion,
    hideLayerOutlines
  } = props;
  const dropRef = useRef();
  const dropContainerRef = useRef();

  const onDrop = async (item, monitor) => {
    const targetComponentId = symbolId ? symbolId : rootComponent.id;

    if (!monitor.didDrop()) {
      setCreateOptions({
        parentComponentId: targetComponentId,
        htmlTag: item.componentId ? "div" : item.htmlTag || "div",
        htmlTagAttributes: item.htmlTagAttributes || {},
        position: getMaxPosition(targetComponentId),
        externalComponentId: item.componentId
          ? parseInt(item.componentId)
          : null
      });
      setCreateModalIsOpen(true);
    }
  };

  const onHover = (item, monitor) => {
    if (monitor.isOver({ shallow: true })) {
      setDropComponentId(symbolId ? symbolId : rootComponent.id);
    }
  };

  const onCollect = monitor => {
    return {
      isOver: monitor.isOver({ shallow: true }),
      canDrop:
        monitor.canDrop() &&
        monitor.getItem().componentId != rootComponent.id &&
        !isVoidElement(rootComponent.htmlTag)
    };
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ["box"],
    drop: onDrop,
    hover: onHover,
    collect: onCollect
  });

  const [{ isOver: isOverContainer }, dropContainer] = useDrop({
    accept: ["box"],
    drop: onDrop,
    hover: onHover,
    collect: onCollect
  });

  drop(dropRef);
  dropContainer(dropContainerRef);

  return (
    <Container
      ref={dropRef}
      device={activeDevice}
      isOver={symbolId ? false : isOver || isOverContainer}
      onClick={() => {
        setSymbolId(null);
        setSymbolShadowId(null);
        setActiveComponentId(null);
      }}
    >
      {!hideLayerOutlines && activeComponent && (
        <LayerOutline
          key={`activeLayer${activeComponentId}${designVersion}`}
          iframeRef={iframeRef}
          layerRef={layerRefs[activeComponentId]}
          component={activeComponent}
          rootComponent={rootComponent}
          symbolId={symbolId}
          active={true}
        />
      )}

      {!hideLayerOutlines &&
        hoverComponentId &&
        activeComponentId != hoverComponentId && (
          <LayerOutline
            key={`hoverLayer${hoverComponentId}${designVersion}`}
            iframeRef={iframeRef}
            layerRef={layerRefs[hoverComponentId]}
            component={hoverComponent}
            rootComponent={rootComponent}
            symbolId={symbolId}
            hover={true}
          />
        )}

      {!hideLayerOutlines &&
        dropComponentId &&
        activeComponentId != dropComponentId && (
          <LayerOutline
            key={`dropLayer${dropComponentId}${designVersion}`}
            iframeRef={iframeRef}
            layerRef={layerRefs[dropComponentId]}
            component={dropComponent}
            rootComponent={rootComponent}
            symbolId={symbolId}
            hover={true}
          />
        )}

      <PreviewFrame {...props} iframeRef={iframeRef}>
        <React.Fragment>
          <div ref={dropContainerRef}>{children}</div>

          {symbolId && layerRefs[symbolId] && (
            <CanvasBlur {...props} layerRef={layerRefs[symbolId]} />
          )}
        </React.Fragment>
      </PreviewFrame>
    </Container>
  );
};

export default Canvas;
