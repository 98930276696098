import React from "react";

const Invitation = ({ invitation, uninviteUserFromProject, refetchProject }) => {
  return (
    <>
      <div className="list-item">
        <div className="list-info">
          <div className="title">{invitation.email}</div>
          <div className="description">{invitation.role.toLowerCase()}</div>
        </div>

        <div className="actions">
          <a className="action-item" onClick={async () => {
            await uninviteUserFromProject({
              variables: {
                id: parseInt(invitation.id)
              }
            })

            await refetchProject()
          }}>
            <i className="action-icon fas fa-trash"></i>
          </a>
        </div>
      </div>
    </>
  );
};

export default Invitation;
