import React from "react";

const Img = ({
  className,
  style,
  component,
  layerRef,
  onClick,
  onDoubleClick,
  onMouseOver,
  onMouseOut
}) => {
  return (
    <img
      ref={layerRef}
      className={className}
      style={style}
      src={component.textContent}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
};

export default Img;
