import React, { useRef } from "react";
import { useDrag } from "react-dnd";

import Component from "./Component";

import { useQuery } from "@apollo/client";

import { COMPONENT_QUERY } from "../../queries";

const ChildComponent = ({
  projectId,
  project,
  activeComponentId,
  activeDevice,
  componentId
}) => {
  const { loading, error, data } = useQuery(COMPONENT_QUERY, {
    variables: { id: parseInt(componentId) }
  });

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const component = data.component;

  return (
    <Component
      key={`externalcomponent${component.id}`}
      component={component}
      activeComponentId={activeComponentId}
      activeDevice={activeDevice}
      externalChild={true}
      project={project}
    >
      {component.children.length > 0 &&
        component.children.map(c => (
          <ChildComponent
            key={`childcomponent${c.id}`}
            projectId={projectId}
            project={project}
            activeComponentId={activeComponentId}
            activeDevice={activeDevice}
            componentId={c.externalComponentId ? c.externalComponentId : c.id}
          />
        ))}
      {component.children.length == 0 && component.textContent}
    </Component>
  );
};

const RootComponent = props => {
  const {
    component,
    externalComponent,
    projectId,
    project,
    activeDevice,
    activeComponentId
  } = props;
  return (
    <Component
      {...props}
      key={`layer-${component.id}-${component.parentComponentId}`}
      variationId={component.externalVariationId}
    >
      {externalComponent.children.length > 0 &&
        externalComponent.children.map(c => (
          <ChildComponent
            key={`childcomponent${c.id}`}
            projectId={projectId}
            project={project}
            activeComponentId={activeComponentId}
            activeDevice={activeDevice}
            componentId={c.externalComponentId ? c.externalComponentId : c.id}
          />
        ))}
      {externalComponent.children.length == 0 && externalComponent.textContent}
    </Component>
  );
};

const ExternalLayer = props => {
  const { component } = props;
  const { loading, error, data } = useQuery(COMPONENT_QUERY, {
    variables: { id: parseInt(component.externalComponentId) }
  });

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  return <RootComponent {...props} externalComponent={data.component} />;
};

export default ExternalLayer;
