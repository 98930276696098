import { gql } from "@apollo/client";

export const GET_FONTS = gql`
  query getFonts($projectId: Int!) {
    fonts(projectId: $projectId) {
      id
      name
      value
      url
    }
  }
`;

export const CREATE_FONT = gql`
  mutation CreateFont(
    $projectId: Int!
    $name: String!
    $value: String!
    $url: String
  ) {
    createFont(
      input: { projectId: $projectId, name: $name, value: $value, url: $url }
    ) {
      success
      error
      font {
        id
        name
        value
        url
      }
    }
  }
`;

export const UPDATE_FONT = gql`
  mutation UpdateFont($id: Int!, $name: String, $value: String, $url: String) {
    updateFont(input: { id: $id, name: $name, value: $value, url: $url }) {
      success
      error
      font {
        id
        name
        value
        url
      }
    }
  }
`;

export const DELETE_FONT = gql`
  mutation DeleteFont($id: Int!) {
    deleteFont(input: { id: $id }) {
      success
      error
    }
  }
`;
