import React from "react";

const Variable = ({
  variable,
  fonts,
  colors,
  onEditVariable,
  onDeleteVariable
}) => {
  const { id, name, fontId, colorId, value } = variable;

  const getValue = () => {
    if (fontId) {
      return fonts.find(f => f.id == fontId).value;
    } else if (colorId) {
      return colors.find(c => c.id == colorId).value;
    } else {
      return value;
    }
  };

  return (
    <div className="list-item">
      <div className="list-info">
        <div className="title">{name}</div>
        <div className="description">{getValue()}</div>
      </div>

      <div className="actions">
        <a
          className="action-item"
          onClick={() => onEditVariable({ id, name, value, fontId, colorId })}
        >
          <i className="action-icon fas fa-edit"></i>
        </a>
        <a className="action-item" onClick={() => onDeleteVariable(id)}>
          <i className="action-icon fas fa-trash"></i>
        </a>
      </div>
    </div>
  );
};

export default Variable;
