import React, { useState } from "react";
import styled from "styled-components";
import classnames from "classnames";

import { Row, Col, TextFieldContainer } from "./styles";

const Container = styled.div`
  padding: 10px 0px 50px;
`;

const NewCustomAttribute = styled.div`
  font-size: 12px;
  text-align: center;

  p {
    font-size: 12px;
    margin: 0px;
    margin-bottom: 3px;
    padding: 10px;
  }

  a {
    display: inline-block;
    padding: 5px 10px;
    background-color: #333;
    color: #ccc;
    cursor: pointer;

    &:hover {
      background-color: #444;
    }
  }
`;

const CustomAttributeForm = styled.div`
  label {
    font-size: 12px;
    color: #fff;
    margin-bottom: 3px;
  }

  .error {
    border: 1px solid #dc3545;
  }

  .save {
    flex: 1;
    font-size: 30px;
    line-height: 0;

    a {
      cursor: pointer;
      color: #999;

      &:hover {
        color: #fff;
      }
    }
  }
`;

const CustomProperty = ({ onChange }) => {
  const [addCustom, setAddCustom] = useState(false);

  const [propertyName, setPropertyName] = useState("");
  const [propertyValue, setPropertyValue] = useState("");

  const [nameError, setNameError] = useState(false);
  const [valueError, setValueError] = useState(false);

  const saveProperty = () => {
    let errors = false;
    if (!propertyName.trim()) {
      setNameError(true);
      errors = true;
    }
    if (!propertyValue.trim()) {
      setValueError(true);
      errors = true;
    }
    if (errors) return;
    onChange(propertyName, propertyValue);
    setPropertyName("");
    setPropertyValue("");
    setNameError(false);
    setValueError(false);
    setAddCustom(false);
  };

  return (
    <Container>
      {addCustom && (
        <CustomAttributeForm>
          <Row>
            <Col style={{ maxWidth: "42% " }}>
              <label>Property</label>
              <TextFieldContainer>
                <input
                  type="text"
                  value={propertyName}
                  className={classnames({ error: nameError })}
                  onChange={e => {
                    setPropertyName(e.target.value);
                    setNameError(false);
                  }}
                />
              </TextFieldContainer>
            </Col>
            <Col style={{ maxWidth: "42% " }}>
              <label>Value</label>
              <TextFieldContainer>
                <input
                  type="text"
                  value={propertyValue}
                  className={classnames({ error: valueError })}
                  onChange={e => {
                    setPropertyValue(e.target.value);
                    setValueError(false);
                  }}
                />
              </TextFieldContainer>
            </Col>
            <Col
              style={{
                maxWidth: 30,
                display: "flex",
                alignItems: "flex-end"
              }}
            >
              <div className="save">
                <a onClick={() => saveProperty()}>
                  <i className="fas fa-check-square"></i>
                </a>
              </div>
            </Col>
          </Row>
        </CustomAttributeForm>
      )}

      {!addCustom && (
        <NewCustomAttribute>
          <p>Add a custom CSS property</p>
          <a onClick={() => setAddCustom(true)}>Add New</a>
        </NewCustomAttribute>
      )}
    </Container>
  );
};
export default CustomProperty;
