import { gql } from "@apollo/client";

export const GET_ATTACHMENTS = gql`
  query getAttachments($projectId: Int!) {
    attachments(projectId: $projectId) {
      id
      name
      url
    }
  }
`;

export const CREATE_ATTACHMENT = gql`
  mutation CreateAttachment($projectId: Int!, $name: String!, $url: String!) {
    createAttachment(input: { projectId: $projectId, name: $name, url: $url }) {
      success
      error
      attachment {
        id
        name
        url
      }
    }
  }
`;

export const UPDATE_ATTACHMENT = gql`
  mutation UpdateAttachment($id: Int!, $name: String, $url: String) {
    updateAttachment(input: { id: $id, name: $name, url: $url }) {
      success
      error
      attachment {
        id
        name
        url
      }
    }
  }
`;

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($id: Int!) {
    deleteAttachment(input: { id: $id }) {
      success
      error
    }
  }
`;

export const CREATE_S3_SIGNED_REQUEST = gql`
  mutation CreateS3SignedRequest($projectId: Int!, $fileName: String!) {
    createS3SignedRequest(
      input: { projectId: $projectId, fileName: $fileName }
    ) {
      success
      error
      signedRequest
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject(
    $id: Int!
    $name: String
    $awsConfig: AwsConfigInput
    $cdnUrl: String
  ) {
    updateProject(
      input: { id: $id, name: $name, awsConfig: $awsConfig, cdnUrl: $cdnUrl }
    ) {
      success
      error
      project {
        id
        name
        cdnUrl
        awsConfig {
          accessKeyId
          secretAccessKey
          region
          bucket
          pathPrefix
        }
      }
    }
  }
`;
