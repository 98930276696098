import React, { useEffect, useState } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import TopNav from "../components/TopNav";
import Account from "../components/Account";
import Onboarding from "../components/Onboarding";
import Projects from "../components/Projects";
import Components from "../components/Components";
import Settings from "../components/Settings";
import Documentation from "../components/Documentation";
import Versions from "../components/Versions";

import Loader from "../components/common/Loader";

import { useQuery, gql } from "@apollo/client";

const PROJECT_QUERY = gql`
  query getProject($id: Int!) {
    project(id: $id) {
      id
      name
      resetCss
      fullStylesUrl
      fullJavascriptUrl
      cdnUrl
      onboardingSteps
      awsConfig {
        accessKeyId
        secretAccessKey
        region
        bucket
        pathPrefix
      }
      fonts {
        id
        name
        value
        url
      }
      colors {
        id
        name
        value
      }
      variables {
        id
        name
        value
        colorId
        fontId
      }
      utilities {
        id
        selector
      }
      categories {
        id
        slug
        label
      }
    }
  }
`;

const ProjectContainer = props => {
  const { activeProjectId } = props;
  return (
    <React.Fragment>
      <TopNav {...props} key={`topnav${activeProjectId}`} />

      <Onboarding {...props} key={`onboarding${activeProjectId}`} />

      <Switch>
        <Route path="/projects">
          <Projects {...props} />
        </Route>
        <Route path="/components">
          <Components {...props} />
        </Route>
        <Route path="/docs">
          <Documentation {...props} />
        </Route>
        <Route path="/versions">
          <Versions {...props} />
        </Route>
        <Route path="/settings">
          <Settings {...props} />
        </Route>
        <Route path="/account">
          <Account {...props} />
        </Route>
      </Switch>
    </React.Fragment>
  );
};

const NoProjectContainer = props => {
  const { activeProjectId } = props;
  return (
    <React.Fragment>
      <TopNav {...props} key={`topnavnoprojects`} />

      <Switch>
        <Route path="/projects">
          <Projects {...props} />
        </Route>
        <Route path="/account">
          <Account {...props} />
        </Route>
        <Route>
          {!activeProjectId && (
            <Redirect
              to={{
                pathname: "/projects"
              }}
            />
          )}
        </Route>
      </Switch>
    </React.Fragment>
  );
};

const DataWrapper = props => {
  const { projectId } = props;

  const { loading, error, data, refetch } = useQuery(PROJECT_QUERY, {
    variables: { id: parseInt(projectId) }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const isProjectAvailable = projectId && data && data.project;

  const childProps = {
    ...props,
    project: data.project,
    refetchProject: refetch
  };

  return isProjectAvailable ? (
    <ProjectContainer {...childProps} />
  ) : (
    <NoProjectContainer {...childProps} />
  );
};

const Project = props => {
  const { currentUser } = props;

  useEffect(() => {
    if (currentUser) {
      window.intercomSettings = {
        app_id: "lrov7lx5",
        email: currentUser.email,
        name: currentUser.name,
        created_at: new Date(currentUser.createdAt).getTime()
      };

      (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/lrov7lx5";
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }
  }, [currentUser]);

  const [activeProjectId, setActiveProjectId] = useState(
    localStorage.getItem("monad:projectId")
  );

  const childProps = {
    ...props,
    setActiveProjectId,
    projectId: parseInt(activeProjectId)
  };

  if (activeProjectId) {
    return <DataWrapper {...childProps} />;
  } else {
    return <NoProjectContainer {...childProps} />;
  }
};

export default Project;
