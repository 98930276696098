import React from "react"
import { Redirect } from "react-router-dom"

import client from '../../client'

const Signout = () => {
  localStorage.removeItem('monad:token')
  client.resetStore()

  return (
    <Redirect
      to={{
        pathname: "/login"
      }}
    />
  )
}

export default Signout
