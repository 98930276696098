import React, { useState, useRef, useEffect } from "react";

import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";
import CodeMirror from "codemirror/lib/codemirror";

import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;

  .title {
    padding: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }

    .version {
      max-width: 120px;
      font-weight: bold;
      margin-right: 30px;
      flex: 1;

      .number {
        margin-left: 4px;
        display: inline-block;
        padding: 5px 10px;
        border-radius: 25px;
        background-color: rgba(0, 123, 255, 1);
        color: #fff;
      }
    }

    .comment {
      color: #999;
      flex: 1;
    }

    .action {
      font-size: 24px;
      color: #444;
      max-width: 30px;
      flex: 1;
      text-align: right;
    }
  }
`;

const ExpandContainer = styled.div`
  padding: 20px;

  .header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .CodeMirror {
    border: 1px solid #ccc;
    height: auto;

    .CodeMirror-sizer {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
`;

const Version = props => {
  const { version, comment, project } = props;
  const [expanded, setExpanded] = useState(props.expanded || false);

  const cssFileTextarea = useRef(null);
  const jsFileTextArea = useRef(null);

  useEffect(() => {
    if (cssFileTextarea && cssFileTextarea.current) {
      CodeMirror.fromTextArea(cssFileTextarea.current, {
        mode: { name: "htmlmixed" },
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true
      });
    }

    if (jsFileTextArea && jsFileTextArea.current) {
      CodeMirror.fromTextArea(jsFileTextArea.current, {
        mode: { name: "htmlmixed" },
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true
      });
    }
  }, [project.id, expanded]);

  return (
    <Container>
      <div className="title" onClick={() => setExpanded(!expanded)}>
        <div className="version">
          <span>Version</span> <span className="number">{version.version}</span>
        </div>
        <div className="comment">{version.comment || "No comment added"}</div>
        <div className="action">
          {expanded ? (
            <i className="far fa-angle-down"></i>
          ) : (
            <i className="far fa-angle-right"></i>
          )}
        </div>
      </div>

      {expanded && (
        <ExpandContainer className="details">
          <form className="form">
            <div className="header">CSS</div>

            <div className="form-group">
              <p>
                {
                  "Copy-paste the stylesheet <link> into your <head> before all other stylesheets to load our CSS."
                }
              </p>

              <textarea
                ref={cssFileTextarea}
                defaultValue={`<link rel="stylesheet" type="text/css" href="${version.fullStylesUrl}" crossorigin="anonymous" />`}
              ></textarea>
            </div>

            <div className="header">JS</div>

            <div className="form-group">
              <p>
                {
                  "Place the following <script> near the end of your pages, right before the closing </body> tag."
                }
              </p>

              <textarea
                ref={jsFileTextArea}
                defaultValue={`<script src="${version.fullJavascriptUrl}" crossorigin="anonymous"></script>`}
              ></textarea>
            </div>
          </form>
        </ExpandContainer>
      )}
    </Container>
  );
};

export default Version;
