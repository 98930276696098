import { gql } from "@apollo/client";

export const DEFAULT_FONTS = gql`
  query getDefaultFonts {
    defaultFonts {
      name
      value
      url
    }
  }
`;

export const ASSIGN_FONT_TO_VARIABLE = gql`
  mutation AssignFontToVariable(
    $projectId: Int!
    $name: String!
    $value: String!
    $url: String
    $variableName: String!
  ) {
    assignFontToVariable(
      input: {
        projectId: $projectId
        name: $name
        value: $value
        url: $url
        variableName: $variableName
      }
    ) {
      success
      error
      variable {
        id
        name
        value
        fontId
        colorId
      }
    }
  }
`;

export const ASSIGN_COLOR_TO_VARIABLE = gql`
  mutation AssignColorToVariable(
    $projectId: Int!
    $name: String!
    $value: String!
    $variableName: String!
  ) {
    assignColorToVariable(
      input: {
        projectId: $projectId
        name: $name
        value: $value
        variableName: $variableName
      }
    ) {
      success
      error
      variable {
        id
        name
        value
        fontId
        colorId
      }
    }
  }
`;

export const CREATE_OR_UPDATE_VARIABLE = gql`
  mutation CreateOrUpdateVariable(
    $projectId: Int!
    $name: String!
    $value: String!
  ) {
    createOrUpdateVariable(
      input: { projectId: $projectId, name: $name, value: $value }
    ) {
      success
      error
      variable {
        id
        name
        value
      }
    }
  }
`;

export const COMPLETE_ONBOARDING_STEP = gql`
  mutation CompleteOnboardingStep($projectId: Int!, $name: String!) {
    completeOnboardingStep(input: { projectId: $projectId, name: $name }) {
      success
      error
      project {
        id
        onboardingSteps
      }
    }
  }
`;
