import React from "react";

import classnames from "classnames";

import {
  EditorContainer,
  SquareButton,
  SquareButtonGroup,
  EditorTitle
} from "../styles";

const Editor = ({
  name,
  title,
  value,
  onChange,
  isChanged,
  hasValue,
  project
}) => {
  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>
      <SquareButtonGroup>
        <SquareButton
          onClick={() => onChange(name, "left")}
          className={classnames({ active: value == "left" })}
        >
          <i className="fas fa-align-left"></i>
        </SquareButton>

        <SquareButton
          onClick={() => onChange(name, "center")}
          className={classnames({ active: value == "center" })}
        >
          <i className="fas fa-align-center"></i>
        </SquareButton>

        <SquareButton
          onClick={() => onChange(name, "right")}
          className={classnames({ active: value == "right" })}
        >
          <i className="fas fa-align-right"></i>
        </SquareButton>

        <SquareButton
          onClick={() => onChange(name, "justify")}
          className={classnames({ active: value == "justify" })}
        >
          <i className="fas fa-align-justify"></i>
        </SquareButton>
      </SquareButtonGroup>
    </EditorContainer>
  );
};
export default Editor;
