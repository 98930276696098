import { gql } from "@apollo/client";

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: Int!, $name: String) {
    updateProject(input: { id: $id, name: $name }) {
      success
      error
      project {
        id
        name
      }
    }
  }
`;

export const PUBLISH_PROJECT = gql`
  mutation PublishProject($id: Int!) {
    publishProject(input: { id: $id }) {
      success
      error
      project {
        id
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    deleteProject(input: { id: $id }) {
      success
      error
      deletedAt
    }
  }
`;
