import React from "react";
import { Link } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import {
  INVITATIONS_QUERY,
  ACCEPT_INVITATION,
  DECLINE_INVITATION
} from "./queries";

import Loader from "../../components/common/Loader";

const Invitations = ({ invitations, refetchProjects, refetchInvitations }) => {
  const [acceptInvitation] = useMutation(ACCEPT_INVITATION);
  const [declineInvitation] = useMutation(DECLINE_INVITATION);

  return (
    <React.Fragment>
      {invitations.map(i => {
        return (
          <div key={i} className="card">
            <div className="card-body">
              <h2 className="card-title">{i.project.name}</h2>
              <p className="card-info" style={{ textTransform: "none" }}>
                Invited by {i.invitor.name || i.invitor.email}
              </p>
              <a
                className="btn btn-success"
                onClick={async () => {
                  await acceptInvitation({
                    variables: {
                      id: i.id
                    }
                  });

                  await refetchInvitations();
                  await refetchProjects();
                }}
              >
                Accept
              </a>{" "}
              <a
                className="btn btn-danger"
                onClick={async () => {
                  await declineInvitation({
                    variables: {
                      id: i.id
                    }
                  });

                  await refetchInvitations();
                  await refetchProjects();
                }}
              >
                Decline
              </a>
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default Invitations;
