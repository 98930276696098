import React, { useState, useRef, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { Formik } from "formik";

import { UPDATE_USER } from "./queries";

import styled from "styled-components";

const Well = styled.div`
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 0px 0px 30px;

  &:last-child {
    margin-bottom: 0px;
  }

  .well-error {
    background-color: #dc3545;
    color: #fff;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
  }
`;

const General = props => {
  const [saved, setSaved] = useState(false);
  const [savedPassword, setSavedPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(null);

  const { history, project, projectId, currentUser } = props;

  const [updateUser] = useMutation(UPDATE_USER);

  return (
    <div>
      <Well>
        <Formik
          initialValues={{
            email: currentUser.email,
            name: currentUser.name
          }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }

            if (!values.name) {
              errors.name = "Required";
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSaved(true);

            const response = await updateUser({
              variables: {
                email: values.email,
                name: values.name
              }
            });

            if (
              response &&
              response.data &&
              response.data.updateUser &&
              response.data.updateUser.success
            ) {
              localStorage.setItem(
                "monad:token",
                response.data.updateUser.token
              );
            }

            setSubmitting(false);
            setTimeout(() => setSaved(false), 2000);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <label className="label">Name</label>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <p className="hint error">{errors.name}</p>
                )}
              </div>

              <div className="form-group">
                <label className="label">Email Address</label>
                <input
                  className="form-control"
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                {errors.email && touched.email && (
                  <p className="hint error">{errors.email}</p>
                )}
              </div>
              <button
                className={
                  isSubmitting
                    ? "btn btn-dark"
                    : saved
                    ? "btn btn-success"
                    : "btn btn-primary"
                }
                type="submit"
                disabled={isSubmitting || saved}
              >
                {isSubmitting ? "Saving" : saved ? "Saved" : "Save Changes"}
              </button>
            </form>
          )}
        </Formik>
      </Well>

      <Well>
        {passwordError && <div className="well-error">{passwordError}</div>}
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmNewPassword: ""
          }}
          validate={values => {
            const errors = {};
            if (!values.currentPassword) {
              errors.currentPassword = "Required";
            }
            if (!values.newPassword) {
              errors.newPassword = "Required";
            }
            if (!values.confirmNewPassword) {
              errors.confirmNewPassword = "Required";
            }
            if (values.confirmNewPassword != values.newPassword) {
              errors.confirmNewPassword = "Does not match new password";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            setSavedPassword(true);
            setPasswordError(null);

            const response = await updateUser({
              variables: {
                currentPassword: values.currentPassword,
                newPassword: values.newPassword,
                confirmNewPassword: values.confirmNewPassword
              }
            });

            if (
              response &&
              response.data &&
              response.data.updateUser &&
              response.data.updateUser.error
            ) {
              setPasswordError(response.data.updateUser.error);
            }

            if (
              response &&
              response.data &&
              response.data.updateUser &&
              response.data.updateUser.success
            ) {
              localStorage.setItem(
                "monad:token",
                response.data.updateUser.token
              );
            }

            setSubmitting(false);
            setTimeout(() => setSavedPassword(false), 2000);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <label className="label">Current Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="currentPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.currentPassword}
                />
                {errors.currentPassword && touched.currentPassword && (
                  <p className="hint error">{errors.currentPassword}</p>
                )}
              </div>

              <div className="form-group">
                <label className="label">New Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="newPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.newPassword}
                />
                {errors.newPassword && touched.newPassword && (
                  <p className="hint error">{errors.newPassword}</p>
                )}
              </div>

              <div className="form-group">
                <label className="label">Confirm New Password</label>
                <input
                  className="form-control"
                  type="password"
                  name="confirmNewPassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.confirmNewPassword}
                />
                {errors.confirmNewPassword && touched.confirmNewPassword && (
                  <p className="hint error">{errors.confirmNewPassword}</p>
                )}
              </div>

              <button
                className={
                  isSubmitting
                    ? "btn btn-dark"
                    : savedPassword
                    ? "btn btn-success"
                    : "btn btn-primary"
                }
                type="submit"
                disabled={isSubmitting || savedPassword}
              >
                {isSubmitting
                  ? "Saving"
                  : savedPassword
                  ? "Saved"
                  : "Change Password"}
              </button>
            </form>
          )}
        </Formik>
      </Well>

      <Well>
        <a href="/logout" className="btn btn-outline-dark">
          Sign Out
        </a>
      </Well>
    </div>
  );
};

export default General;
