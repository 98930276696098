import { gql } from "@apollo/client";

export const GET_COLORS = gql`
  query getColors($projectId: Int!) {
    colors(projectId: $projectId) {
      id
      name
      value
    }
  }
`;

export const CREATE_COLOR = gql`
  mutation CreateColor($projectId: Int!, $name: String!, $value: String!) {
    createColor(input: { projectId: $projectId, name: $name, value: $value }) {
      success
      error
      color {
        id
        name
        value
      }
    }
  }
`;

export const UPDATE_COLOR = gql`
  mutation UpdateColor($id: Int!, $name: String, $value: String) {
    updateColor(input: { id: $id, name: $name, value: $value }) {
      success
      error
      color {
        id
        name
        value
      }
    }
  }
`;

export const DELETE_COLOR = gql`
  mutation DeleteColor($id: Int!) {
    deleteColor(input: { id: $id }) {
      success
      error
    }
  }
`;
