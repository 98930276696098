import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { ROOT_COMPONENTS_QUERY } from "./queries";

import Loader from "../common/Loader";
import Tabs from "./Tabs";

import blankImg from "../common/img/components.png";

import styled from "styled-components";

const ComponentsContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  ::after {
    content: "";
    flex: auto;
    min-width: 32%;
    max-width: 32%;
  }

  .card {
    max-width: 32%;
    margin-bottom: 2%;
  }
`;

const BlankState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  img {
    max-width: 300px;
  }

  .header {
    font-size: 20px;
    margin: 20px 0px 15px;
    font-weight: bold;
  }

  .desc {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const Components = ({ projectId }) => {
  const { loading, error, data } = useQuery(ROOT_COMPONENTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb active">Components</h1>
          </div>

          <div className="actions">
            <Link className="action" to="/components/utilities/new">
              New Utility
            </Link>{" "}
            <Link className="action" to="/components/new">
              New Component
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <Tabs />

        {data.rootComponents.length == 0 && (
          <BlankState>
            <img src={blankImg} />
            <div className="header">No Components Found</div>
            <p className="desc">
              Components are the building blocks of a project.
            </p>
            <div className="action">
              <Link className="btn btn-primary" to="/components/new">
                Create Component
              </Link>
            </div>
          </BlankState>
        )}

        <ComponentsContainer>
          {data.rootComponents.map((p, i) => {
            return (
              <div key={i} className="card">
                <div className="card-body">
                  <h2 className="card-title">{p.selector}</h2>
                  <div className="card-info">
                    <span className="info-type">{p.htmlTag}</span>
                  </div>
                  <div className="actions">
                    <Link
                      to={`/components/${p.id}`}
                      className="btn btn-primary"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </ComponentsContainer>
      </div>
    </React.Fragment>
  );
};

export default Components;
