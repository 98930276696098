import React, { useEffect } from "react";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Delimiter from "@editorjs/delimiter";
import CodeTool from "./CodePlugin";

import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;

  .ce-block__content,
  .ce-toolbar__content {
    max-width: 100%;
  }

  .ce-paragraph {
    padding-right: 45px;
  }

  .ce-delimiter:before {
    display: inline-block;
    content: " ";
    font-size: 0px;
    height: 1px;
    width: 100%;
    border-top: 1px dotted #ccc;
  }
`;

const Editor = ({ json, onChange }) => {
  useEffect(() => {
    const editor = new EditorJS({
      holderId: "page-editor",
      data: json,
      autofocus: true,
      tools: {
        header: Header,
        list: List,
        delimiter: Delimiter,
        code: CodeTool
      },
      onReady: () => {
        // console.log("Editor.js is ready to work!");
      },
      onChange: async () => {
        const data = await editor.save();
        // console.log("Now I know that Editor's content changed!", data);
        onChange(data);
      }
    });
  }, []);
  return (
    <Wrapper>
      <div id="page-editor"></div>
    </Wrapper>
  );
};

export default Editor;
