import { gql } from "@apollo/client";

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: Int!, $resetCss: String) {
    updateProject(input: { id: $id, resetCss: $resetCss }) {
      success
      error
      project {
        id
        resetCss
      }
    }
  }
`;
