import React from "react";
import { NavLink } from "react-router-dom";

import classnames from "classnames";

import styled from "styled-components";

const AccountThumb = styled.div`
  background-color: #ccc;
  color: #555;
  flex: 1;
  text-align: center;
`;

const Nav = ({ currentUser, project, location }) => {
  let fullWidthContainer = false;
  const pathname = location.pathname;
  if (pathname) {
    if (pathname.match(/\/components\/\d{2,}$/)) {
      fullWidthContainer = true;
    }
  }

  return (
    <div className="nav">
      <div
        className={classnames({
          "nav-wrapper": true,
          container: !fullWidthContainer
        })}
      >
        <div className="nav-items-left">
          <NavLink
            className="navbar-brand"
            activeClassName="active"
            to="/projects"
          >
            <img className="navbar-logo" src="/logo.png" />
          </NavLink>

          {!project && (
            <NavLink
              className="nav-item"
              activeClassName="active"
              to="/projects"
            >
              Projects
            </NavLink>
          )}

          {!!project && (
            <React.Fragment>
              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/projects"
              >
                {project.name}
              </NavLink>
              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/components"
              >
                Components
              </NavLink>
              <NavLink className="nav-item" activeClassName="active" to="/docs">
                Documentation
              </NavLink>
              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/settings"
              >
                Settings
              </NavLink>
            </React.Fragment>
          )}
        </div>

        <div className="nav-items-right">
          {!!project && (
            <NavLink
              className="nav-item"
              activeClassName="active"
              to="/versions"
            >
              Versions
            </NavLink>
          )}
          <NavLink className="navbrand-user" to="/account">
            <AccountThumb>
              <i className="fas fa-user" />
            </AccountThumb>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default Nav;
