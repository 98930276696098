import React from "react";

import Select from "react-select";

import classnames from "classnames";

import { EditorContainer, EditorTitle, DropdownContainer } from "../styles";

import { fontToId, idToFont } from "../../utils";

const Editor = ({
  name,
  title,
  value,
  onChange,
  isChanged,
  hasValue,
  project
}) => {
  const fontId = fontToId(value);
  const font = project.fonts.find(f => f.id == fontId);

  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>
      <DropdownContainer>
        <Select
          defaultValue={font ? { value: value, label: font.name } : null}
          isClearable={true}
          isSearchable={true}
          name={name}
          onChange={data => onChange(name, data ? data.value : null)}
          options={project.fonts.map(f => {
            return { value: idToFont(f.id), label: f.name };
          })}
          classNamePrefix="react-select"
        />
      </DropdownContainer>
    </EditorContainer>
  );
};
export default Editor;
