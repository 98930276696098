import React, { useState } from "react";
import { Link } from "react-router-dom";

import client from "../../client";

import { Formik } from "formik";

import styled from "styled-components";

import { useMutation, gql } from "@apollo/client";

const SIGN_IN_USER = gql`
  mutation SignInUser($email: String!, $password: String!) {
    signInUser(input: { email: $email, password: $password }) {
      success
      error
      token
    }
  }
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .auth {
    flex: 1;
    background-color: #fff;
    padding: 30px;
    border-radius: 25px;
    box-shadow: 1px 1px 5px #ccc;
  }

  label {
    color: #999;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 3px;
  }

  .main-error {
    background-color: #ad2d39;
    border-radius: 4px;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
  }

  .footer {
    margin-top: 25px;
    font-size: 14px;
  }

  .error {
    color: red;
    font-size: 12px;
  }
`;

const Login = ({ history }) => {
  const [error, setError] = useState(false);
  const [signInUser, { data }] = useMutation(SIGN_IN_USER);

  return (
    <Container className="container">
      <div className="auth">
        <div className="header">
          <img className="logo" src="/logo.png" />
          <h2 className="title">Sign in to your account</h2>
        </div>

        <div className="body">
          <Formik
            initialValues={{ email: "", password: "" }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.password) {
                errors.password = "Required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              const response = await signInUser({ variables: values });

              setError(false);

              if (
                response &&
                response.data &&
                response.data.signInUser &&
                response.data.signInUser.error
              ) {
                setError(true);
              }

              if (
                response &&
                response.data &&
                response.data.signInUser &&
                response.data.signInUser.success
              ) {
                localStorage.setItem(
                  "monad:token",
                  response.data.signInUser.token
                );
                client.resetStore();
                window.location.replace("/components");
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} className="form">
                {(error ||
                  (touched.password && errors.password) ||
                  (touched.email && errors.email)) && (
                  <div className="main-error">Invalid email or password</div>
                )}
                <div>
                  <div className="form-group">
                    <label>
                      Email Address
                      {errors.email && touched.email && (
                        <>
                          {" "}
                          &mdash; <span className="error">{errors.email}</span>
                        </>
                      )}
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                  </div>

                  <div className="form-group">
                    <label>
                      Password
                      {errors.password && touched.password && (
                        <>
                          {" "}
                          &mdash;{" "}
                          <span className="error">{errors.password}</span>
                        </>
                      )}
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  </div>
                </div>

                <div className="button">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}
                  >
                    Sign In
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>

        <div className="footer">
          <p>
            Don't have an account? <Link to="/signup">Sign Up</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Login;
