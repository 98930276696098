import React, { useState, useEffect } from "react";

import tags from "../../../../common/tags.json";

import Select from "react-select";

import {
  EditorContainer,
  EditorTitle,
  DropdownContainer,
  TextFieldContainer
} from "../../../../common/Editors/styles";

const CssState = ({ activeComponent, updateComponent }) => {
  const activeTagValue = tags.find(tag => tag.value == activeComponent.htmlTag);

  const updateTag = async htmlTag => {
    await updateComponent({
      variables: {
        id: activeComponent.id,
        htmlTag
      }
    });
  };

  const updateTextContent = async textContent => {
    await updateComponent({
      variables: {
        id: activeComponent.id,
        textContent
      }
    });
  };

  return (
    <>
      <EditorContainer>
        <EditorTitle>Tag</EditorTitle>
        <DropdownContainer>
          <Select
            defaultValue={activeTagValue}
            isClearable={false}
            isSearchable={true}
            onChange={data => updateTag(data.value)}
            options={tags}
            classNamePrefix="react-select"
          />
        </DropdownContainer>
      </EditorContainer>

      <EditorContainer>
        <EditorTitle>Value</EditorTitle>
        <TextFieldContainer>
          <input
            type="text"
            defaultValue={activeComponent.textContent}
            onChange={e => updateTextContent(e.target.value)}
          />
        </TextFieldContainer>
      </EditorContainer>
    </>
  );
};
export default CssState;
