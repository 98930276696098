import React from "react";

import classnames from "classnames";

import { EditorContainer, EditorTitle, TextFieldContainer } from "../styles";

const Editor = ({ name, title, value, onChange, isChanged, hasValue }) => {
  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>

      <TextFieldContainer>
        <input
          type="text"
          defaultValue={value}
          onChange={e => onChange(name, e.target.value)}
        />
      </TextFieldContainer>
    </EditorContainer>
  );
};
export default Editor;
