import React, { useState } from "react";

import Loader from "../../../components/common/Loader";
import Modal from "../../common/Modal";
import Font from "./Font";

import { Formik } from "formik";

import { useQuery, useMutation } from "@apollo/client";

import { GET_FONTS, CREATE_FONT, UPDATE_FONT, DELETE_FONT } from "./queries";

const Fonts = ({ projectId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editFont, setEditFont] = useState({});
  const [createFont] = useMutation(CREATE_FONT);
  const [updateFont] = useMutation(UPDATE_FONT);
  const [deleteFont] = useMutation(DELETE_FONT);

  const { loading, error, data, refetch } = useQuery(GET_FONTS, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const onEditFont = font => {
    setEditFont(font);
    setModalIsOpen(true);
  };

  const onDeleteFont = async fontId => {
    if (window.confirm("Are you sure?")) {
      await deleteFont({
        variables: {
          id: fontId
        }
      });
      refetch();
    }
  };

  return (
    <div>
      <div className="settings-header">
        <div className="title">Fonts</div>
        <div className="action">
          <a
            className="btn btn-primary"
            onClick={() => {
              setEditFont({});
              setModalIsOpen(true);
            }}
          >
            New Font
          </a>
        </div>
      </div>

      {data && data.fonts.length == 0 && (
        <div
          style={{
            padding: 20,
            textAlign: "center",
            color: "#999",
            fontWeight: "bold"
          }}
        >
          No Fonts Found
        </div>
      )}

      {data && data.fonts.length > 0 && (
        <div className="list">
          {data &&
            data.fonts.map(c => {
              return (
                <Font
                  key={`font${c.id}`}
                  id={c.id}
                  name={c.name}
                  value={c.value}
                  url={c.url}
                  onEditFont={onEditFont}
                  onDeleteFont={onDeleteFont}
                />
              );
            })}
        </div>
      )}

      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            name: "",
            value: "",
            url: "",
            ...editFont
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.value) {
              errors.value = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (editFont && editFont.id) {
              await updateFont({
                variables: {
                  id: parseInt(editFont.id),
                  ...values
                }
              });
            } else {
              await createFont({
                variables: {
                  projectId,
                  ...values
                }
              });
            }

            refetch();
            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">
                    {editFont.id ? "Edit" : "New"} Font
                  </h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p className="hint error">{errors.name}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Value</label>
                    <input
                      className="form-control"
                      type="text"
                      name="value"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.value}
                    />
                    {errors.value && touched.value && (
                      <p className="hint error">{errors.value}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">URL</label>
                    <input
                      className="form-control"
                      type="text"
                      name="url"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                    />
                    {errors.url && touched.url && (
                      <p className="hint error">{errors.url}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Fonts;
