import { gql } from "@apollo/client";

export const ROOT_COMPONENTS_QUERY = gql`
  query getRootComponents($projectId: Int!) {
    rootComponents(projectId: $projectId) {
      id
      htmlTag
      selector
      position
      parentComponentId
      containerComponentId
      htmlMarkup
    }
  }
`;

export const PAGE_QUERY = gql`
  query getPage($id: Int!) {
    page(id: $id) {
      id
      componentId
      name
      json
      html
      url
      categoryId
      component {
        id
        htmlTag
        selector
        htmlMarkup
      }
    }
  }
`;

export const PAGES_QUERY = gql`
  query getPages($projectId: Int!) {
    pages(projectId: $projectId) {
      id
      projectId
      componentId
      name
      json
      html
      url
      categoryId
      component {
        htmlTag
        selector
      }
    }
  }
`;

export const CREATE_OR_UPDATE_PAGE = gql`
  mutation CreateOrUpdatePage(
    $id: Int
    $componentId: Int
    $projectId: Int
    $categoryId: Int
    $name: String
    $json: JSON
    $html: String
  ) {
    createOrUpdatePage(
      input: {
        id: $id
        componentId: $componentId
        categoryId: $categoryId
        projectId: $projectId
        name: $name
        json: $json
        html: $html
      }
    ) {
      success
      error
      page {
        id
        componentId
        projectId
        categoryId
        name
        json
        html
        url
      }
    }
  }
`;

export const UPDATE_PAGE = gql`
  mutation UpdatePage($id: Int!, $name: String, $json: JSON, $html: String) {
    updatePage(input: { id: $id, name: $name, json: $json, html: $html }) {
      success
      error
      page {
        id
        componentId
        projectId
        categoryId
        name
        json
        html
      }
    }
  }
`;

export const DELETE_PAGE = gql`
  mutation DeletePage($id: Int!) {
    deletePage(input: { id: $id }) {
      success
      error
    }
  }
`;

export const CREATE_CATEGORY = gql`
  mutation CreateCategory($projectId: Int!, $label: String!, $slug: String) {
    createCategory(
      input: { projectId: $projectId, label: $label, slug: $slug }
    ) {
      success
      error
      category {
        id
        slug
        label
      }
    }
  }
`;
