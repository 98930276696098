import React from "react";
import { Link } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { PAGES_QUERY } from "./queries";

import Loader from "../common/Loader";

import blankImg from "../common/img/components.png";

import styled from "styled-components";

const Container = styled.div`
  padding-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  ::after {
    content: "";
    flex: auto;
    min-width: 32%;
    max-width: 32%;
  }

  .card {
    max-width: 32%;
    margin-bottom: 2%;
  }
`;

const BlankState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 50px 0px;
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  img {
    max-width: 300px;
  }

  .header {
    font-size: 20px;
    margin: 20px 0px 15px;
    font-weight: bold;
  }

  .desc {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

const Pages = props => {
  const { projectId, history } = props;
  const { loading, error, data } = useQuery(PAGES_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb active">Documentation</h1>
          </div>

          <div className="actions">
            <Link className="action" to="/docs/new">
              New Page
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        {data.pages.length == 0 && (
          <BlankState>
            <img src={blankImg} />
            <div className="header">No Pages Found</div>
            <p className="desc">
              Documentation pages help developers quickly find and use
              components
            </p>
            <div className="action">
              <Link className="btn btn-primary" to="/docs/new">
                Create Page
              </Link>
            </div>
          </BlankState>
        )}

        <Container>
          {data.pages.map((p, i) => {
            return (
              <div key={`page${i}`} className="card">
                <div className="card-body">
                  <h2 className="card-title">{p.name}</h2>
                  <div className="card-info">
                    {p.component && (
                      <span className="info-type">
                        {p.component.htmlTag}
                        {p.component.selector}
                      </span>
                    )}
                    {!p.component && (
                      <span className="info-type">No Component</span>
                    )}
                  </div>
                  <div className="actions">
                    <Link to={`/docs/${p.id}`} className="btn btn-primary">
                      Edit
                    </Link>{" "}
                    <a href={p.url} className="btn btn-dark" target="_blank">
                      View
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pages;
