import React, { useState, useEffect } from "react";
import { isEqual } from "lodash";

import Layer from "./Layer";

import styled from "styled-components";

const Title = styled.div`
  padding: 5px 10px;
  background-color: rgb(43, 43, 43);
  color: #ccc;
  font-size: 12px;
  border-top: 1px solid rgb(33, 33, 33);
  border-bottom: 1px solid rgb(33, 33, 33);
  text-transform: uppercase;
`;

const LayersContainer = styled.div`
  display: flex;
  max-width: 300px;
  flex: 1;
  flex-direction: column;

  .layer {
    font-size: 14px;
    color: #d9d9d9;
    padding: 10px;
    flex: 1;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &.dragging {
      opacity: 0.5;
    }

    &.is-over-container {
      background-color: #333333;
    }

    &.is-over {
      background-color: #000000;
    }

    &.active {
      color: #fff;
      background-color: #3b79c3;
    }
  }

  .layer-position-bar {
    background: rgba(255, 0, 0, 0.3);
    cursor: default;

    &.expand {
      padding: 5px;
      background: rgb(102, 102, 102);
    }

    &.active {
      padding: 5px;
      background: rgb(0, 0, 0);
    }
  }
`;

let componentsCache;
let cacheVersion = 1;

const Layers = props => {
  const {
    rootComponent,
    childComponents,
    updateComponent,
    isNestedParent,
    isDirectParent,
    getParentId,
    getMaxPosition,
    refetchChildComponents,
    symbolId
  } = props;

  let treeRootComponent = rootComponent;
  if (symbolId) {
    let symbolComponent = childComponents.find(c => c.id == symbolId);
    if (symbolComponent) {
      treeRootComponent = symbolComponent;
    }
  }

  const [layers, setLayers] = useState(null);

  const getChildren = component => {
    return childComponents
      .filter(c => c.parentComponentId == component.id)
      .map(c => {
        return {
          id: c.id,
          title: c.externalComponentId
            ? `${c.external.htmlTag}${c.external.selector}`
            : `${c.htmlTag}${c.selector}`,
          htmlTag: c.htmlTag,
          external: !!c.externalComponentId,
          children: getChildren(c)
        };
      });
  };

  const getTree = component => {
    return {
      id: component.id,
      title: `${component.htmlTag}${component.selector}`,
      htmlTag: component.htmlTag,
      external: !!component.externalComponentId,
      children: getChildren(component)
    };
  };

  const repositionComponent = async (
    componentId,
    action,
    cursorComponentId
  ) => {
    const parentComponentId = getParentId(cursorComponentId);
    const component = childComponents.find(c => c.id == componentId);

    const children = childComponents
      .filter(c => c.parentComponentId == parentComponentId)
      .filter(c => c.id != componentId)
      .sort((a, b) => (a.position > b.position ? 1 : -1));

    const cursorComponent = children.find(c => c.id == cursorComponentId);
    let beforeComponents = children.filter(
      c => c.position < cursorComponent.position
    );
    let afterComponents = children.filter(
      c => c.position > cursorComponent.position
    );

    let newChildren;
    if (action == "before") {
      newChildren = [
        ...beforeComponents,
        component,
        cursorComponent,
        ...afterComponents
      ];
    } else {
      newChildren = [
        ...beforeComponents,
        cursorComponent,
        component,
        ...afterComponents
      ];
    }

    let position = 0;
    await Promise.all(
      newChildren.map(async child => {
        position++;
        if (child.id == componentId) {
          await updateComponent({
            variables: {
              id: child.id,
              parentComponentId,
              position
            }
          });
        } else {
          await updateComponent({
            variables: {
              id: child.id,
              position
            }
          });
        }
      })
    );
    refetchChildComponents();
  };

  if (!isEqual(childComponents, componentsCache)) {
    componentsCache = childComponents;
    cacheVersion++;
  }

  useEffect(() => {
    setLayers(getTree(treeRootComponent));
  }, [cacheVersion, treeRootComponent.id, treeRootComponent.selector]);

  const renderLayer = (component, nested = 0) => {
    return (
      <Layer
        {...props}
        key={`layer${component.id}`}
        isRoot={component.id == rootComponent.id}
        isDirectParent={isDirectParent}
        isNestedParent={isNestedParent}
        getMaxPosition={getMaxPosition}
        repositionComponent={repositionComponent}
        nested={nested}
        component={component}
      >
        {component.children.map(childComponent => {
          return renderLayer(childComponent, nested + 1);
        })}
      </Layer>
    );
  };

  return (
    <React.Fragment>
      <Title>Layers</Title>
      {layers && <LayersContainer>{renderLayer(layers)}</LayersContainer>}
      {!layers && <div>Loading</div>}
    </React.Fragment>
  );
};
export default Layers;
