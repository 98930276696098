import React from "react";

import { Formik } from "formik";

import { useMutation } from "@apollo/client";

import { CREATE_UTILITY } from "./queries";

import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
`;

const NewUtility = ({ projectId, history }) => {
  const [createUtility] = useMutation(CREATE_UTILITY);

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Utilities /</h1>
            <h1 className="breadcrumb active">New Utility</h1>
          </div>
        </div>
      </div>

      <Container className="container">
        <Formik
          initialValues={{
            name: "",
            selector: ""
          }}
          validate={values => {
            const errors = {};
            if (!values.selector) {
              errors.selector = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            const response = await createUtility({
              variables: {
                projectId,
                ...values
              }
            });

            if (
              response &&
              response.data &&
              response.data.createUtility &&
              response.data.createUtility.success
            ) {
              const utilityId = response.data.createUtility.utility.id;
              history.push(`/components/utilities/${utilityId}`);
              return;
            }

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <label className="label">Selector</label>
                <input
                  className="form-control"
                  type="text"
                  name="selector"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.selector}
                />
                {!(errors.selector && touched.selector) && (
                  <p className="hint">
                    CSS Selector for this utility. For example: .container,
                    .row, .color-green
                  </p>
                )}
                {errors.selector && touched.selector && (
                  <p className="hint error">{errors.selector}</p>
                )}
              </div>

              <button
                className="btn btn-primary"
                type="submit"
                disabled={isSubmitting}
              >
                Create Utility
              </button>
            </form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default NewUtility;
