import React, { useEffect, useState } from "react";

import StyleEditor from "./Editors/Styles";
import SettingsEditor from "./Editors/Settings";
import CodeEditor from "./Editors/Code";

import ExternalEditor from "./ExternalEditor";
import SymbolEditor from "./SymbolEditor";

import classnames from "classnames";

import styled from "styled-components";

const EditorsContainer = styled.div`
  flex: 1;
  color: rgb(217, 217, 217);
  max-width: 300px;
  overflow-y: auto;
  background-color: rgb(83, 83, 83);
`;

const InactiveState = styled.div`
  text-align: center;
  background-color: #2b2b2b;
  color: #ccc;
  padding: 25px;
  margin-top: 20px;

  i {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .notice {
    font-size: 14px;
    line-height: 20px;
  }
`;

const TitleContainer = styled.div`
  padding: 10px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgb(43, 43, 43);

  .title {
    flex: 1;
    padding: 3px;
    font-size: 12px;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;

    .tag {
      color: #eee;
      margin-right: 3px;
    }
  }

  .actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;

    a {
      flex: 1;
      cursor: pointer;
      padding: 3px;
      max-width: 30px;
      text-align: center;
      color: #fff;

      &:hover {
        background-color: #444;
      }
    }
  }
`;

const TabsContainer = styled.div`
  display: flex;

  .tab {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 35px;
    color: #fff;
    background-color: rgb(43, 43, 43);
    cursor: pointer;
    box-shadow: inset -1px -1px 0 0 #000;

    i {
      padding: 2px;
    }
  }

  .active {
    background-color: rgb(83, 83, 83);
    box-shadow: inset -1px 0px 0 0 #000, -1px -1px 0 0 #000,
      0 2px 0 0 rgb(83, 83, 83);
  }

  .changed {
    i {
      color: rgb(139, 190, 250);
      background-color: rgba(76, 152, 241, 0.15);
    }
  }
`;

const Styles = props => {
  const [activeTab, setActiveTab] = useState("styles");

  const {
    activeComponentId,
    rootComponent,
    activeComponent,
    deleteComponent,
    symbolId,
    convertComponentToSymbol
  } = props;

  useEffect(() => {
    setActiveTab("styles");
  }, [activeComponentId]);

  if (!activeComponentId || !activeComponent)
    return (
      <EditorsContainer style={{ padding: "15px 25px" }}>
        <InactiveState>
          <i className="fas fa-mouse-pointer"></i>

          <div className="notice">
            Select an element on the canvas to activate this panel
          </div>
        </InactiveState>
      </EditorsContainer>
    );

  const isExternal = !!activeComponent.externalComponentId;
  const hasContainer =
    isExternal && !!activeComponent.external.containerComponentId;

  const canBeConvertedToSymbol =
    !isExternal &&
    !symbolId &&
    !!activeComponent &&
    !activeComponent.containerComponentId &&
    activeComponent.id != rootComponent.id;

  return (
    <EditorsContainer>
      {!isExternal && (
        <TabsContainer>
          <a
            className={classnames({ tab: true, active: activeTab == "styles" })}
            onClick={() => setActiveTab("styles")}
          >
            <i className="fas fa-paint-brush"></i>
          </a>

          <a
            className={classnames({
              tab: true,
              active: activeTab == "settings"
            })}
            onClick={() => setActiveTab("settings")}
          >
            <i className="fas fa-cog"></i>
          </a>

          <a
            className={classnames({
              tab: true,
              active: activeTab == "code",
              changed: !!activeComponent.customCss
            })}
            onClick={() => setActiveTab("code")}
          >
            <i className="fas fa-code"></i>
          </a>
        </TabsContainer>
      )}

      <TitleContainer>
        <div className="title">
          <span className="tag">
            {isExternal
              ? activeComponent.external.htmlTag
              : activeComponent.htmlTag}
          </span>
          {isExternal
            ? activeComponent.external.selector
            : activeComponent.selector}
        </div>

        <div className="actions">
          {canBeConvertedToSymbol && (
            <a onClick={() => convertComponentToSymbol(activeComponentId)}>
              <i className="fas fa-cube"></i>
            </a>
          )}

          {rootComponent.id != activeComponent.id && (
            <a
              onClick={() => {
                if (window.confirm("Are you sure?")) {
                  deleteComponent(activeComponent.id);
                }
              }}
            >
              <i className="far fa-trash-alt"></i>
            </a>
          )}
        </div>
      </TitleContainer>

      {activeTab == "styles" && (
        <React.Fragment>
          {!isExternal && <StyleEditor {...props} />}
          {isExternal && !hasContainer && <ExternalEditor {...props} />}
          {isExternal && hasContainer && <SymbolEditor {...props} />}
        </React.Fragment>
      )}

      {activeTab == "settings" && (
        <React.Fragment>
          {!isExternal && <SettingsEditor {...props} />}
        </React.Fragment>
      )}

      {activeTab == "code" && (
        <React.Fragment>
          {!isExternal && <CodeEditor {...props} />}
        </React.Fragment>
      )}
    </EditorsContainer>
  );
};
export default Styles;
