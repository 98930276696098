import React from "react";
import { Link, Redirect } from "react-router-dom";

import { useQuery } from "@apollo/client";

import { PROJECTS_QUERY, INVITATIONS_QUERY } from "./queries";

import Loader from "../../components/common/Loader";
import Invitations from "./Invitations";

import styled from "styled-components";

const ProjectsContainer = styled.div`
  padding-top: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  ::after {
    content: "";
    flex: auto;
    min-width: 32%;
    max-width: 32%;
  }

  .card {
    max-width: 32%;
    margin-bottom: 2%;
  }
`;

const Projects = (props) => {
  const { loading, error, data, refetch } = useQuery(PROJECTS_QUERY, {
    fetchPolicy: "network-only",
  });

  const {
    loading: loadingInvitations,
    error: errorInvitations,
    data: dataInvitations,
    refetch: refetchInvitations,
  } = useQuery(INVITATIONS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      filters: {
        status: "PENDING",
      },
    },
  });

  if (loading || loadingInvitations) return <Loader />;
  if (error) return <div>Error</div>;

  if (data.projects.length == 0 && dataInvitations.invitations.length == 0) {
    return (
      <Redirect
        to={{
          pathname: "/projects/new",
        }}
      />
    );
  }

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb active">Projects</h1>
          </div>

          <div className="actions">
            <Link className="action" to="/projects/new">
              New Project
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        {data.projects.length == 0 && dataInvitations.invitations.length == 0 && (
          <div
            style={{
              padding: 20,
              paddingTop: 100,
              textAlign: "center",
              color: "#999",
              fontWeight: "bold",
            }}
          >
            No Projects Found
          </div>
        )}

        <ProjectsContainer>
          <Invitations
            {...props}
            invitations={dataInvitations.invitations}
            refetchInvitations={refetchInvitations}
            refetchProjects={refetch}
          />

          {data.projects.map((p, i) => {
            return (
              <div key={i} className="card">
                <div className="card-body">
                  <h2 className="card-title">{p.name}</h2>
                  <p className="card-info">
                    {p.rootComponents.length} components
                  </p>
                  <Link to={`/projects/${p.id}`} className="btn btn-primary">
                    Open
                  </Link>
                </div>
              </div>
            );
          })}
        </ProjectsContainer>
      </div>
    </React.Fragment>
  );
};

export default Projects;
