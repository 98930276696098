import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import styled from "styled-components";

import EditorGroup from "../../../../common/Editors/EditorGroup";

import "codemirror/mode/css/css";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/display/autorefresh";
import CodeMirror from "codemirror/lib/codemirror";

const Container = styled.div`
  .CodeMirror {
    height: auto;
    font-size: 12px;
  }

  .CodeMirror-scroll {
    min-height: 300px;
  }
`;

const Editors = props => {
  const { activeComponent, updateComponent, bumpLibFileVersion } = props;

  const [css, setCss] = useState(activeComponent.customCss);

  const _debounceTimer = useRef();
  const _textarea = useRef(null);
  const _codeMirror = useRef(null);

  const onUpdateCss = async customCss => {
    await updateComponent({
      variables: {
        id: activeComponent.id,
        customCss
      }
    });

    bumpLibFileVersion();
  };

  useEffect(() => {
    if (css == activeComponent.customCss) return;
    if (_debounceTimer.current) clearTimeout(_debounceTimer.current);
    _debounceTimer.current = setTimeout(() => {
      onUpdateCss(css);
    }, 300);
  }, [css]);

  useLayoutEffect(() => {
    _codeMirror.current = CodeMirror.fromTextArea(_textarea.current, {
      mode: { name: "css" },
      lineNumbers: true,
      lineWrapping: true
    });
    _codeMirror.current.on("change", (doc, change) => {
      if (change.origin !== "setValue") {
        setCss(doc.getValue().trim());
      }
    });
    _codeMirror.current.setValue(css || "");
  }, []);

  return (
    <Container>
      <EditorGroup title="Custom CSS">
        <textarea ref={_textarea}></textarea>
      </EditorGroup>
    </Container>
  );
};
export default Editors;
