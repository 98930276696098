import { gql } from "@apollo/client";

export const COMPONENT_QUERY = gql`
  query getComponent($id: Int!) {
    component(id: $id) {
      id
      htmlTag
      selector
      textContent
      position
      containerComponentId
      externalComponentId
      htmlTagAttributes
      customCssClasses
      customCss
      variations {
        id
        selector
      }
      utilities {
        id
        selector
      }
      children {
        id
        externalComponentId
      }
      styles {
        id
        componentId
        rootComponentId
        variationId
        device
        selectors
        properties
      }
    }
  }
`;

export const ROOT_COMPONENTS_QUERY = gql`
  query getRootComponents($projectId: Int!) {
    rootComponents(projectId: $projectId) {
      id
      htmlTag
      htmlTagAttributes
      selector
      position
      parentComponentId
      containerComponentId
      customCssClasses
      customCss
    }
  }
`;

export const COMPONENTS_QUERY = gql`
  query getComponents($projectId: Int!) {
    components(projectId: $projectId) {
      id
      htmlTag
      htmlTagAttributes
      selector
      position
      parentComponentId
      containerComponentId
      customCssClasses
      customCss
    }
  }
`;

export const CHILD_COMPONENTS_QUERY = gql`
  query getChildComponents($projectId: Int!, $rootComponentId: Int!) {
    components(projectId: $projectId, rootComponentId: $rootComponentId) {
      id
      htmlTag
      htmlTagAttributes
      selector
      textContent
      position
      parentComponentId
      externalComponentId
      containerComponentId
      externalVariationId
      customCssClasses
      customCss
      variations {
        id
        selector
      }
      utilities {
        id
        selector
      }
      styles {
        id
        componentId
        rootComponentId
        variationId
        device
        selectors
        properties
      }
      external {
        id
        htmlTag
        htmlTagAttributes
        selector
        textContent
        position
        parentComponentId
        externalComponentId
        containerComponentId
        externalVariationId
        customCssClasses
        customCss
        variations {
          id
          selector
        }
        styles {
          id
          componentId
          rootComponentId
          variationId
          device
          selectors
          properties
        }
      }
    }
  }
`;

export const CREATE_COMPONENT = gql`
  mutation CreateComponent(
    $parentComponentId: Int
    $projectId: Int!
    $htmlTag: String!
    $position: Int!
    $selector: String
    $textContent: String
    $externalComponentId: Int
    $containerComponentId: Int
    $htmlTagAttributes: JSON
    $customCssClasses: JSON
  ) {
    createComponent(
      input: {
        parentComponentId: $parentComponentId
        projectId: $projectId
        htmlTag: $htmlTag
        position: $position
        selector: $selector
        textContent: $textContent
        externalComponentId: $externalComponentId
        containerComponentId: $containerComponentId
        htmlTagAttributes: $htmlTagAttributes
        customCssClasses: $customCssClasses
      }
    ) {
      success
      error
      component {
        id
        htmlTag
        selector
        textContent
        position
        parentComponentId
        externalComponentId
        containerComponentId
        htmlTagAttributes
        customCssClasses
      }
    }
  }
`;

export const UPDATE_COMPONENT = gql`
  mutation UpdateComponent(
    $id: Int!
    $parentComponentId: Int
    $htmlTag: String
    $position: Int
    $selector: String
    $textContent: String
    $externalVariationId: Int
    $htmlTagAttributes: JSON
    $customCssClasses: JSON
    $customCss: String
  ) {
    updateComponent(
      input: {
        id: $id
        parentComponentId: $parentComponentId
        htmlTag: $htmlTag
        position: $position
        selector: $selector
        textContent: $textContent
        externalVariationId: $externalVariationId
        htmlTagAttributes: $htmlTagAttributes
        customCssClasses: $customCssClasses
        customCss: $customCss
      }
    ) {
      success
      error
      component {
        id
        htmlTag
        selector
        textContent
        position
        parentComponentId
        externalVariationId
        customCssClasses
        customCss
        styles {
          id
          componentId
          rootComponentId
          variationId
          device
          selectors
          properties
        }
      }
    }
  }
`;

export const DELETE_COMPONENT = gql`
  mutation DeleteComponent($id: Int!) {
    deleteComponent(input: { id: $id }) {
      success
      error
    }
  }
`;

export const CREATE_OR_UPDATE_STYLE = gql`
  mutation CreateOrUpdateStyle(
    $id: Int
    $componentId: Int
    $rootComponentId: Int
    $variationId: Int
    $device: Device
    $selectors: [String!]
    $properties: JSON
  ) {
    createOrUpdateStyle(
      input: {
        id: $id
        componentId: $componentId
        rootComponentId: $rootComponentId
        variationId: $variationId
        device: $device
        selectors: $selectors
        properties: $properties
      }
    ) {
      success
      error
      style {
        id
        componentId
        rootComponentId
        variationId
        device
        selectors
        properties
        component {
          id
          styles {
            id
            properties
          }
        }
      }
    }
  }
`;

export const CREATE_VARIATION = gql`
  mutation CreateVariation($componentId: Int!, $selector: String!) {
    createVariation(input: { componentId: $componentId, selector: $selector }) {
      success
      error
      variation {
        id
        selector
        component {
          id
          variations {
            id
            selector
          }
        }
      }
    }
  }
`;

export const DELETE_VARIATION = gql`
  mutation DeleteVariation($id: Int!) {
    deleteVariation(input: { id: $id }) {
      success
      error
    }
  }
`;

export const CONVERT_COMPONENT_TO_SYMBOL = gql`
  mutation ConvertComponentToSymbol($id: Int!) {
    convertComponentToSymbol(input: { id: $id }) {
      success
      error
      component {
        id
        htmlTag
        selector
        textContent
        position
        containerComponentId
        externalComponentId
      }
      symbol {
        id
        htmlTag
        selector
      }
    }
  }
`;

export const UPDATE_COMPONENT_UTILITIES = gql`
  mutation UpdateComponentUtilities($componentId: Int!, $utilityIds: [Int]!) {
    updateComponentUtilities(
      input: { componentId: $componentId, utilityIds: $utilityIds }
    ) {
      success
      error
      utilities {
        id
        selector
      }
      component {
        id
      }
    }
  }
`;

export const CREATE_BUILD = gql`
  mutation CreateBuild($projectId: Int!, $comment: String) {
    createBuild(input: { projectId: $projectId, comment: $comment }) {
      success
      build {
        id
        projectId
        comment
      }
    }
  }
`;
