import React, { useRef } from "react";
import { useDrag } from "react-dnd";

import Component from "./Component";

import ExternalLayer from "./ExternalLayer";

const ChildComponent = ({
  projectId,
  project,
  activeComponentId,
  activeDevice,
  childComponents,
  component,
  variationId
}) => {
  const children = childComponents.filter(
    c => c.parentComponentId == component.id
  );

  const isExternal = !!component.externalComponentId;

  const Cursor = isExternal ? ExternalLayer : Component;

  return (
    <Cursor
      key={`externalcomponent${component.id}`}
      project={project}
      component={component}
      activeComponentId={activeComponentId}
      activeDevice={activeDevice}
      variationId={variationId}
      externalChild={true}
    >
      {children.length > 0 &&
        children.map(c => (
          <ChildComponent
            key={`childcomponent${c.id}`}
            projectId={projectId}
            project={project}
            activeComponentId={activeComponentId}
            activeDevice={activeDevice}
            variationId={variationId}
            childComponents={childComponents}
            component={c}
          />
        ))}
      {children.length == 0 && component.textContent}
    </Cursor>
  );
};

const RootComponent = props => {
  const {
    component,
    projectId,
    project,
    activeDevice,
    activeComponentId,
    childComponents
  } = props;

  const symbolComponent = component.external;
  const symbolChildren = childComponents.filter(
    c => c.parentComponentId == symbolComponent.id
  );

  return (
    <Component
      {...props}
      externalComponent={symbolComponent}
      key={`layer-${component.id}-${symbolComponent.id}`}
      variationId={component.externalVariationId}
    >
      {symbolChildren.length > 0 &&
        symbolChildren.map(c => (
          <ChildComponent
            key={`symbolChildComponent${c.id}`}
            projectId={projectId}
            project={project}
            activeComponentId={activeComponentId}
            activeDevice={activeDevice}
            childComponents={childComponents}
            variationId={component.externalVariationId}
            component={c}
          />
        ))}
      {symbolChildren.length == 0 && symbolComponent.textContent}
    </Component>
  );
};

const SymbolLayer = props => {
  return <RootComponent {...props} />;
};

export default SymbolLayer;
