import React, { useState, useEffect } from "react";

import Container from "./Container";

import { isEqual } from "lodash";

let childComponentsCache = [];
let cacheVersion = 1;

const Designer = props => {
  const {
    rootComponent,
    allChildComponents,
    deleteComponent,
    convertComponentToSymbol,
    updateComponentUtilities,
    createVariation,
    deleteVariation,
    refetchChildComponents,
    refetchRootComponent,
    symbolId,
    setSymbolId,
    symbolShadowId,
    setSymbolShadowId,
    activeDevice,
    setActiveDevice,
    bumpDesignVersion
  } = props;
  const [childComponents, setChildComponents] = useState([]);

  useEffect(() => {
    childComponentsCache = allChildComponents;
    setChildComponents(allChildComponents);
  }, [cacheVersion]);

  if (!isEqual(allChildComponents, childComponentsCache)) {
    childComponentsCache = allChildComponents;
    cacheVersion++;
  }

  const [variations, setVariations] = useState([]);

  useEffect(() => {
    let component = rootComponent;
    if (symbolId) {
      component = findComponent(symbolId);
    }
    setVariations(component.variations);
  }, [rootComponent.variations.length, symbolId]);

  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);
  const [createOptions, setCreateOptions] = useState({});

  const [hoverComponentId, setHoverComponentId] = useState(null);
  const [dropComponentId, setDropComponentId] = useState(null);
  const [activeComponentId, setActiveComponentId] = useState(
    symbolId ? symbolId : null
  );
  const [activeVariationId, setActiveVariationId] = useState(null);
  const [activeComponentState, setActiveComponentState] = useState("");

  let activeComponent;
  let symbolComponent;
  let hoverComponent;
  let dropComponent;
  let parentComponent;
  let containerComponent;
  let symbolShadowComponent;

  const findComponent = id => {
    if (id == rootComponent.id) {
      return rootComponent;
    } else {
      let component = childComponents.find(c => c.id == id);
      if (!component) {
        component = childComponents.find(c => c.externalComponentId == id);
        if (component) return component.external;
      }
      return component;
    }
  };

  if (activeComponentId) {
    activeComponent = findComponent(activeComponentId);

    if (!activeComponent) {
      // setActiveComponentId(null);
    } else {
      if (activeComponent.parentComponentId) {
        parentComponent = findComponent(activeComponent.parentComponentId);
      }
    }
  }

  if (symbolId) {
    symbolComponent = findComponent(symbolId);

    // If symbol is the active one, we'll find it's container to pass on the styles
    if (symbolId == activeComponentId) {
      symbolShadowComponent = findComponent(symbolShadowId);
      if (symbolShadowComponent)
        containerComponent = findComponent(
          symbolShadowComponent.parentComponentId
        );
    }
  }

  if (hoverComponentId) {
    hoverComponent = findComponent(hoverComponentId);
    if (!hoverComponent) setHoverComponentId(null);
  }

  if (dropComponentId) {
    dropComponent = findComponent(dropComponentId);
    if (!dropComponent) setDropComponentId(null);
  }

  // If we get inside or outside of a symbol, change the variation id to null
  useEffect(() => {
    let newVariationId = null;

    // If the symbol has a variation selected, make sure its active once inside
    if (symbolShadowComponent) {
      newVariationId = symbolShadowComponent.externalVariationId;
    }

    setActiveVariationId(newVariationId);
  }, [symbolId]);

  // Bump design version if component or variation is changed
  // This helps reposition the layer outlines
  useEffect(() => {
    bumpDesignVersion();
  }, [activeComponent, activeVariationId]);

  const onDeleteComponent = async componentId => {
    await deleteComponent({
      variables: {
        id: componentId
      }
    });

    // If the active component is deleted, reset active
    if (activeComponentId == componentId) {
      setActiveComponentId(symbolId ? symbolId : null);
    }

    // If the symbol is deleted, exit symbol mode
    if (symbolId == componentId) {
      setSymbolId(null);
      setSymbolShadowId(null);
    }

    let updatedChildComponents = childComponents
      .filter(c => c.id != componentId)
      .filter(c => c.externalComponentId != componentId); // shadow components

    setChildComponents(updatedChildComponents);
    refetchChildComponents();
  };

  const onConvertToSymbol = async componentId => {
    const resp = await convertComponentToSymbol({
      variables: {
        id: parseInt(componentId)
      }
    });

    if (
      resp &&
      resp.data &&
      resp.data.convertComponentToSymbol &&
      resp.data.convertComponentToSymbol.success
    ) {
      await refetchChildComponents();

      const component = resp.data.convertComponentToSymbol.component;
      const symbol = resp.data.convertComponentToSymbol.symbol;

      setSymbolId(symbol.id);
      setSymbolShadowId(component.id);
      setActiveComponentId(symbol.id);
      setDropComponentId(null);
    }
  };

  const onUpdateComponentUtilities = async utilities => {
    const utilityIds = utilities ? utilities.map(u => parseInt(u.value)) : [];

    const resp = await updateComponentUtilities({
      variables: {
        componentId: parseInt(activeComponentId),
        utilityIds: utilityIds
      }
    });

    if (activeComponentId == rootComponent.id) {
      refetchRootComponent();
    } else {
      refetchChildComponents();
    }
  };

  const onCreateVariation = async selector => {
    const resp = await createVariation({
      variables: {
        componentId: symbolId ? symbolId : rootComponent.id,
        selector
      }
    });

    if (
      resp &&
      resp.data &&
      resp.data.createVariation &&
      resp.data.createVariation.success
    ) {
      setVariations([...variations, resp.data.createVariation.variation]);
    }
  };

  const onDeleteVariation = async variationId => {
    await deleteVariation({
      variables: {
        id: parseInt(variationId)
      }
    });

    if (activeVariationId == variationId) {
      setActiveVariationId(null);
    }

    setVariations(variations.filter(v => v.id != variationId));
  };

  const getChildComponents = parentId => {
    const components = childComponents.filter(
      c => c.parentComponentId == parentId
    );
    return components;
  };

  const isNestedParent = (parentId, childId) => {
    let child = childComponents.find(c => c.parentComponentId == parentId);
    if (!child) return false;

    while (!child || child.id != childId) {
      child = childComponents.find(c => c.parentComponentId == child.id);
      if (!child) break;
    }

    return !!child;
  };

  const isDirectParent = (parentId, childId) => {
    const child = childComponents.find(c => c.id == childId);
    return child.parentComponentId == parentId;
  };

  const getParentId = componentId => {
    return childComponents.find(c => c.id == componentId).parentComponentId;
  };

  const getMaxPosition = parentComponentId => {
    const children = childComponents.filter(
      c => c.parentComponentId == parentComponentId
    );
    return Math.max.apply(Math, [0, ...children.map(c => c.position)]) + 1;
  };

  const childProps = {
    ...props,
    deleteComponent: onDeleteComponent,
    createVariation: onCreateVariation,
    deleteVariation: onDeleteVariation,
    convertComponentToSymbol: onConvertToSymbol,
    updateComponentUtilities: onUpdateComponentUtilities,
    childComponents,
    variations,
    activeDevice,
    setActiveDevice,
    symbolComponent,
    activeComponent,
    activeComponentId,
    hoverComponent,
    hoverComponentId,
    setHoverComponentId,
    dropComponent,
    dropComponentId,
    setDropComponentId,
    parentComponent,
    containerComponent,
    setActiveComponentId,
    activeVariationId,
    setActiveVariationId,
    activeComponentState,
    setActiveComponentState,
    symbolId,
    setSymbolId,
    symbolShadowId,
    setSymbolShadowId,
    createModalIsOpen,
    setCreateModalIsOpen,
    createOptions,
    setCreateOptions,
    isNestedParent,
    isDirectParent,
    getParentId,
    getMaxPosition,
    getChildComponents
  };

  return <Container {...childProps} />;
};

export default Designer;
