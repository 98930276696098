import React from "react";
import styled from "styled-components";

import types from "./types";

const Container = styled.div``;

const MultiEditor = props => {
  const { editorKey, type } = props;
  const Component = types[type];

  return (
    <Container>
      <Component key={`${editorKey}:${type}`} {...props} />
    </Container>
  );
};
export default MultiEditor;
