import React, { useState } from "react";

import Loader from "../../../components/common/Loader";
import Modal from "../../common/Modal";
import Member from "./Member";
import Invitation from "./Invitation";

import { Formik } from "formik";

import { useQuery, useMutation } from "@apollo/client";

import { PROJECT_QUERY, INVITE_USER_TO_PROJECT, UNINVITE_USER_FROM_PROJECT } from "./queries";

const Team = props => {
  const { projectId } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [inviteUserToProject] = useMutation(INVITE_USER_TO_PROJECT);
  const [uninviteUserFromProject] = useMutation(UNINVITE_USER_FROM_PROJECT);

  const { loading, error, data, refetch } = useQuery(PROJECT_QUERY, {
    variables: { id: parseInt(projectId) }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  let owner = {};
  let members = [];
  let invitations = [];
  if (data && data.project) {
    members = data.project.members;
    invitations = data.project.invitations.filter(
      i => !i.acceptedAt && !i.declinedAt
    );
    owner = data.project.owner;
  }

  return (
    <div>
      <div className="settings-header">
        <div className="title">Team Members</div>
        <div className="action">
          <a
            className="btn btn-primary"
            onClick={() => {
              setModalIsOpen(true);
            }}
          >
            Invite Member
          </a>
        </div>
      </div>

      {members.length == 0 && (
        <div
          style={{
            padding: 20,
            textAlign: "center",
            color: "#999",
            fontWeight: "bold"
          }}
        >
          No Members Found
        </div>
      )}

      {members.length > 0 && (
        <div className="list">
          {members.map(m => {
            return (
              <Member
                key={`member-${m.user.id}`}
                {...props}
                owner={owner}
                member={m}
              />
            );
          })}
        </div>
      )}

      {invitations.length > 0 && (
        <div
          style={{ borderTop: "1px solid #EEE", marginTop: 40, paddingTop: 40 }}
        >
          <div className="settings-header">
            <div className="title">Pending Members</div>
          </div>

          <div className="list">
            {invitations.map(i => {
              return (
                <Invitation
                  key={`invitation-${i.id}`}
                  {...props}
                  uninviteUserFromProject={uninviteUserFromProject}
                  refetchProject={refetch}
                  invitation={i}
                />
              );
            })}
          </div>
        </div>
      )}

      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            email: ""
          }}
          validate={values => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await inviteUserToProject({
              variables: {
                projectId,
                email: values.email
              }
            });

            refetch();
            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">Invite User</h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Email</label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <p className="hint error">{errors.email}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Team;
