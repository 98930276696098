import React from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";

import {
  getStyles,
  jsonToCss,
  cssToStyle,
  objectDiff
} from "../../common/utils";

import {
  GET_UTILITY,
  DELETE_UTILITY,
  CREATE_OR_UPDATE_UTILITY_STYLE
} from "./queries";

import Loader from "../../../components/common/Loader";

import Editors from "../../common/Editors";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  height: calc(100vh - 140px);
  background-color: #282828;
`;

const Preview = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
`;

const CanvasContainer = styled.div`
  flex: 1;

  .utility-selector {
    text-align: center;
    color: #777;
    margin-bottom: 15px;
  }
`;

const Canvas = styled.div`
  color: #fff;
  padding: 20px 40px;
  border: 2px dashed #777;
`;

const EditorsContainer = styled.div`
  flex: 1;
  color: rgb(217, 217, 217);
  max-width: 300px;
  overflow-y: auto;
  background-color: rgb(83, 83, 83);
`;

const Utility = props => {
  const { history, project } = props;
  const { fonts, colors } = project;

  let { utilityId } = useParams();

  const [createOrUpdateUtilityStyle] = useMutation(
    CREATE_OR_UPDATE_UTILITY_STYLE
  );
  const [deleteUtility] = useMutation(DELETE_UTILITY);

  const { loading, error, data, refetch } = useQuery(GET_UTILITY, {
    variables: { id: parseInt(utilityId) }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const utility = data.utility;

  let activeDevice = "DESKTOP";
  let selectors = [utility.selector];
  // if (variation) selectors.push(variation.selector);
  // if (activeComponentState && activeComponentId == component.id)
  //   selectors.push(`:${activeComponentState}`);

  let styles = getStyles(
    utility.styles,
    selectors,
    utility.variationId,
    activeDevice
  );

  const css = jsonToCss(
    {
      ...styles.inherited,
      ...styles.active
    },
    fonts,
    colors
  );

  const cssStyles = cssToStyle(css);

  const updateProperty = async (name, value) => {
    let newStyles = {
      ...styles.active
    };

    if (!!value) {
      newStyles[name] = value;
    } else {
      delete newStyles[name];
    }

    await createOrUpdateUtilityStyle({
      variables: {
        utilityId: parseInt(utility.id),
        selectors: [utility.selector],
        device: "DESKTOP",
        properties: objectDiff(styles.inherited, newStyles)
      }
    });
  };

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Utilities /</h1>
            <h1 className="breadcrumb active">{utility.selector}</h1>
          </div>

          <div className="actions">
            <a
              className="action action-danger"
              onClick={async () => {
                if (window.confirm("Are you sure?")) {
                  await deleteUtility({
                    variables: {
                      id: utility.id
                    }
                  });
                  history.push("/components/utilities");
                }
              }}
            >
              Delete
            </a>
          </div>
        </div>
      </div>

      <Container>
        <Preview>
          <CanvasContainer>
            <div className="utility-selector">{utility.selector}</div>
            <Canvas style={cssStyles} />
          </CanvasContainer>
        </Preview>
        <EditorsContainer>
          <Editors
            editorKey={`${utility.id}`}
            style={styles}
            onChange={updateProperty}
            project={project}
          />
        </EditorsContainer>
      </Container>
    </React.Fragment>
  );
};

export default Utility;
