import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";

import Dashboard from "../components/Dashboard";
import Project from "./Project";

import Loader from "../components/common/Loader";

import { useQuery, gql } from "@apollo/client";

const ME_QUERY = gql`
  {
    me {
      id
      email
      name
      createdAt
    }
  }
`;

const LoggedIn = () => {
  let history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (typeof window.gtag != "undefined") {
        window.gtag("config", "UA-171040204-1", {
          page_path: window.location.pathname,
        });
      }
    });
  }, [history]);

  const { loading, error, data } = useQuery(ME_QUERY);

  if (loading) return <Loader />;

  if (error || !data || !data.me) {
    localStorage.removeItem("monad:token");

    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  const childProps = {
    currentUser: data.me,
    history,
  };

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/">
          <Dashboard {...childProps} />
        </Route>

        <Project {...childProps} />
      </Switch>
    </React.Fragment>
  );
};

export default LoggedIn;
