import React from "react";

const Member = ({ member, owner, currentUser }) => {
  const { user, role } = member;
  const { id, email, name } = user;

  return (
    <>
      <div className="list-item">
        <div className="list-info">
          <div className="title">{email}</div>
          <div className="description">
            {name} {name && <>&mdash;</>} {role.toLowerCase()}
          </div>
        </div>

        {owner.id != id && (
          <div className="actions">
            <a className="action-item">
              <i className="action-icon fas fa-trash"></i>
            </a>
          </div>
        )}
      </div>
    </>
  );
};

export default Member;
