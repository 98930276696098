import { gql } from "@apollo/client";

export const GET_DEPENDENCIES = gql`
  query getDependencies($projectId: Int!) {
    dependencies(projectId: $projectId) {
      id
      name
      position
      type
      url
    }
  }
`;

export const CREATE_DEPENDENCY = gql`
  mutation CreateDependency(
    $projectId: Int!
    $name: String!
    $position: Int
    $url: String!
    $type: DependencyType!
  ) {
    createDependency(
      input: {
        projectId: $projectId
        name: $name
        position: $position
        url: $url
        type: $type
      }
    ) {
      success
      error
      dependency {
        id
        name
        position
        type
        url
      }
    }
  }
`;

export const UPDATE_DEPENDENCY = gql`
  mutation UpdateDependency(
    $id: Int!
    $name: String
    $position: Int
    $url: String
    $type: DependencyType
  ) {
    updateDependency(
      input: {
        id: $id
        name: $name
        position: $position
        url: $url
        type: $type
      }
    ) {
      success
      error
      dependency {
        id
        name
        position
        type
        url
      }
    }
  }
`;

export const DELETE_DEPENDENCY = gql`
  mutation DeleteDependency($id: Int!) {
    deleteDependency(input: { id: $id }) {
      success
      error
    }
  }
`;
