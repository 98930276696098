import React, { useState } from "react";

import Loader from "../../../components/common/Loader";
import Modal from "../../common/Modal";
import Variable from "./Variable";

import { Formik } from "formik";

import { useQuery, useMutation } from "@apollo/client";

import {
  GET_VARIABLES,
  CREATE_VARIABLE,
  UPDATE_VARIABLE,
  DELETE_VARIABLE,
  GET_FONTS,
  GET_COLORS
} from "./queries";

const Variables = ({ projectId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [variableType, setVariableType] = useState("custom");
  const [editVariable, setEditVariable] = useState({});
  const [createVariable] = useMutation(CREATE_VARIABLE);
  const [updateVariable] = useMutation(UPDATE_VARIABLE);
  const [deleteVariable] = useMutation(DELETE_VARIABLE);

  const { loading, error, data, refetch } = useQuery(GET_VARIABLES, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  const {
    loading: loadingFonts,
    error: errorFonts,
    data: dataFonts
  } = useQuery(GET_FONTS, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  const {
    loading: loadingColors,
    error: errorColors,
    data: dataColors
  } = useQuery(GET_COLORS, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading || loadingFonts || loadingColors) return <Loader />;
  if (error || errorFonts || errorColors) return <div>Error</div>;

  const onEditVariable = variable => {
    setEditVariable(variable);
    setVariableType(
      variable.fontId ? "fonts" : variable.colorId ? "colors" : "custom"
    );
    setModalIsOpen(true);
  };

  const onDeleteVariable = async variableId => {
    if (window.confirm("Are you sure?")) {
      await deleteVariable({
        variables: {
          id: variableId
        }
      });
      refetch();
    }
  };

  return (
    <div>
      <div className="settings-header">
        <div className="title">Variables</div>
        <div className="action">
          <a
            className="btn btn-primary"
            onClick={() => {
              setEditVariable({});
              setModalIsOpen(true);
            }}
          >
            New Variable
          </a>
        </div>
      </div>

      {data && data.variables.length == 0 && (
        <div
          style={{
            padding: 20,
            textAlign: "center",
            color: "#999",
            fontWeight: "bold"
          }}
        >
          No Variables Found
        </div>
      )}

      {data && data.variables.length > 0 && (
        <div className="list">
          {data &&
            data.variables.map(v => {
              return (
                <Variable
                  key={`variable${v.id}`}
                  variable={v}
                  fonts={dataFonts.fonts}
                  colors={dataColors.colors}
                  onEditVariable={onEditVariable}
                  onDeleteVariable={onDeleteVariable}
                />
              );
            })}
        </div>
      )}

      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            name: "",
            value: "",
            fontId: "",
            colorId: "",
            ...editVariable
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (variableType == "custom") {
              if (!values.value) {
                errors.value = "Required";
              }
            } else if (variableType == "fonts") {
              if (!values.fontId) {
                errors.fontId = "Required";
              }
            } else if (variableType == "colors") {
              if (!values.colorId) {
                errors.colorId = "Required";
              }
            }

            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            let updates = {
              name: values.name
            };

            if (variableType == "custom") {
              updates["value"] = values.value;
            } else if (variableType == "fonts") {
              updates["fontId"] = parseInt(values.fontId);
            } else if (variableType == "colors") {
              updates["colorId"] = parseInt(values.colorId);
            }

            if (editVariable && editVariable.id) {
              await updateVariable({
                variables: {
                  id: parseInt(editVariable.id),
                  ...updates
                }
              });
            } else {
              let resp = await createVariable({
                variables: {
                  projectId,
                  ...updates
                }
              });
            }

            refetch();
            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">
                    {editVariable.id ? "Edit" : "New"} Variable
                  </h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Type</label>
                    <select
                      className="form-select"
                      value={variableType}
                      onChange={e => setVariableType(e.target.value)}
                    >
                      <option value="custom">Custom Value</option>
                      <option value="fonts">Font</option>
                      <option value="colors">Color</option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label className="label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p className="hint error">{errors.name}</p>
                    )}
                  </div>

                  {variableType == "custom" && (
                    <div className="form-group">
                      <label className="label">Value</label>
                      <input
                        className="form-control"
                        type="text"
                        name="value"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.value}
                      />
                      {errors.value && touched.value && (
                        <p className="hint error">{errors.value}</p>
                      )}
                    </div>
                  )}

                  {variableType == "fonts" && (
                    <div className="form-group">
                      <label className="label">Fonts</label>
                      <select
                        className="form-select"
                        name="fontId"
                        value={values.fontId}
                        onChange={handleChange}
                      >
                        <option value="">Choose font</option>
                        {dataFonts.fonts.map(f => (
                          <option key={`font${f.id}`} value={f.id}>
                            {f.name}
                          </option>
                        ))}
                      </select>
                      {errors.fontId && touched.fontId && (
                        <p className="hint error">{errors.fontId}</p>
                      )}
                    </div>
                  )}

                  {variableType == "colors" && (
                    <div>
                      <label className="label">Colors</label>
                      <select
                        className="form-select"
                        name="colorId"
                        value={values.colorId}
                        onChange={handleChange}
                      >
                        <option value="">Choose color</option>
                        {dataColors.colors.map(c => (
                          <option key={`font${c.id}`} value={c.id}>
                            {c.name}
                          </option>
                        ))}
                      </select>
                      {errors.colorId && touched.colorId && (
                        <p className="hint error">{errors.colorId}</p>
                      )}
                    </div>
                  )}
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Variables;
