import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getStyles, objectDiff } from "../../../../common/utils";

import CssClasses from "./CssClasses";
import Markup from "./Markup";

import EditorGroup from "../../../../common/Editors/EditorGroup";

const Container = styled.div``;

const Editors = props => {
  const { activeComponent, activeVariationId, activeComponentState } = props;
  const { selector, variations } = activeComponent;

  const variation = variations.find(v => v.id == activeVariationId);

  let selectors = [selector];
  if (variation) selectors.push(variation.selector);
  if (activeComponentState) selectors.push(`:${activeComponentState}`);

  return (
    <Container>
      <EditorGroup title="HTML Markup">
        <Markup {...props} key={`markup${activeComponent.id}`} />
      </EditorGroup>

      <EditorGroup title="Selector">
        <CssClasses {...props} key={`cssClasses${activeComponent.id}`} />
      </EditorGroup>
    </Container>
  );
};
export default Editors;
