import React from "react";

import Select from "react-select";

import classnames from "classnames";

import { EditorContainer, EditorTitle, DropdownContainer } from "../styles";

const Editor = ({ name, title, value, onChange, isChanged, hasValue }) => {
  const weights = [
    { label: "100 - Thin", value: 100 },
    { label: "200 - Extra Light", value: 200 },
    { label: "300 - Light", value: 300 },
    { label: "400 - Normal", value: 400 },
    { label: "500 - Medium", value: 500 },
    { label: "600 - Semi Bold", value: 600 },
    { label: "700 - Bold", value: 700 },
    { label: "800 - Extra Bold", value: 800 },
    { label: "900 - Black", value: 900 }
  ];

  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>
      <DropdownContainer>
        <Select
          defaultValue={value ? weights.find(w => w.value == value) : null}
          isClearable={true}
          isSearchable={true}
          name={name}
          onChange={data => onChange(name, data ? data.value : null)}
          options={weights}
          classNamePrefix="react-select"
        />
      </DropdownContainer>
    </EditorContainer>
  );
};
export default Editor;
