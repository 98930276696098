import React, { useState } from "react";
import styled from "styled-components";

import Devices from "./Devices";
import Variations from "./Variations";
import Pseudo from "./Pseudo";

const Container = styled.div`
  padding: 20px;
  text-align: left;
  cursor: default;
  display: flex;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 30px;

  .react-select__control {
    color: hsla(0, 0%, 100%, 0.5);
    background-color: rgb(43, 43, 43);
    border: 1px solid #666;
    font-size: 16px;

    &:hover {
      border: 1px solid #999;
    }
  }

  .react-select__menu {
    z-index: 200;
  }

  .react-select__option {
    color: #212529;

    &.react-select__option--is-selected {
      color: #fff;
    }
  }

  .react-select__single-value {
    color: hsla(0, 0%, 100%, 0.5);
  }

  .react-select__multi-value {
    color: hsla(0, 0%, 100%, 0.5);
    background-color: rgb(59, 121, 195);
    margin: 5px 3px;
  }

  .react-select__value-container {
    padding: 2px 5px;
  }
`;

const SelectorBox = styled.div`
  flex: 1;
  margin-right: 15px;

  &:last-child {
    margin-right: 0px;
  }

  label {
    padding: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    color: ${props => (props.changed ? "rgb(139,190,250)" : "#999")};
    background-color: ${props =>
      props.changed ? "rgba(76,152,241,0.15)" : "transparent"};
  }

  .react-select__control {
    border: 1px solid ${props => (props.changed ? "rgb(139,190,250)" : "#666")} !important;
  }

  .react-select__single-value {
    color: ${props =>
      props.changed ? "rgb(139,190,250)" : "hsla(0, 0%, 100%, 0.5)"} !important;
  }
`;

const Component = props => {
  const { activeDevice, activeVariationId, activeComponentState } = props;

  return (
    <Container>
      <SelectorBox changed={activeDevice != "desktop"}>
        <label>Device</label>
        <Devices {...props} />
      </SelectorBox>

      <SelectorBox changed={!!activeVariationId}>
        <label>Variation</label>
        <Variations {...props} />
      </SelectorBox>

      <SelectorBox changed={!!activeComponentState}>
        <label>Pseudo Class</label>
        <Pseudo {...props} />
      </SelectorBox>
    </Container>
  );
};
export default Component;
