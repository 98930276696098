import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import Page from "./Page";
import NewPage from "./NewPage";
import Pages from "./Pages";

const Documentation = props => {
  let { path, url } = useRouteMatch();

  const childProps = {
    ...props
  };

  return (
    <Switch>
      <Route exact path={`${path}/new`}>
        <NewPage {...childProps} />
      </Route>
      <Route exact path={`${path}/:pageId`}>
        <Page {...childProps} />
      </Route>
      <Route exact path={path}>
        <Pages {...childProps} />
      </Route>
    </Switch>
  );
};

export default Documentation;
