import { gql } from "@apollo/client";

export const PROJECTS_QUERY = gql`
  query getProjects {
    projects {
      id
      name
      rootComponents {
        id
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($name: String!) {
    createProject(input: { name: $name }) {
      success
      error
      project {
        id
        name
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: Int!) {
    deleteProject(input: { id: $id }) {
      success
      error
    }
  }
`;

export const INVITATIONS_QUERY = gql`
  query getInvitations($filters: FiltersInput) {
    invitations(filters: $filters) {
      id
      project {
        id
        name
      }
      invitor {
        id
        name
        email
      }
    }
  }
`;

export const ACCEPT_INVITATION = gql`
  mutation AcceptInvitationToProject($id: Int!) {
    acceptInvitationToProject(input: { id: $id }) {
      success
      error
    }
  }
`;

export const DECLINE_INVITATION = gql`
  mutation DeclineInvitationToProject($id: Int!) {
    declineInvitationToProject(input: { id: $id }) {
      success
      error
    }
  }
`;
