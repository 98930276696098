import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";

import { useMutation } from "@apollo/client";

import { DELETE_PROJECT } from "./queries";

const Project = ({ project, setActiveProjectId, history }) => {
  let { projectId } = useParams();

  const [deleteProject] = useMutation(DELETE_PROJECT);

  useEffect(() => {
    localStorage.setItem("monad:projectId", projectId);
    setActiveProjectId(projectId);
  }, [projectId]);

  if (!project) return null;

  return (
    <Redirect
      to={{
        pathname: "/components"
      }}
    />
  );

  return (
    <div>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Projects /</h1>
            <h1 className="breadcrumb active">{project.name}</h1>
          </div>

          <div className="actions">
            <a
              className="action action-danger"
              onClick={async () => {
                if (window.confirm("Are you sure?")) {
                  await deleteProject({
                    variables: {
                      id: project.id
                    }
                  });
                  localStorage.removeItem("monad:projectId");
                  setActiveProjectId(null);
                  history.push("/projects");
                }
              }}
            >
              Delete Project
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
