import React from "react";

import classnames from "classnames";
import styled from "styled-components";

const Container = styled.div`
  max-width: 100px;

  .progress {
    display: flex;
    padding-left: 0px;
    padding-right: 0px;
    margin-bottom: 50px;

    li {
      list-style: none;
      flex: 1;
      display: block;
      position: relative;
      text-align: center;

      &.active {
        .bubble {
          background-color: #007bff;
          border: 1px solid #007bff;
        }
      }
    }
  }

  .bubble {
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 1000px;
    border: 1px solid rgb(221, 221, 221);
    background-color: rgb(221, 221, 221);

    &.enabled {
      cursor: pointer;
    }
  }
`;

const Progress = props => {
  const {
    steps,
    currentStep,
    setCurrentStep,
    project: { onboardingSteps }
  } = props;

  return (
    <Container>
      <ul className="progress">
        {steps.map(step => {
          return (
            <li
              key={`step${step}`}
              className={classnames({
                completed: onboardingSteps.indexOf(steps) > -1,
                active: currentStep == step
              })}
            >
              <span
                className={classnames({
                  bubble: true,
                  enabled:
                    onboardingSteps.indexOf(step) > -1 ||
                    onboardingSteps.indexOf(currentStep) > -1
                })}
                onClick={() => {
                  if (
                    onboardingSteps.indexOf(step) > -1 ||
                    onboardingSteps.indexOf(currentStep) > -1
                  ) {
                    setCurrentStep(step);
                  }
                }}
              ></span>
            </li>
          );
        })}
      </ul>
    </Container>
  );
};

export default Progress;
