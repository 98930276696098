import React, { useRef, useState } from "react";

import { useDrop, useDrag } from "react-dnd";

import { isVoidElement } from "../../../common/utils";

import classNames from "classnames";

const Layer = ({
  activeComponentId,
  setActiveComponentId,
  component,
  nested,
  isRoot,
  isDirectParent,
  isNestedParent,
  refetchChildComponents,
  getMaxPosition,
  repositionComponent,
  setCreateOptions,
  setCreateModalIsOpen,
  updateComponent,
  setDropComponentId,
  setHoverComponentId,
  symbolId,
  children
}) => {
  const containerRef = useRef(null);
  const aboveRef = useRef(null);
  const belowRef = useRef(null);
  const layerRef = useRef(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "layer", componentId: component.id },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end: (item, monitor) => {
      setDropComponentId(null);
    }
  });

  const [{ isOverContainer }, dropContainer] = useDrop({
    accept: ["layer"],
    drop: async (item, monitor) => {},
    collect: monitor => ({
      isOverContainer: monitor.isOver()
    })
  });

  const [{ isOverAbove }, dropAbove] = useDrop({
    accept: ["layer"],
    drop: async (item, monitor) => {
      const sourceComponentId = item.componentId;
      const targetComponentId = component.id;

      const response = await repositionComponent(
        sourceComponentId,
        "before",
        targetComponentId
      );
    },
    collect: monitor => ({
      isOverAbove: monitor.isOver({ shallow: true })
    })
  });

  const [{ isOverBelow }, dropBelow] = useDrop({
    accept: ["layer"],
    drop: async (item, monitor) => {
      const sourceComponentId = item.componentId;
      const targetComponentId = component.id;

      const response = await repositionComponent(
        sourceComponentId,
        "after",
        targetComponentId
      );
    },
    collect: monitor => ({
      isOverBelow: monitor.isOver({ shallow: true })
    })
  });

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ["box", "layer"],
    drop: async (item, monitor) => {
      const sourceComponentId = item.componentId;
      const targetComponentId = component.id;

      // A child cannot be dropped into the same parent
      // and a parent cannot be dropped into a nested child
      if (
        item.type == "layer" &&
        (sourceComponentId == targetComponentId ||
          isNestedParent(sourceComponentId, targetComponentId) ||
          isDirectParent(targetComponentId, sourceComponentId))
      ) {
        return;
      }

      if (!monitor.didDrop()) {
        if (item.type == "layer") {
          await updateComponent({
            variables: {
              id: sourceComponentId,
              parentComponentId: targetComponentId,
              position: getMaxPosition(targetComponentId)
            }
          });
          refetchChildComponents();
        } else {
          setCreateOptions({
            parentComponentId: component.id,
            htmlTag: item.componentId ? "div" : item.htmlTag || "div",
            htmlTagAttributes: item.htmlTagAttributes || {},
            position: getMaxPosition(targetComponentId),
            externalComponentId: item.componentId
              ? parseInt(item.componentId)
              : null
          });
          setCreateModalIsOpen(true);
        }
      }
    },
    collect: monitor => ({
      isOver: monitor.isOver({ shallow: true }),
      canDrop:
        monitor.canDrop() &&
        monitor.getItem().componentId != component.id &&
        !isVoidElement(component.htmlTag) &&
        !isNestedParent(monitor.getItem().componentId, component.id)
    })
  });

  const isHovering = canDrop && isOver;

  if (canDrop) {
    drop(layerRef);
  }

  if (!isRoot) {
    dropAbove(aboveRef);
    dropBelow(belowRef);
  }
  dropContainer(containerRef);

  if (!isRoot) drag(layerRef);

  const inActiveSymbol = !!symbolId;
  const isExternal = component.external;
  const canEdit = !inActiveSymbol || (inActiveSymbol && !isExternal); // We cannot allow editing external components and symbols if we are already inside a symbol

  return (
    <div
      ref={containerRef}
      className="layer-container"
      onClick={e => {
        e.stopPropagation();
        if (canEdit) {
          if (activeComponentId == component.id) {
            setActiveComponentId(null);
          } else {
            setActiveComponentId(component.id);
          }
        }
      }}
    >
      {!isDragging && !isRoot && (
        <div
          ref={aboveRef}
          className={classNames({
            "layer-position-bar": true,
            active: isOverAbove,
            expand: isOverContainer
          })}
          style={{ marginLeft: nested * 20 }}
        ></div>
      )}

      <div
        ref={layerRef}
        style={{ marginLeft: nested * 20 }}
        className={classNames({
          layer: true,
          active: activeComponentId == component.id,
          dragging: isDragging,
          "is-over": canDrop && isHovering,
          "is-over-container": canDrop && (isOverAbove || isOverBelow)
        })}
        onMouseOver={e => {
          setHoverComponentId(component.id);
        }}
        onMouseOut={e => {
          setHoverComponentId(null);
        }}
      >
        {component.title}
      </div>

      {!isDragging && !isRoot && (
        <div
          ref={belowRef}
          className={classNames({
            "layer-position-bar": true,
            active: isOverBelow,
            expand: isOverContainer
          })}
          style={{ marginLeft: nested * 20 }}
        ></div>
      )}

      {children}
    </div>
  );
};

export default Layer;
