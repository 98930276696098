import React, { useState } from "react";

import Modal from "../../common/Modal";
import { Formik } from "formik";

import styled from "styled-components";

const Container = styled.div`
  padding: 10px 20px;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.not-connected {
    color: #856404;
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
  }

  &.connected {
    color: #155724;
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
  }

  div {
    flex: 1;
  }

  .icon {
    font-size: 32px;
    max-width: 70px;
  }

  .description {
  }

  .action {
    text-align: right;
  }
`;

const Storage = props => {
  const {
    projectId,
    updateProject,
    refetchProject,
    project: { awsConfig, cdnUrl }
  } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const {
    accessKeyId,
    bucket,
    pathPrefix,
    region,
    secretAccessKey
  } = awsConfig;

  return (
    <>
      <Container className={accessKeyId ? "connected" : "not-connected"}>
        <div className="icon">
          <i
            className={
              accessKeyId ? "fas fa-check-circle" : "fas fa-exclamation-circle"
            }
          ></i>
        </div>

        <div className="description">
          {accessKeyId ? (
            <div>
              <strong>Storage Connected</strong> &mdash; {bucket}
            </div>
          ) : (
            <div>
              <strong>No Storage Connected</strong> &mdash; Connect your Amazon
              S3
            </div>
          )}
        </div>

        <div className="action">
          <a className="btn btn-primary" onClick={() => setModalIsOpen(true)}>
            {accessKeyId ? "Edit" : "Add"} Storage
          </a>
        </div>
      </Container>
      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            accessKeyId,
            secretAccessKey,
            bucket,
            region,
            pathPrefix,
            cdnUrl
          }}
          validate={values => {
            const errors = {};
            if (!values.accessKeyId) {
              errors.accessKeyId = "Required";
            }
            if (!values.secretAccessKey) {
              errors.secretAccessKey = "Required";
            }
            if (!values.bucket) {
              errors.bucket = "Required";
            }
            if (!values.region) {
              errors.region = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await updateProject({
              variables: {
                id: projectId,
                awsConfig: {
                  accessKeyId: values.accessKeyId,
                  secretAccessKey: values.secretAccessKey,
                  bucket: values.bucket,
                  region: values.region,
                  pathPrefix: values.pathPrefix
                },
                cdnUrl: values.cdnUrl
              }
            });

            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">Storage</h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Access Key Id</label>
                    <input
                      className="form-control"
                      type="text"
                      name="accessKeyId"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.accessKeyId}
                    />
                    {errors.accessKeyId && touched.accessKeyId && (
                      <p className="hint error">{errors.accessKeyId}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Secret Access Key</label>
                    <input
                      className="form-control"
                      type="text"
                      name="secretAccessKey"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.secretAccessKey}
                    />
                    {errors.secretAccessKey && touched.secretAccessKey && (
                      <p className="hint error">{errors.secretAccessKey}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Bucket</label>
                    <input
                      className="form-control"
                      type="text"
                      name="bucket"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.bucket}
                    />
                    {errors.bucket && touched.bucket && (
                      <p className="hint error">{errors.bucket}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Region</label>
                    <input
                      className="form-control"
                      type="text"
                      name="region"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.region}
                    />
                    {errors.region && touched.region && (
                      <p className="hint error">{errors.region}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Path Prefix</label>
                    <input
                      className="form-control"
                      type="text"
                      name="pathPrefix"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pathPrefix}
                    />
                    {errors.pathPrefix && touched.pathPrefix && (
                      <p className="hint error">{errors.pathPrefix}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">CDN Url</label>
                    <input
                      className="form-control"
                      type="text"
                      name="cdnUrl"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cdnUrl}
                    />
                    {errors.cdnUrl && touched.cdnUrl && (
                      <p className="hint error">{errors.cdnUrl}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default Storage;
