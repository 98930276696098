import React, { useState } from "react";

import styled from "styled-components";

const Container = styled.div`
  cursor: default;
`;

const Title = styled.div`
  padding: 5px 10px;
  background-color: rgb(43, 43, 43);
  color: #ccc;
  font-size: 12px;
  border-top: 1px solid rgb(33, 33, 33);
  border-bottom: 1px solid rgb(33, 33, 33);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;

  i,
  span {
    flex: 1;
  }

  i {
    max-width: 10px;
    margin-right: 5px;
  }
`;

const ChildContainer = styled.div`
  padding: 5px;
`;

const EditorGroup = ({ title, children }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Container>
      <Title onClick={() => setExpanded(!expanded)}>
        <i
          className={expanded ? "fas fa-caret-down" : "fas fa-caret-right"}
        ></i>
        <span>{title}</span>
      </Title>
      {expanded && <ChildContainer>{children}</ChildContainer>}
    </Container>
  );
};

export default EditorGroup;
