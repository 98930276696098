// import React, { useState } from "react";

// import Modal from "../../common/Modal";

// import Utility from "./Utility";

// import { Formik } from "formik";

// import { useQuery, useMutation } from "@apollo/client";

// import {
//   GET_UTILITIES,
//   CREATE_UTILITY,
//   UPDATE_UTILITY,
//   DELETE_UTILITY
// } from "./queries";

// const Utilities = ({ projectId }) => {
//   const [modalIsOpen, setModalIsOpen] = useState(false);
//   const [editUtility, setEditUtility] = useState({});
//   const [createUtility] = useMutation(CREATE_UTILITY);
//   const [updateUtility] = useMutation(UPDATE_UTILITY);
//   const [deleteUtility] = useMutation(DELETE_UTILITY);

//   const { loading, error, data, refetch } = useQuery(GET_UTILITIES, {
//     fetchPolicy: "network-only",
//     variables: {
//       projectId: parseInt(projectId)
//     }
//   });

//   if (loading) return <div>Loading</div>;
//   if (error) return <div>Error</div>;

//   const onEditUtility = utility => {
//     setEditUtility(utility);
//     setModalIsOpen(true);
//   };

//   const onDeleteUtility = async utilityId => {
//     if (window.confirm("Are you sure?")) {
//       await deleteUtility({
//         variables: {
//           id: utilityId
//         }
//       });
//       refetch();
//     }
//   };

//   return (
//     <div>
//       <div>
//         <a
//           onClick={() => {
//             setEditUtility({});
//             setModalIsOpen(true);
//           }}
//         >
//           New Utility
//         </a>
//       </div>

//       {data &&
//         data.utilities.map(u => {
//           return (
//             <Utility
//               key={`utility${u.id}`}
//               id={u.id}
//               name={u.name}
//               selector={u.selector}
//               properties={u.properties}
//               onEditUtility={onEditUtility}
//               onDeleteUtility={onDeleteUtility}
//             />
//           );
//         })}

//       <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
//         <Formik
//           initialValues={{
//             name: "",
//             selector: "",
//             properties: {},
//             ...editUtility
//           }}
//           validate={values => {
//             const errors = {};
//             if (!values.selector) {
//               errors.selector = "Required";
//             }
//             return errors;
//           }}
//           onSubmit={async (values, { setSubmitting }) => {
//             if (editUtility && editUtility.id) {
//               await updateUtility({
//                 variables: {
//                   id: parseInt(editUtility.id),
//                   ...values
//                 }
//               });
//             } else {
//               await createUtility({
//                 variables: {
//                   projectId,
//                   ...values
//                 }
//               });
//             }

//             refetch();
//             setSubmitting(false);
//             setModalIsOpen(false);
//           }}
//         >
//           {({
//             values,
//             errors,
//             touched,
//             handleChange,
//             handleBlur,
//             handleSubmit,
//             isSubmitting
//           }) => (
//             <form onSubmit={handleSubmit}>
//               <div>
//                 <label>Selector</label>
//                 <input
//                   type="text"
//                   name="selector"
//                   onChange={handleChange}
//                   onBlur={handleBlur}
//                   value={values.selector}
//                 />
//                 {errors.selector && touched.selector && errors.selector}
//               </div>

//               <button type="submit" disabled={isSubmitting}>
//                 Submit
//               </button>
//             </form>
//           )}
//         </Formik>
//       </Modal>
//     </div>
//   );
// };

// export default Utilities;

import React from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";

import Utilities from "./Utilities";
import Utility from "./Utility";
import NewUtility from "./NewUtility";

const UtilitiesContainer = props => {
  let { path, url } = useRouteMatch();

  const childProps = {
    ...props
  };

  return (
    <div>
      <Switch>
        <Route exact path={`${path}/new`}>
          <NewUtility {...childProps} />
        </Route>
        <Route exact path={`${path}/:utilityId`}>
          <Utility {...childProps} />
        </Route>
        <Route exact path={path}>
          <Utilities {...childProps} />
        </Route>
      </Switch>
    </div>
  );
};

export default UtilitiesContainer;
