import React, { useState, useEffect } from "react";

import { useMutation, useQuery } from "@apollo/client";

import {
  DEFAULT_FONTS,
  ASSIGN_FONT_TO_VARIABLE,
  CREATE_OR_UPDATE_VARIABLE,
} from "./queries";

import styled from "styled-components";

const Container = styled.div`
  .font-info {
    margin-top: 40px;
    text-transform: uppercase;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    padding-left: 5px;
    margin-bottom: 3px;
    color: #999;
  }

  .preview-text {
    font-size: 28px;
    text-align: center;
    padding: 30px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 40px;
  }

  .form {
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 3px;
      color: #999;
    }
  }
`;

const Step = (props) => {
  const {
    projectId,
    currentStep,
    goToNextStep,
    refetchProject,
    project: { variables, fonts },
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [defaultFonts, setDefaultFonts] = useState([]);
  const [selectedFont, setSelectedFont] = useState({});
  const [selectedFontWeight, setSelectedFontWeight] = useState(400);
  const [selectedFontSize, setSelectedFontSize] = useState(16);
  const [assignFontToVariable] = useMutation(ASSIGN_FONT_TO_VARIABLE);
  const [createOrUpdateVariable] = useMutation(CREATE_OR_UPDATE_VARIABLE);
  const { loading, error, data } = useQuery(DEFAULT_FONTS, {
    variables: {},
  });

  useEffect(() => {
    if (data && data.defaultFonts) {
      setDefaultFonts(data.defaultFonts);
    }
  }, [data]);

  const findVariable = (varName) => {
    return variables.find((v) => v.name == varName);
  };

  const findFont = (fontId) => {
    return fonts.find((f) => f.id == fontId);
  };

  useEffect(() => {
    if (!selectedFont.name && defaultFonts.length > 0) {
      let newFont = defaultFonts.find((f) => f.name == "Inter");
      const fontFamilyBaseVariable = findVariable("fontFamilyBase");
      if (fontFamilyBaseVariable) {
        let baseFont = findFont(fontFamilyBaseVariable.fontId);
        if (baseFont) newFont = baseFont;
      }
      setSelectedFont(newFont);
    }

    const fontSizeBaseVariable = variables.find(
      (v) => v.name == "fontSizeBase"
    );
    if (fontSizeBaseVariable) {
      setSelectedFontSize(parseInt(fontSizeBaseVariable.value));
    }

    const fontWeightBaseVariable = variables.find(
      (v) => v.name == "fontWeightBase"
    );
    if (fontWeightBaseVariable) {
      setSelectedFontWeight(parseInt(fontWeightBaseVariable.value));
    }
  }, [variables, defaultFonts]);

  return (
    <>
      <Container>
        {selectedFont.name && (
          <div className="font-info">
            {selectedFont.name} - {selectedFontSize}px
          </div>
        )}
        {selectedFont.url && <link href={selectedFont.url} rel="stylesheet" />}
        <div
          className="preview-text"
          style={{
            fontFamily: selectedFont.value,
            fontSize: selectedFontSize,
            fontWeight: selectedFontWeight,
          }}
        >
          <div>The quick brown fox jumps over the lazy dog</div>
        </div>

        <div className="form">
          <div className="form-group">
            <label className="label">Font Family</label>

            <select
              className="form-select"
              value={selectedFont.name}
              onChange={async (e) => {
                const selectedFont = defaultFonts.find(
                  (f) => f.name == e.target.value
                );

                setSelectedFont(selectedFont);
              }}
            >
              {defaultFonts.map((f) => {
                return (
                  <option key={`font${f.name}`} value={f.name}>
                    {f.name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="form-group">
            <label className="label">Font Size</label>
            <select
              className="form-select"
              value={selectedFontSize}
              onChange={async (e) => {
                setSelectedFontSize(parseInt(e.target.value));
              }}
            >
              <option value="10">10px</option>
              <option value="12">12px</option>
              <option value="14">14px</option>
              <option value="16">16px</option>
              <option value="18">18px</option>
              <option value="20">20px</option>
              <option value="22">22px</option>
              <option value="24">24px</option>
            </select>
          </div>

          <div className="form-group">
            <label className="label">Font Weight</label>
            <select
              className="form-select"
              value={selectedFontWeight}
              onChange={async (e) => {
                setSelectedFontWeight(e.target.value);
              }}
            >
              <option value="300">Light</option>
              <option value="400">Regular</option>
              <option value="700">Bold</option>
            </select>
          </div>
        </div>
      </Container>
      <div className="onboarding-action">
        <a
          className={
            submitting ? "btn btn-dark btn-lg" : "btn btn-primary btn-lg"
          }
          onClick={async () => {
            if (submitting) return false;
            setSubmitting(true);

            await assignFontToVariable({
              variables: {
                projectId: parseInt(projectId),
                name: selectedFont.name,
                value: selectedFont.value,
                url: selectedFont.url,
                variableName: "fontFamilyBase",
              },
            });

            await createOrUpdateVariable({
              variables: {
                projectId: parseInt(projectId),
                name: "fontSizeBase",
                value: `${selectedFontSize}px`,
              },
            });

            await createOrUpdateVariable({
              variables: {
                projectId: parseInt(projectId),
                name: "fontWeightBase",
                value: `${selectedFontWeight}`,
              },
            });

            await refetchProject();

            goToNextStep(currentStep);
          }}
        >
          {submitting ? <i className="fas fa-spinner fa-spin"></i> : "Continue"}
        </a>
      </div>
    </>
  );
};

export default Step;
