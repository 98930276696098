import React, { useEffect } from "react";

const LayerOutline = ({
  layerRef,
  setSymbolId,
  setSymbolShadowId,
  setActiveComponentId,
  setActiveVariationId
}) => {
  const domComponent = layerRef ? layerRef.current : null;

  if (!domComponent) return null;

  const rect = domComponent.getBoundingClientRect();

  const resetEditor = () => {
    setSymbolId(null);
    setSymbolShadowId(null);
    setActiveComponentId(null);
    setActiveVariationId(null);
  };

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          height: rect.top,
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(255,255,255,0.7)"
        }}
        onClick={() => resetEditor()}
      ></div>

      <div
        style={{
          position: "absolute",
          top: rect.top + rect.height,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(255,255,255,0.7)"
        }}
        onClick={() => resetEditor()}
      ></div>

      <div
        style={{
          position: "absolute",
          top: rect.top,
          left: 0,
          height: rect.height,
          width: rect.left,
          backgroundColor: "rgba(255,255,255,0.7)"
        }}
        onClick={() => resetEditor()}
      ></div>

      <div
        style={{
          position: "absolute",
          top: rect.top,
          left: rect.right,
          right: 0,
          height: rect.height,
          backgroundColor: "rgba(255,255,255,0.7)"
        }}
        onClick={() => resetEditor()}
      ></div>
    </React.Fragment>
  );
};

export default LayerOutline;
