import React, { useState } from "react";
import styled from "styled-components";

import EditorGroup from "./EditorGroup";
import Editor from "./Editor";
import MultiEditor from "./MultiEditor";
import CustomProperty from "./CustomProperty";

import { Row, Col } from "./styles";

import availableCssEditors from "./availableCssEditors";

const Container = styled.div``;

const Editors = ({ editorKey, style, onChange, project }) => {
  const editorProps = {
    type: "text",
    editorKey,
    style,
    onChange,
    project,
  };

  const { active, inherited } = style;

  const values = {
    ...inherited,
    ...active,
  };

  const isChanged = (name) => !!active[name];

  const isDisplayFlex =
    values["display"] == "flex" || values["display"] == "inline-flex";

  const activeStyleKeys = Object.keys(active);
  const unsupportedKeys = activeStyleKeys.filter(
    (k) => availableCssEditors.indexOf(k) < 0
  );

  return (
    <Container>
      <EditorGroup title="Layout">
        <Editor {...editorProps} name="display" type="display" />
        {isDisplayFlex && (
          <>
            <Editor {...editorProps} name="flex-direction" title="Direction" />
            <Editor {...editorProps} name="flex-wrap" title="Wrap" />
            <Editor {...editorProps} name="justify-content" title="Justify" />
            <Editor {...editorProps} name="align-items" title="Align" />
          </>
        )}
      </EditorGroup>

      <EditorGroup title="Spacing">
        <MultiEditor {...editorProps} type="spacing" />
      </EditorGroup>

      <EditorGroup title="Size">
        <Row>
          <Col>
            <Editor {...editorProps} name="width" type="size" />
            <Editor
              {...editorProps}
              name="min-width"
              title="Min W"
              type="size"
            />
            <Editor
              {...editorProps}
              name="max-width"
              title="Max W"
              type="size"
            />
          </Col>

          <Col>
            <Editor {...editorProps} name="height" type="size" />
            <Editor
              {...editorProps}
              name="min-height"
              title="Min H"
              type="size"
            />
            <Editor
              {...editorProps}
              name="max-height"
              title="Max H"
              type="size"
            />
          </Col>
        </Row>

        <Editor {...editorProps} name="overflow" type="overflow" />
      </EditorGroup>

      <EditorGroup title="Position">
        <Editor {...editorProps} name="position" type="position" />
        <Row>
          <Col>
            <Editor {...editorProps} name="top" type="size" />
            <Editor {...editorProps} name="bottom" type="size" />
          </Col>
          <Col>
            <Editor {...editorProps} name="right" type="size" />
            <Editor {...editorProps} name="left" type="size" />
          </Col>
        </Row>
      </EditorGroup>

      <EditorGroup title="Typography">
        <Editor
          {...editorProps}
          name="font-family"
          title="Font"
          type="font_family"
        />

        <Editor
          {...editorProps}
          name="font-weight"
          title="Weight"
          type="font_weight"
        />

        <Row>
          <Col>
            <Editor
              {...editorProps}
              name="font-size"
              title="Size"
              type="size"
            />
          </Col>

          <Col>
            <Editor
              {...editorProps}
              name="line-height"
              title="Height"
              type="size"
            />
          </Col>
        </Row>

        <Editor {...editorProps} name="color" type="color" />

        <Editor {...editorProps} name="text-align" title="Align" type="align" />
        <Editor
          {...editorProps}
          name="text-decoration"
          title="Line"
          type="decoration"
        />

        <Row>
          <Col>
            <Editor
              {...editorProps}
              name="letter-spacing"
              title="Letter Sp"
              type="size"
            />
          </Col>

          <Col>
            <Editor
              {...editorProps}
              name="text-indent"
              title="Indent"
              type="size"
            />
          </Col>
        </Row>

        <Editor
          {...editorProps}
          name="text-transform"
          title="Capitalize"
          type="text_transform"
        />
      </EditorGroup>

      <EditorGroup title="Backgrounds">
        <Editor
          {...editorProps}
          name="background-color"
          title="Color"
          type="color"
        />

        {false && (
          <React.Fragment>
            <Editor {...editorProps} name="background" />
            <Editor {...editorProps} name="background-position" />
            <Editor {...editorProps} name="background-size" />
            <Editor {...editorProps} name="background-repeat" />
            <Editor {...editorProps} name="background-image" />
          </React.Fragment>
        )}
      </EditorGroup>

      <EditorGroup title="Borders">
        <MultiEditor {...editorProps} type="border_radius" />

        <MultiEditor {...editorProps} type="border" />
      </EditorGroup>

      <EditorGroup title="Effects">
        <Editor {...editorProps} name="opacity" />
        <Editor {...editorProps} name="cursor" type="cursor" />
      </EditorGroup>

      <EditorGroup title="Other Properties">
        {unsupportedKeys.map((k) => {
          return <Editor key={`otherprop${k}`} {...editorProps} name={k} />;
        })}

        <CustomProperty {...editorProps} />
      </EditorGroup>
    </Container>
  );
};
export default Editors;
