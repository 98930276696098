import React from "react";

import classnames from "classnames";

import EditorGroup from "../../../../common/Editors/EditorGroup";
import CssClasses from "./CssClasses";

import { useQuery, useMutation } from "@apollo/client";

import { COMPONENT_QUERY } from "../../../queries";

import styled from "styled-components";

const SymbolState = styled.div`
  text-align: center;
  background-color: #2b2b2b;
  color: #ccc;
  padding: 25px;

  i {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .notice {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Variations = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  a {
    flex: 1;
    margin: 5px;
  }
`;

const ExternalEditor = props => {
  const { activeComponent, updateComponent } = props;
  const { loading, error, data, refetch } = useQuery(COMPONENT_QUERY, {
    variables: {
      id: parseInt(activeComponent.externalComponentId)
    }
  });

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const component = data.component;
  const variations = component.variations;

  const setVariation = async variationId => {
    await updateComponent({
      variables: {
        id: activeComponent.id,
        externalVariationId: variationId
      }
    });
  };

  return (
    <div>
      <div style={{ padding: "15px 25px" }}>
        <SymbolState>
          <i className="fas fa-external-link-alt"></i>

          <div className="notice">This is an External Component</div>
        </SymbolState>
      </div>

      {variations.length > 0 && (
        <EditorGroup title="Choose Variations">
          {/* instead of externalVariationId, we are trying to use customCssClasses now */}
          {false && (
            <Variations>
              <a
                className={classnames({
                  btn: true,
                  "btn-dark": !!activeComponent.externalVariationId,
                  "btn-primary": !activeComponent.externalVariationId
                })}
                onClick={() => setVariation(null)}
              >
                {component.selector}
              </a>
              {variations.map(v => {
                return (
                  <a
                    className={classnames({
                      btn: true,
                      "btn-dark": activeComponent.externalVariationId != v.id,
                      "btn-primary": activeComponent.externalVariationId == v.id
                    })}
                    key={`externalvariation${v.id}`}
                    onClick={() => setVariation(v.id)}
                  >
                    {v.selector}
                  </a>
                );
              })}
            </Variations>
          )}

          <CssClasses {...props} variations={variations} />
        </EditorGroup>
      )}
    </div>
  );
};

export default ExternalEditor;
