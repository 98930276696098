import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ApolloProvider } from "@apollo/client";
import client from "./client";

import SignUp from "./components/Auth/SignUp";
import SignIn from "./components/Auth/SignIn";
import SignOut from "./components/Auth/SignOut";
import LoggedIn from "./containers/LoggedIn";

const App = () => {
  return (
    <ApolloProvider client={client}>
      <Router>
        <div>
          <Switch>
            <Route path="/login">
              <SignIn />
            </Route>
            <Route path="/signup">
              <SignUp />
            </Route>
            <Route path="/logout">
              <SignOut />
            </Route>

            <LoggedIn />
          </Switch>
        </div>
      </Router>
    </ApolloProvider>
  );
};

export default App;
