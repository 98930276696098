import React from "react";

import styled from "styled-components";

const Container = styled.div``;

const Step = props => {
  return <Container>Typography</Container>;
};

export default Step;
