import React, { useState } from "react";

import styled from "styled-components";

const Container = styled.div``;

const Title = styled.div`
  padding: 5px 10px;
  background-color: rgb(43, 43, 43);
  color: #ccc;
  font-size: 12px;
  border-top: 1px solid rgb(33, 33, 33);
  border-bottom: 1px solid rgb(33, 33, 33);
  text-transform: uppercase;
`;

const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 15px;

  ::after {
    content: "";
    flex: auto;
    min-width: 31%;
    max-width: 31%;
  }
`;

const TagGroup = ({ title, children }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <TagsContainer>{children}</TagsContainer>
    </Container>
  );
};

export default TagGroup;
