import React, { useState, useRef, useEffect } from "react";

import Loader from "../../components/common/Loader";
import Modal from "../common/Modal";
import Version from "./Version";

import { Formik } from "formik";
import { useQuery, useMutation } from "@apollo/client";

import { GET_BUILDS, CREATE_BUILD } from "./queries";

import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";
import CodeMirror from "codemirror/lib/codemirror";

import styled from "styled-components";

const VersionsContainer = styled.div`
  padding: 20px 0px;
`;

const LatestContainer = styled.div`
  margin: 20px 0px 0px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;

  .number {
    font-size: 14px;
    margin-left: 4px;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 25px;
    background-color: rgba(0, 123, 255, 1);
    color: #fff;
  }

  .header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .CodeMirror {
    border: 1px solid #ccc;
    height: auto;

    .CodeMirror-sizer {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
`;

const BlankState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-radius: 4px;

  img {
    max-width: 300px;
  }

  .header {
    font-size: 20px;
    margin: 20px 0px 15px;
    font-weight: bold;
  }

  .desc {
    font-size: 16px;
    margin-bottom: 20px;
  }
`;

let cssCm;
let jsCm;

const Versions = ({ projectId, project }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [createBuild] = useMutation(CREATE_BUILD);

  const cssFileTextarea = useRef(null);
  const jsFileTextArea = useRef(null);

  const { loading, error, data, refetch } = useQuery(GET_BUILDS, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  useEffect(() => {
    if (cssCm) {
      cssCm.toTextArea();
    }

    if (jsCm) {
      jsCm.toTextArea();
    }

    if (cssFileTextarea && cssFileTextarea.current) {
      cssCm = CodeMirror.fromTextArea(cssFileTextarea.current, {
        mode: { name: "htmlmixed" },
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true
      });
    }

    if (jsFileTextArea && jsFileTextArea.current) {
      jsCm = CodeMirror.fromTextArea(jsFileTextArea.current, {
        mode: { name: "htmlmixed" },
        lineNumbers: true,
        lineWrapping: true,
        readOnly: true
      });
    }
  }, [project.id, data]);

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb active">Versions</h1>
          </div>

          <div className="actions">
            <a className="action" onClick={() => setModalIsOpen(true)}>
              New Version
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <LatestContainer>
          <form className="form">
            <div className="header">
              CSS <span className="number">Latest</span>
            </div>

            <div className="form-group">
              <p>
                {
                  "Copy-paste the stylesheet <link> into your <head> before all other stylesheets to load our CSS."
                }
              </p>

              <textarea
                ref={cssFileTextarea}
                defaultValue={`<link rel="stylesheet" type="text/css" href="${project.fullStylesUrl}" crossorigin="anonymous" />`}
              ></textarea>
            </div>

            <div className="header">
              JS <span className="number">Latest</span>
            </div>

            <div className="form-group">
              <p>
                {
                  "Place the following <script> near the end of your pages, right before the closing </body> tag."
                }
              </p>

              <textarea
                ref={jsFileTextArea}
                defaultValue={`<script src="${project.fullJavascriptUrl}" crossorigin="anonymous"></script>`}
              ></textarea>
            </div>
          </form>
        </LatestContainer>

        <VersionsContainer>
          {data && data.builds.length == 0 && (
            <BlankState>
              <div className="header">No Versions Published</div>
              <p className="desc">
                Create a new version to publish the changes to your components.
              </p>
              <div className="action">
                <a
                  className="btn btn-primary"
                  onClick={() => setModalIsOpen(true)}
                >
                  New Version
                </a>
              </div>
            </BlankState>
          )}

          {data &&
            data.builds.map((v, i) => {
              return (
                <Version key={`version${v.id}`} version={v} project={project} />
              );
            })}
        </VersionsContainer>

        <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
          <Formik
            initialValues={{
              comment: ""
            }}
            validate={values => {
              const errors = {};
              if (!values.comment) {
                errors.comment = "Required";
              }
              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await createBuild({
                variables: {
                  projectId,
                  comment: values.comment
                }
              });

              refetch();
              setSubmitting(false);
              setModalIsOpen(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form onSubmit={handleSubmit} className="form">
                <div className="modal-top">
                  <div className="modal-title">
                    <h3 className="headline">New Version</h3>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label className="label">Comment</label>
                      <input
                        className="form-control"
                        type="text"
                        name="comment"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.comment}
                      />
                      {!(errors.comment && touched.comment) && (
                        <p className="hint">
                          This is for future reference. Mention the changes you
                          made in this version.
                        </p>
                      )}
                      {errors.comment && touched.comment && (
                        <p className="hint error">{errors.comment}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button className="btn" onClick={() => setModalIsOpen(false)}>
                    Cancel
                  </button>{" "}
                  <button
                    className={
                      isSubmitting ? "btn btn-dark" : "btn btn-primary"
                    }
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Publishing" : "Publish Version"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </Modal>
      </div>
    </>
  );
};

export default Versions;
