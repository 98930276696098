import React, { useState } from "react";
import styled from "styled-components";

import { Formik } from "formik";
import Select from "react-select";

import Modal from "../../../common/Modal";

import classNames from "classnames";

const VariationsContainer = styled.div``;

const Component = ({
  variations,
  rootComponent,
  symbolComponent,
  activeVariationId,
  setActiveVariationId,
  createVariation,
  deleteVariation
}) => {
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const defaultSelector = (symbolComponent || rootComponent).selector;

  const options = [
    ...variations.map(v => ({ label: v.selector, value: v.id })),
    {
      label: "Add New Variation",
      value: "add-new-variation"
    }
  ];

  const groupedOptions = [
    {
      label: "Root",
      options: [{ label: defaultSelector, value: null }]
    },
    {
      label: "Variations",
      options
    }
  ];

  return (
    <VariationsContainer>
      <Select
        defaultValue={
          activeVariationId
            ? options.find(o => o.value == activeVariationId)
            : { label: defaultSelector, value: null }
        }
        isClearable={false}
        isSearchable={true}
        onChange={data => {
          if (data.value == "add-new-variation") {
            setCreateModalIsOpen(true);
          } else {
            setActiveVariationId(data.value);
          }
        }}
        options={groupedOptions}
        classNamePrefix="react-select"
      />

      <Modal
        isOpen={createModalIsOpen}
        closeModal={() => setCreateModalIsOpen(false)}
      >
        <Formik
          initialValues={{ selector: "" }}
          validate={values => {
            const errors = {};
            if (!values.selector) {
              errors.selector = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const response = await createVariation(values.selector);
            setSubmitting(false);
            setCreateModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">New Variation</h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Selector Class</label>
                    <input
                      className="form-control"
                      type="text"
                      name="selector"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.selector}
                    />
                    <p className="hint">
                      This would be your CSS class name. Examples: .btn-primary,
                      .btn-dark
                    </p>
                    {errors.selector && touched.selector && (
                      <p className="hint error">{errors.selector}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  className="btn"
                  onClick={() => setCreateModalIsOpen(false)}
                >
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </VariationsContainer>
  );
};
export default Component;
