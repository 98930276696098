import { gql } from "@apollo/client";

export const GET_BUILDS = gql`
  query getBuilds($projectId: Int!) {
    builds(projectId: $projectId) {
      id
      version
      comment
      fullStylesUrl
      fullJavascriptUrl
    }
  }
`;

export const CREATE_BUILD = gql`
  mutation CreateBuild($projectId: Int!, $comment: String) {
    createBuild(input: { projectId: $projectId, comment: $comment }) {
      success
      build {
        id
        projectId
        comment
      }
    }
  }
`;
