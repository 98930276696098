export default [
  // LAYOUT
  "display",
  "flex-direction",
  "flex-wrap",
  "justify-content",
  "align-items",

  // SPACING
  "padding-top",
  "padding-left",
  "padding-right",
  "padding-bottom",
  "margin-top",
  "margin-left",
  "margin-right",
  "margin-bottom",

  // SIZE
  "width",
  "min-width",
  "max-width",
  "height",
  "min-height",
  "max-height",
  "overflow",

  // POSITION
  "position",
  "top",
  "bottom",
  "left",
  "right",

  // TYPOGRAPHY
  "font-family",
  "font-weight",
  "font-size",
  "line-height",
  "color",
  "text-align",
  "text-decoration",
  "letter-spacing",
  "text-indent",
  "text-transform",

  // BACKGROUND
  "background-color",

  // EFFECTS
  "opacity",
  "cursor",

  // BORDERS
  "border-style",
  "border-width",
  "border-color",
  "border-left-style",
  "border-left-width",
  "border-left-color",
  "border-top-style",
  "border-top-width",
  "border-top-color",
  "border-right-style",
  "border-right-width",
  "border-right-color",
  "border-bottom-style",
  "border-bottom-width",
  "border-bottom-color",
  "border-radius",
  "border-top-left-radius",
  "border-top-right-radius",
  "border-bottom-left-radius",
  "border-bottom-right-radius",
];
