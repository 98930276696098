import React, { useState, useEffect } from "react";

import { useMutation } from "@apollo/client";

import SketchPicker from "react-color/lib/Sketch";
import Trigger from "rc-trigger";

import { ASSIGN_COLOR_TO_VARIABLE, CREATE_OR_UPDATE_VARIABLE } from "./queries";

import styled from "styled-components";

const Container = styled.div`
  .form {
    label {
      text-transform: uppercase;
      font-size: 12px;
      font-weight: bold;
      margin-bottom: 3px;
      color: #999;
    }
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Col = styled.div`
  flex: 1;
`;

const ColorPickerContainer = styled.div`
  display: flex;
  max-width: 250px;
  align-items: center;

  .color-preview {
    flex: 1;
    height: 35px;
    max-width: 35px;
    background-color: ${(props) => props.color};
    cursor: pointer;
    border: 1px solid #ccc;
    border-right: 0px;

    &.transparent {
      background-size: 20px 20px;
      background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
      background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
        linear-gradient(-45deg, #eee 25%, transparent 25%),
        linear-gradient(45deg, transparent 75%, #eee 75%),
        linear-gradient(-45deg, transparent 75%, #eee 75%);
    }
  }

  .color-hex {
    padding-left: 10px;
    flex: 1;
    height: 35px;
    border: 1px solid #ccc;
    outline: none;
    font-family: "Inter", sans-serif;
    font-size: 16px;
  }
`;

const ColorPickerPopover = styled.div`
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;

  .sketch-picker {
    box-shadow: none !important;
  }
`;

const ColorPicker = ({ onChange, value }) => {
  return (
    <ColorPickerContainer>
      <Trigger
        action={["click"]}
        popup={
          <ColorPickerPopover>
            <SketchPicker
              disableAlpha={true}
              color={value || ""}
              presetColors={[]}
              onChange={(color) => {
                onChange(color.hex);
              }}
            />
          </ColorPickerPopover>
        }
        popupStyle={{
          width: 220,
          height: 303,
        }}
        zIndex={120}
        popupPlacement="right"
        maskClosable={true}
        destroyPopupOnHide={true}
        builtinPlacements={{
          left: {
            points: ["tr", "tl"],
          },
          right: {
            points: ["tl", "tr"],
          },
        }}
        popupAlign={{
          offset: [0, 3],
          overflow: { adjustX: 1, adjustY: 1 },
        }}
      >
        <div className="color-preview" style={{ backgroundColor: value }}></div>
      </Trigger>
      <input
        type="text"
        className="color-hex"
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
      />
    </ColorPickerContainer>
  );
};

const Step = (props) => {
  const {
    projectId,
    currentStep,
    goToNextStep,
    refetchProject,
    project: { variables, colors },
  } = props;
  const [submitting, setSubmitting] = useState(false);
  const [bodyColor, setBodyColor] = useState(null);
  const [bodyBg, setBodyBg] = useState(null);
  const [linkColor, setLinkColor] = useState(null);
  const [linkHoverColor, setLinkHoverColor] = useState(null);

  const [assignColorToVariable] = useMutation(ASSIGN_COLOR_TO_VARIABLE);
  const [createOrUpdateVariable] = useMutation(CREATE_OR_UPDATE_VARIABLE);

  const findColor = (varName, defaultColor) => {
    const colorVar = variables.find((v) => v.name == varName);
    if (colorVar) {
      let color = colors.find((f) => f.id == colorVar.colorId);
      if (color) return color.value;
    }
    return defaultColor;
  };

  useEffect(() => {
    if (!bodyColor) {
      const newBodyColor = findColor("bodyColor", "#212529");
      setBodyColor(newBodyColor);
    }

    if (!bodyBg) {
      const newBodyBg = findColor("bodyBg", "#FFFFFF");
      setBodyBg(newBodyBg);
    }

    if (!linkColor) {
      const newLinkColor = findColor("linkColor", "#007bff");
      setLinkColor(newLinkColor);
    }

    if (!linkHoverColor) {
      const newLinkHoverColor = findColor("linkHoverColor", "#0055D9");
      setLinkHoverColor(newLinkHoverColor);
    }
  }, [variables]);

  return (
    <>
      <Container>
        <div className="form">
          <div className="form-group">
            <Row>
              <Col>
                <label className="label">Body Background Color</label>
                <ColorPicker value={bodyBg} onChange={setBodyBg} />
              </Col>

              <Col>
                <label className="label">Body Text Color</label>
                <ColorPicker value={bodyColor} onChange={setBodyColor} />
              </Col>
            </Row>
          </div>

          <div className="form-group">
            <Row>
              <Col>
                <label className="label">Link Color</label>
                <ColorPicker value={linkColor} onChange={setLinkColor} />
              </Col>

              <Col>
                <label className="label">Link Hover Color</label>
                <ColorPicker
                  value={linkHoverColor}
                  onChange={setLinkHoverColor}
                />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
      <div className="onboarding-action">
        <a
          className={
            submitting ? "btn btn-dark btn-lg" : "btn btn-primary btn-lg"
          }
          onClick={async () => {
            if (submitting) return false;
            setSubmitting(true);

            await assignColorToVariable({
              variables: {
                projectId: parseInt(projectId),
                name: "Body Color",
                value: bodyColor,
                variableName: "bodyColor",
              },
            });

            await assignColorToVariable({
              variables: {
                projectId: parseInt(projectId),
                name: "Body Background",
                value: bodyBg,
                variableName: "bodyBg",
              },
            });

            await assignColorToVariable({
              variables: {
                projectId: parseInt(projectId),
                name: "Links",
                value: linkColor,
                variableName: "linkColor",
              },
            });

            await assignColorToVariable({
              variables: {
                projectId: parseInt(projectId),
                name: "Links Hover",
                value: linkHoverColor,
                variableName: "linkHoverColor",
              },
            });

            await refetchProject();

            goToNextStep(currentStep);
          }}
        >
          {submitting ? <i className="fas fa-spinner fa-spin"></i> : "Continue"}
        </a>
      </div>
    </>
  );
};

export default Step;
