import React, { useState } from "react";

import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";

import { useQuery, useMutation } from "@apollo/client";

import { CREATE_S3_SIGNED_REQUEST } from "./queries";

const Uploader = ({ projectId, onSave, onRemove }) => {
  const [createS3SignedRequest] = useMutation(CREATE_S3_SIGNED_REQUEST);

  // specify upload params and url for your files
  const getUploadParams = async ({ meta: { name } }) => {
    const response = await await createS3SignedRequest({
      variables: {
        fileName: name,
        projectId
      }
    });

    const data = response.data.createS3SignedRequest;
    const { signedRequest } = data;
    const { fields, url } = signedRequest;
    return { fields, meta: { fileUrl: `${url}/${fields.key}` }, url: url };
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    if (status == "done") {
      onSave(file.name, meta.fileUrl, meta);
    } else if (status == "removed") {
      onRemove();
    }
  };

  return (
    <Dropzone
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      accept="image/*"
      maxFiles={1}
      multiple={false}
      canCancel={false}
      inputContent="Drop A File"
      styles={{
        dropzone: { width: "100%", height: 120 },
        dropzoneActive: { borderColor: "green" }
      }}
    />
  );
};

export default Uploader;
