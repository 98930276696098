import React from "react";
import styled from "styled-components";

import types from "./types";

const Container = styled.div``;

const Editor = ({
  name,
  title,
  type = "text",
  editorKey,
  style,
  onChange,
  project
}) => {
  const Component = types[type];

  const { active, inherited } = style;

  const values = {
    ...inherited,
    ...active
  };

  const isChanged = !!active[name];
  const hasValue = !!values[name];

  return (
    <Container>
      <Component
        key={`${editorKey}:${name}`}
        project={project}
        name={name}
        title={title}
        isChanged={isChanged}
        hasValue={hasValue}
        value={values[name]}
        onChange={onChange}
      />
    </Container>
  );
};
export default Editor;
