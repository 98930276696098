import { gql } from "@apollo/client";

export const GET_FONTS = gql`
  query getFonts($projectId: Int!) {
    fonts(projectId: $projectId) {
      id
      name
      value
    }
  }
`;

export const GET_COLORS = gql`
  query getColors($projectId: Int!) {
    colors(projectId: $projectId) {
      id
      name
      value
    }
  }
`;

export const GET_VARIABLES = gql`
  query getVariables($projectId: Int!) {
    variables(projectId: $projectId) {
      id
      name
      value
      colorId
      fontId
    }
  }
`;

export const CREATE_VARIABLE = gql`
  mutation CreateVariable(
    $projectId: Int!
    $name: String!
    $value: String
    $fontId: Int
    $colorId: Int
  ) {
    createVariable(
      input: {
        projectId: $projectId
        name: $name
        value: $value
        fontId: $fontId
        colorId: $colorId
      }
    ) {
      success
      error
      variable {
        id
        name
        value
        fontId
        colorId
      }
    }
  }
`;

export const UPDATE_VARIABLE = gql`
  mutation UpdateVariable(
    $id: Int!
    $name: String
    $value: String
    $fontId: Int
    $colorId: Int
  ) {
    updateVariable(
      input: {
        id: $id
        name: $name
        value: $value
        fontId: $fontId
        colorId: $colorId
      }
    ) {
      success
      error
      variable {
        id
        name
        value
        fontId
        colorId
      }
    }
  }
`;

export const DELETE_VARIABLE = gql`
  mutation DeleteVariable($id: Int!) {
    deleteVariable(input: { id: $id }) {
      success
      error
    }
  }
`;
