import React, { useState } from "react";

import { useDrag } from "react-dnd";

import {
  jsonToCss,
  cssToStyle,
  getStyles,
  isVoidElement,
  isInlineElement,
  isProjectUtilityClass,
  utilityClassToId
} from "../../../common/utils";

import types from "./types";

const Component = ({
  layerRef,
  component,
  children,
  externalComponent,
  activeComponentId,
  setActiveComponentId,
  activeComponentState,
  variationId,
  activeDevice,
  isHovering,
  setHoverComponentId,
  setDropComponentId,
  symbolId,
  setSymbolId,
  symbolShadowId,
  setSymbolShadowId,
  project
}) => {
  const { fonts, colors, utilities } = project;
  const [{ isDragging }, drag] = useDrag({
    item: { type: "layer", componentId: component.id },
    end: (item, monitor) => {
      setDropComponentId(null);
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });

  let renderComponent = externalComponent || component;

  const isActive = isHovering || activeComponentId == component.id;
  const variation = renderComponent.variations.find(v => v.id == variationId);

  const inActiveSymbol = !!symbolId;
  const isExternal = !!component.externalComponentId;
  const isSymbol = isExternal && !!component.external.containerComponentId;

  let selectors = [renderComponent.selector];
  if (variation) selectors.push(variation.selector);
  if (activeComponentState) selectors.push(`:${activeComponentState}`);

  // Dont load styles for external components (not symbols) because their styles are loaded in full css already
  let styles =
    isExternal && !isSymbol
      ? {}
      : getStyles(renderComponent.styles, selectors, variationId, activeDevice);

  const css = jsonToCss(
    {
      ...styles.inherited,
      ...styles.active
    },
    fonts,
    colors
  );

  const cssStyles = cssToStyle(css);

  const htmlTag = renderComponent.htmlTag;

  const Tag =
    types[htmlTag] || types[isInlineElement(htmlTag) ? "span" : "div"];

  if (layerRef) drag(layerRef);

  const canEdit = !inActiveSymbol || (inActiveSymbol && !isExternal); // We cannot allow editing external components and symbols if we are already inside a symbol

  let cssClasses = [renderComponent.selector]
    .concat(renderComponent.customCssClasses)
    .concat(component.customCssClasses)
    .map(cssClass => {
      let cssClassName = cssClass;
      if (isProjectUtilityClass(cssClass)) {
        const utilityClassId = utilityClassToId(cssClass);
        const utilityClass = utilities.find(uc => uc.id == utilityClassId);
        cssClassName = utilityClass.selector;
      }
      return cssClassName;
    })
    .join(" ")
    .trim()
    .replace(/\./g, "");

  const canHaveChildren = !isVoidElement(htmlTag);
  let isEmpty = renderComponent.customCssClasses.length > 0 ? false : true;
  if (isEmpty) {
    children.map(child => {
      if (!child || typeof child == "boolean") {
      } else if (typeof child == "string" && child.trim() != "") {
        isEmpty = false;
      } else if (typeof child == "object") {
        isEmpty = false;
      }
    });
  }

  // This tag does not have any content inside it so we will show empty box
  if (canHaveChildren && isEmpty && Object.keys(cssStyles).length == 0) {
    cssClasses += " monad-empty";
  }

  return (
    <Tag
      layerRef={layerRef}
      component={renderComponent}
      className={cssClasses}
      children={!isVoidElement(htmlTag) ? children : null}
      style={{
        ...cssStyles,
        cursor: "default"
      }}
      onDoubleClick={
        canEdit &&
        setActiveComponentId &&
        !!component.externalComponentId &&
        !!component.external.containerComponentId
          ? e => {
              setSymbolId(component.external.id);
              setSymbolShadowId(component.id);
              setActiveComponentId(component.external.id);
              setDropComponentId(null);
            }
          : null
      }
      onClick={
        setActiveComponentId
          ? e => {
              e.stopPropagation();
              if (canEdit) {
                setActiveComponentId(component.id);
              }
            }
          : null
      }
      onMouseOver={
        setHoverComponentId
          ? e => {
              e.stopPropagation();
              setHoverComponentId(component.id);
            }
          : null
      }
      onMouseOut={
        setHoverComponentId
          ? e => {
              e.stopPropagation();
              setHoverComponentId(null);
            }
          : null
      }
    />
  );
};
export default Component;
