import React from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";

const TabsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  .tab {
    text-align: center;
    flex: 1;
    max-width: 135px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px 10px;
    text-decoration: none;

    color: #000;
    font-size: 16px;

    &.active {
      border: 1px solid rgba(37, 47, 63, 1);
      background-color: rgba(37, 47, 63, 1);
      color: #fff;
    }

    &:first-child {
      margin-right: 10px;
    }
  }
`;

const Tabs = ({ projectId }) => {
  return (
    <TabsContainer>
      <NavLink exact className="tab" activeClassName="active" to="/components">
        Components
      </NavLink>
      <NavLink
        exact
        className="tab"
        activeClassName="active"
        to="/components/utilities"
      >
        Utilities
      </NavLink>
    </TabsContainer>
  );
};

export default Tabs;
