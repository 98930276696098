import React from "react";

import { Redirect } from "react-router-dom";

const Dashboard = () => {
  return (
    <Redirect
      to={{
        pathname: "/components"
      }}
    />
  );
};

export default Dashboard;
