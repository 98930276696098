import React from "react";

import Select from "react-select";

import { debounce } from "lodash";

import { SizeFieldContainer, UnitDropdownContainer } from "../../styles";

const Editor = ({ name, title, value, onChange, isChanged, hasValue }) => {
  const values = [
    { label: "px", value: "px" },
    { label: "%", value: "%" },
  ];

  const unit = value ? value.replace(/[0-9]/g, "") : "px";
  const intValue = value ? value.replace(/\D/g, "") : null;

  const activeUnit = values.find((v) => v.value == unit);

  const onChangeWithDebounce = debounce(onChange, 500);

  return (
    <SizeFieldContainer>
      <input
        type="text"
        defaultValue={intValue}
        placeholder="0"
        onChange={(e) =>
          onChangeWithDebounce(
            name,
            e.target.value ? `${e.target.value}${unit}` : null
          )
        }
      />

      <UnitDropdownContainer>
        <Select
          defaultValue={activeUnit}
          isClearable={false}
          isSearchable={false}
          name={name}
          onChange={(data) =>
            onChangeWithDebounce(name, `${intValue}${data.value}`)
          }
          options={values}
          classNamePrefix="react-select"
        />
      </UnitDropdownContainer>
    </SizeFieldContainer>
  );
};
export default Editor;
