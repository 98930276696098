import React, { useState } from "react";

import Loader from "../../../components/common/Loader";
import Modal from "../../common/Modal";
import Dependency from "./Dependency";

import { Formik } from "formik";

import { useQuery, useMutation } from "@apollo/client";

import {
  GET_DEPENDENCIES,
  CREATE_DEPENDENCY,
  UPDATE_DEPENDENCY,
  DELETE_DEPENDENCY
} from "./queries";

const Dependencies = ({ projectId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editDependency, setEditDependency] = useState({});
  const [createDependency] = useMutation(CREATE_DEPENDENCY);
  const [updateDependency] = useMutation(UPDATE_DEPENDENCY);
  const [deleteDependency] = useMutation(DELETE_DEPENDENCY);

  const { loading, error, data, refetch } = useQuery(GET_DEPENDENCIES, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const onEditDependency = dependency => {
    setEditDependency(dependency);
    setModalIsOpen(true);
  };

  const onDeleteDependency = async dependencyId => {
    if (window.confirm("Are you sure?")) {
      await deleteDependency({
        variables: {
          id: dependencyId
        }
      });
      refetch();
    }
  };

  return (
    <div>
      <div className="settings-header">
        <div className="title">Dependencies</div>
        <div className="action">
          <a
            className="btn btn-primary"
            onClick={() => {
              setEditDependency({});
              setModalIsOpen(true);
            }}
          >
            New Dependency
          </a>
        </div>
      </div>

      {data && data.dependencies.length == 0 && (
        <div
          style={{
            padding: 20,
            textAlign: "center",
            color: "#999",
            fontWeight: "bold"
          }}
        >
          No Dependencies Found
        </div>
      )}

      {data && data.dependencies.length > 0 && (
        <div className="list">
          {data &&
            data.dependencies.map(c => {
              return (
                <Dependency
                  key={`dependency${c.id}`}
                  id={c.id}
                  name={c.name}
                  position={c.position}
                  type={c.type}
                  url={c.url}
                  onEditDependency={onEditDependency}
                  onDeleteDependency={onDeleteDependency}
                />
              );
            })}
        </div>
      )}

      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            name: "",
            type: "STYLESHEET",
            url: "",
            position: 1,
            ...editDependency
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.url) {
              errors.url = "Required";
            }
            if (!values.type) {
              errors.type = "Required";
            }
            if (!values.position) {
              errors.position = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (editDependency && editDependency.id) {
              await updateDependency({
                variables: {
                  id: parseInt(editDependency.id),
                  ...values
                }
              });
            } else {
              await createDependency({
                variables: {
                  projectId,
                  ...values
                }
              });
            }

            refetch();
            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">
                    {editDependency.id ? "Edit" : "New"} Dependency
                  </h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p className="hint error">{errors.name}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Type</label>
                    <select
                      className="form-select"
                      name="type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.type}
                    >
                      <option value="STYLESHEET" className="select-option">
                        Stylesheet
                      </option>
                      <option value="JAVASCRIPT" className="select-option">
                        Javascript
                      </option>
                    </select>
                    {errors.type && touched.type && (
                      <p className="hint error">{errors.type}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">URL</label>
                    <input
                      className="form-control"
                      type="text"
                      name="url"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.url}
                    />
                    {errors.url && touched.url && (
                      <p className="hint error">{errors.url}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Dependencies;
