import React from "react";
import Modal from "react-modal";

import styled from "styled-components";

const customStyles = {
  overlay: {
    zIndex: 110
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "transparent",
    border: "0px",
    padding: "0px"
  }
};

Modal.setAppElement("#root");

const ModalContainer = styled.div`
  z-index: 110;
`;

const Container = ({ isOpen, closeModal, children }) => {
  return (
    <ModalContainer>
      <Modal isOpen={isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="modal">{children}</div>
      </Modal>
    </ModalContainer>
  );
};

export default Container;
