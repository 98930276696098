import React, { useState, useRef, useEffect } from "react";

import { useMutation } from "@apollo/client";
import { Formik } from "formik";

import { UPDATE_PROJECT, DELETE_PROJECT } from "./queries";

import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/lib/codemirror.css";
import CodeMirror from "codemirror/lib/codemirror";

import styled from "styled-components";

const Well = styled.div`
  padding: 20px 20px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #eee;
  margin: 30px 0px;

  &:last-child {
    margin-bottom: 0px;
  }

  .header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .CodeMirror {
    border: 1px solid #ccc;
    height: auto;

    .CodeMirror-sizer {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
`;

const DangerWell = styled.div`
  .header {
    color: #cb2431;
    font-weight: bold;
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .danger-container {
    margin-bottom: 30px;
    padding: 20px;
    border-radius: 4px;
    border: 2px solid #cb2431;
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: center;

    .action-info,
    .action-btn {
      flex: 1;
    }

    .action-title {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .action-btn {
      text-align: right;
    }
  }
`;

const General = (props) => {
  const cssFileTextarea = useRef(null);
  const jsFileTextArea = useRef(null);
  const [saved, setSaved] = useState(false);
  const [updateProject] = useMutation(UPDATE_PROJECT);
  const [deleteProject] = useMutation(DELETE_PROJECT);
  const { history, project, projectId } = props;

  useEffect(() => {
    CodeMirror.fromTextArea(cssFileTextarea.current, {
      mode: { name: "htmlmixed" },
      lineNumbers: true,
      lineWrapping: true,
      readOnly: true,
    });

    CodeMirror.fromTextArea(jsFileTextArea.current, {
      mode: { name: "htmlmixed" },
      lineNumbers: true,
      lineWrapping: true,
      readOnly: true,
    });
  }, [project.id]);

  return (
    <div>
      <Well>
        <Formik
          initialValues={{
            name: project.name,
          }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            await updateProject({
              variables: {
                id: projectId,
                name: values.name,
              },
            });

            setSaved(true);
            setSubmitting(false);
            setTimeout(() => setSaved(false), 2000);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <div className="header">Project Name</div>
                <input
                  className="form-control"
                  type="text"
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <p className="hint error">{errors.name}</p>
                )}
              </div>
              <button
                className={
                  isSubmitting
                    ? "btn btn-dark"
                    : saved
                    ? "btn btn-success"
                    : "btn btn-primary"
                }
                type="submit"
                disabled={isSubmitting || saved}
              >
                {isSubmitting ? "Saving" : saved ? "Saved" : "Save"}
              </button>
            </form>
          )}
        </Formik>
      </Well>

      <Well>
        <div className="header">CSS</div>

        <form className="form">
          <div className="form-group">
            <p>
              {
                "Copy-paste the stylesheet <link> into your <head> before all other stylesheets to load our CSS."
              }
            </p>

            <textarea
              ref={cssFileTextarea}
              defaultValue={`<link rel="stylesheet" type="text/css" href="${project.fullStylesUrl}" crossorigin="anonymous" />`}
            ></textarea>
          </div>

          <div className="header">JS</div>

          <div className="form-group">
            <p>
              {
                "Place the following <script> near the end of your pages, right before the closing </body> tag."
              }
            </p>

            <textarea
              ref={jsFileTextArea}
              defaultValue={`<script src="${project.fullJavascriptUrl}" crossorigin="anonymous"></script>`}
            ></textarea>
          </div>
        </form>
      </Well>

      <DangerWell>
        <div className="header">Danger Zone</div>
        <div className="danger-container">
          <div className="action">
            <div className="action-info">
              <div className="action-title">Delete this Project</div>
              <div className="action-desc">
                Once deleted, it will be gone forever. Please be certain.
              </div>
            </div>

            <div className="action-btn">
              <a
                className={"btn btn-danger"}
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this project?"
                    )
                  ) {
                    const response = await deleteProject({
                      variables: {
                        id: parseInt(projectId),
                      },
                    });

                    if (
                      response &&
                      response.data &&
                      response.data.deleteProject &&
                      response.data.deleteProject.success
                    ) {
                      window.location.href = "/projects";
                      return;
                    }
                  }
                }}
              >
                Delete Project
              </a>
            </div>
          </div>
        </div>
      </DangerWell>
    </div>
  );
};

export default General;
