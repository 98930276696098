import React from "react";

import classnames from "classnames";

import SizeEditor from "./common/Size";

import { EditorContainer, EditorTitle } from "../styles";

const Editor = props => {
  const { name, title, isChanged, hasValue } = props;
  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>

      <SizeEditor {...props} />
    </EditorContainer>
  );
};
export default Editor;
