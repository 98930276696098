import React from "react";

const Image = ({
  id,
  name,
  value,
  url,
  onEditAttachment,
  onDeleteAttachment
}) => {
  return (
    <div className="list-item">
      <div className="preview">
        <img src={url} className="image" />
      </div>

      <div className="list-info">
        <div className="title">{name}</div>
      </div>

      <div className="actions">
        <a
          className="action-item"
          onClick={() => onEditAttachment({ id, name, value, url })}
        >
          <i className="action-icon fas fa-edit"></i>
        </a>
        <a className="action-item" onClick={() => onDeleteAttachment(id)}>
          <i className="action-icon fas fa-trash"></i>
        </a>
      </div>
    </div>
  );
};

export default Image;
