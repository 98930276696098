import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import { useQuery, useMutation } from "@apollo/client";

import { DndProvider } from "react-dnd";
import Backend from "react-dnd-html5-backend";
import Designer from "./Designer";

import Loader from "../../components/common/Loader";

import classNames from "classnames";

import {
  COMPONENT_QUERY,
  CHILD_COMPONENTS_QUERY,
  CREATE_COMPONENT,
  UPDATE_COMPONENT,
  CREATE_OR_UPDATE_STYLE,
  DELETE_COMPONENT,
  CREATE_VARIATION,
  DELETE_VARIATION,
  CONVERT_COMPONENT_TO_SYMBOL,
  UPDATE_COMPONENT_UTILITIES,
  CREATE_BUILD,
} from "./queries";

const DataWrapper = ({
  projectId,
  project,
  history,
  rootComponent,
  refetchRootComponent,
}) => {
  const [libFileVersion, setLibFileVersion] = useState(0);
  const [designVersion, setDesignVersion] = useState(0);
  const [hideLayerOutlines, setHideLayerOutlines] = useState(false);
  const [symbolId, setSymbolId] = useState(null);
  const [symbolShadowId, setSymbolShadowId] = useState(null);
  const [activeDevice, setActiveDevice] = useState("desktop");

  const [createComponent] = useMutation(CREATE_COMPONENT);
  const [updateComponent] = useMutation(UPDATE_COMPONENT);
  const [deleteComponent] = useMutation(DELETE_COMPONENT);
  const [createVariation] = useMutation(CREATE_VARIATION);
  const [deleteVariation] = useMutation(DELETE_VARIATION);
  const [createBuild] = useMutation(CREATE_BUILD);
  const [createOrUpdateStyle] = useMutation(CREATE_OR_UPDATE_STYLE);
  const [convertComponentToSymbol] = useMutation(CONVERT_COMPONENT_TO_SYMBOL);
  const [updateComponentUtilities] = useMutation(UPDATE_COMPONENT_UTILITIES);

  const bumpDesignVersion = () => {
    setDesignVersion(designVersion + 1);
  };

  const bumpLibFileVersion = () => {
    setLibFileVersion(libFileVersion + 1);
  };

  useEffect(() => {
    bumpDesignVersion();
  }, [hideLayerOutlines]);

  const { loading, error, data, refetch } = useQuery(CHILD_COMPONENTS_QUERY, {
    variables: {
      projectId: parseInt(projectId),
      rootComponentId: parseInt(rootComponent.id),
    },
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper">
          <div className="heading">
            <Link className="breadcrumb" to="/components">
              Components
            </Link>
            <h1 className="breadcrumb">/</h1>
            <h1 className="breadcrumb active">
              {rootComponent.htmlTag}
              {rootComponent.selector}
            </h1>
          </div>

          <div className="actions">
            <a
              className="action action-danger"
              onClick={async () => {
                if (window.confirm("Are you sure?")) {
                  await deleteComponent({
                    variables: {
                      id: rootComponent.id,
                    },
                  });
                  history.push("/components");
                }
              }}
            >
              Delete
            </a>

            <a
              className="action"
              onClick={async () => {
                await createBuild({
                  variables: {
                    projectId: parseInt(projectId),
                    comment: `Updated ${rootComponent.selector}`,
                  },
                });
              }}
            >
              Publish Live
            </a>
          </div>
        </div>
      </div>

      <DndProvider backend={Backend}>
        <Designer
          projectId={projectId}
          project={project}
          rootComponent={rootComponent}
          allChildComponents={data.components}
          refetchRootComponent={refetchRootComponent}
          refetchChildComponents={refetch}
          createComponent={createComponent}
          updateComponent={updateComponent}
          deleteComponent={deleteComponent}
          createVariation={createVariation}
          deleteVariation={deleteVariation}
          createOrUpdateStyle={createOrUpdateStyle}
          convertComponentToSymbol={convertComponentToSymbol}
          updateComponentUtilities={updateComponentUtilities}
          setSymbolId={setSymbolId}
          symbolId={symbolId}
          setSymbolShadowId={setSymbolShadowId}
          symbolShadowId={symbolShadowId}
          activeDevice={activeDevice}
          setActiveDevice={setActiveDevice}
          designVersion={designVersion}
          bumpDesignVersion={bumpDesignVersion}
          hideLayerOutlines={hideLayerOutlines}
          setHideLayerOutlines={setHideLayerOutlines}
          libFileVersion={libFileVersion}
          bumpLibFileVersion={bumpLibFileVersion}
        />
      </DndProvider>
    </React.Fragment>
  );
};

const Component = (props) => {
  let { componentId } = useParams();

  const { loading, error, data, refetch } = useQuery(COMPONENT_QUERY, {
    variables: { id: parseInt(componentId) },
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <DataWrapper
      {...props}
      key={`rootComponent${componentId}`}
      rootComponent={data.component}
      refetchRootComponent={refetch}
    />
  );
};

export default Component;
