import React, { useState } from "react";

import Loader from "../../../components/common/Loader";
import Modal from "../../common/Modal";
import Image from "./Image";

import Storage from "./Storage";

import { Field, Formik } from "formik";

import Uploader from "./Uploader";

import { useQuery, useMutation } from "@apollo/client";

import {
  GET_ATTACHMENTS,
  CREATE_ATTACHMENT,
  UPDATE_ATTACHMENT,
  DELETE_ATTACHMENT,
  UPDATE_PROJECT
} from "./queries";

const Images = props => {
  const { projectId } = props;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editAttachment, setEditAttachment] = useState({});
  const [createAttachment] = useMutation(CREATE_ATTACHMENT);
  const [updateAttachment] = useMutation(UPDATE_ATTACHMENT);
  const [deleteAttachment] = useMutation(DELETE_ATTACHMENT);
  const [updateProject] = useMutation(UPDATE_PROJECT);

  const { loading, error, data, refetch } = useQuery(GET_ATTACHMENTS, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const onEditAttachment = attachment => {
    setEditAttachment(attachment);
    setModalIsOpen(true);
  };

  const onDeleteAttachment = async attachmentId => {
    if (window.confirm("Are you sure?")) {
      await deleteAttachment({
        variables: {
          id: attachmentId
        }
      });
      refetch();
    }
  };

  return (
    <div>
      <Storage {...props} updateProject={updateProject} />

      <div className="settings-header">
        <div className="title">Images</div>
        <div className="action">
          <a
            className="btn btn-primary"
            onClick={() => {
              onEditAttachment({});
              setModalIsOpen(true);
            }}
          >
            New Image
          </a>
        </div>
      </div>

      {data && data.attachments.length == 0 && (
        <div
          style={{
            padding: 20,
            textAlign: "center",
            color: "#999",
            fontWeight: "bold"
          }}
        >
          No Images Found
        </div>
      )}

      {data && data.attachments.length > 0 && (
        <div className="list">
          {data &&
            data.attachments.map(c => {
              return (
                <Image
                  key={`font${c.id}`}
                  id={c.id}
                  name={c.name}
                  value={c.value}
                  url={c.url}
                  onEditAttachment={onEditAttachment}
                  onDeleteAttachment={onDeleteAttachment}
                />
              );
            })}
        </div>
      )}

      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            name: "",
            url: "",
            ...editAttachment
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.url) {
              errors.url = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (editAttachment && editAttachment.id) {
              await updateAttachment({
                variables: {
                  id: parseInt(editAttachment.id),
                  ...values
                }
              });
            } else {
              await createAttachment({
                variables: {
                  projectId,
                  ...values
                }
              });
            }

            refetch();
            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">
                    {editAttachment.id ? "Edit" : "New"} Image
                  </h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p className="hint error">{errors.name}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Image</label>
                    <Field name="url">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta
                      }) => (
                        <Uploader
                          projectId={projectId}
                          onSave={(name, url) => {
                            setFieldValue("url", url);
                          }}
                          onRemove={() => {
                            setFieldValue("url", null);
                          }}
                        />
                      )}
                    </Field>
                    {errors.url && touched.url && (
                      <p className="hint error">{errors.url}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Images;
