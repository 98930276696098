import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Field } from "formik";

import { useQuery, useMutation } from "@apollo/client";
import {
  PAGE_QUERY,
  CREATE_OR_UPDATE_PAGE,
  UPDATE_PAGE,
  DELETE_PAGE,
  CREATE_CATEGORY
} from "./queries";

import CreatableSelect from "react-select/creatable";

import Loader from "../../components/common/Loader";
import PageEditor from "./PageEditor";

import generateMarkup from "./generateMarkup";

import { SaveBar } from "./styles";

import styled from "styled-components";

const EditorContainer = styled.div`
  margin: 20px 0px;

  label {
    color: #999;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 2px !important;
    margin-right: 10px;
  }

  .error {
    color: #ad2d39;
    font-size: 12px;
    font-weight: bold;
  }
`;

const CustomPageEditor = ({ field, form: { setFieldValue } }) => {
  return (
    <PageEditor
      json={field.value}
      onChange={data => setFieldValue("json", data)}
    />
  );
};

const Pages = ({ history, project, projectId, refetchProject }) => {
  const { pageId } = useParams();

  const [createOrUpdatePage] = useMutation(CREATE_OR_UPDATE_PAGE);
  const [deletePage] = useMutation(DELETE_PAGE);
  const [updatePage] = useMutation(UPDATE_PAGE);
  const [createCategory] = useMutation(CREATE_CATEGORY);

  const { loading, error, data } = useQuery(PAGE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: parseInt(pageId)
    }
  });

  let page = {};
  if (data && data.page) page = data.page;

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Documentation /</h1>
            <h1 className="breadcrumb active">{page.name}</h1>
          </div>

          <div className="actions">
            <a
              className="action action-danger"
              onClick={async () => {
                if (window.confirm("Are you sure?")) {
                  await deletePage({
                    variables: {
                      id: page.id
                    }
                  });
                  history.push("/docs");
                }
              }}
            >
              Delete
            </a>

            {page.component && (
              <a
                className="action action-primary"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure? This will completely revert your changes and reset the document to default."
                    )
                  ) {
                    let defaultJson = {};
                    defaultJson = generateMarkup(page.component);
                    await updatePage({
                      variables: {
                        id: page.id,
                        json: defaultJson
                      }
                    });
                    window.location.reload();
                  }
                }}
              >
                Reset
              </a>
            )}

            <a className="action" href={page.url} target="_blank">
              Open <i className="fas fa-external-link"></i>
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <Formik
          initialValues={{
            name: page.name,
            json: page.json,
            categoryId: page.categoryId
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.json || Object.keys(values.json) == 0) {
              errors.json = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const response = await createOrUpdatePage({
              variables: {
                ...values,
                id: page.id
              }
            });

            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => {
            return (
              <form onSubmit={handleSubmit} className="form">
                <SaveBar>
                  <div>
                    <label className="label">Name</label>
                    {errors.name && touched.name && (
                      <span className="error">{errors.name}</span>
                    )}
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </div>

                  {page.component && (
                    <div>
                      <label className="label">Component</label>
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        value={page.component.selector}
                      />
                    </div>
                  )}

                  <div>
                    <label className="label">Category</label>
                    <div>
                      <Field name="categoryId">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta
                        }) => {
                          let selectedCategory = project.categories.find(
                            c => c.id == field.value
                          );

                          return (
                            <div>
                              <CreatableSelect
                                isClearable
                                isMulti={false}
                                classNamePrefix="react-select"
                                value={
                                  selectedCategory
                                    ? {
                                        value: selectedCategory.id,
                                        label: selectedCategory.label
                                      }
                                    : null
                                }
                                onChange={async data => {
                                  if (!data) {
                                    setFieldValue("categoryId", null);
                                    return;
                                  }

                                  let newCategory;
                                  if (data.__isNew__) {
                                    const response = await createCategory({
                                      variables: {
                                        projectId: parseInt(projectId),
                                        label: data.value
                                      }
                                    });
                                    await refetchProject();
                                    let category =
                                      response.data.createCategory.category;
                                    newCategory = {
                                      value: category.id,
                                      label: category.slug
                                    };
                                  } else {
                                    newCategory = data;
                                  }
                                  setFieldValue(
                                    "categoryId",
                                    newCategory.value
                                  );
                                }}
                                options={project.categories.map(c => {
                                  return {
                                    value: c.id,
                                    label: c.label
                                  };
                                })}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <p className="hint error">
                                  {errors.categoryId}
                                </p>
                              )}
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <div className="action">
                    <button
                      type="submit"
                      className={
                        isSubmitting ? "btn btn-dark" : "btn btn-primary"
                      }
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Saving" : "Save Page"}
                    </button>
                  </div>
                </SaveBar>

                <EditorContainer>
                  <div>
                    <label className="label">Documentation</label>
                    {errors.json && touched.json && (
                      <span className="error">{errors.json}</span>
                    )}
                  </div>
                  <Field name="json" component={CustomPageEditor} />
                </EditorContainer>
              </form>
            );
          }}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default Pages;
