import { gql } from "@apollo/client";

export const PROJECT_QUERY = gql`
  query getProject($id: Int!) {
    project(id: $id) {
      id
      owner {
        id
      }
      invitations {
        id
        email
        role
        acceptedAt
        declinedAt
      }
      members {
        role
        user {
          id
          name
          email
        }
      }
    }
  }
`;

export const INVITE_USER_TO_PROJECT = gql`
  mutation InviteUserToProject($projectId: Int!, $email: String) {
    inviteUserToProject(input: { projectId: $projectId, email: $email }) {
      success
      error
      invitation {
        id
        email
        role
      }
    }
  }
`;

export const UNINVITE_USER_FROM_PROJECT = gql`
  mutation UninviteUserFromProject($id: Int!) {
    uninviteUserFromProject(input: { id: $id }) {
      success
      error
      deletedAt
    }
  }
`;
