import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Col = styled.div`
  flex: 1;
  max-width: 48%;
`;

export const EditorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
`;

export const EditorTitle = styled.div`
  flex: 1;
  color: #fff;
  font-size: 12px;
  max-width: 60px;
  min-width: 60px;

  span {
    display: inline-block;
    text-transform: capitalize;
    padding: 2px;
  }

  &.hasValue {
    span {
      color: rgb(250, 181, 134);
      background-color: rgba(240, 139, 72, 0.15);
    }
  }

  &.changed {
    span {
      color: rgb(139, 190, 250);
      background-color: rgba(76, 152, 241, 0.15);
    }
  }
`;

export const DropdownContainer = styled.div`
  flex: 1;

  .react-select__control {
    color: hsla(0, 0%, 100%, 0.5);
    background-color: rgb(43, 43, 43);
    border: 1px solid #666;
    font-size: 12px;
    min-height: 30px;

    &:hover {
      border: 1px solid #999;
    }
  }

  .react-select__menu {
    z-index: 10;
  }

  .react-select__option {
    padding: 10px;
    color: #212529;

    &.react-select__option--is-selected {
      color: #fff;
    }
  }

  .react-select__single-value {
    color: hsla(0, 0%, 100%, 0.5);
  }

  .react-select__multi-value {
    color: hsla(0, 0%, 100%, 0.5);
    background-color: rgb(59, 121, 195);
    margin: 5px 3px;
  }

  .react-select__value-container {
    padding: 2px 5px;
  }

  .react-select__value-container--is-multi {
    .react-select__input {
      input {
        color: #fff !important;
      }
    }
  }

  .react-select__multi-value__label {
    color: #fff;
    font-size: 14px;
  }

  .react-select__indicators > div {
    padding: 4px;
  }
`;

export const UnitDropdownContainer = styled.div`
  flex: 1;
  max-width: ${props => (props.full ? "100%" : props.long ? "50px" : "35px")};

  .react-select__dropdown-indicator,
  .react-select__indicators {
    display: none;
  }

  .react-select__control {
    border: 0px !important;
    border-radius: 0px !important;
    text-align: center;
    color: hsla(0, 0%, 100%, 0.5);
    background-color: rgb(43, 43, 43);
    border: 1px solid #666;
    font-size: 12px;
    min-height: 30px;

    &:hover {
      border: 1px solid #999;
    }
  }

  .react-select__menu {
    z-index: 10;
  }

  .react-select__option {
    padding: 10px;
    color: #212529;

    &.react-select__option--is-selected {
      color: #fff;
    }
  }

  .react-select__single-value {
    color: hsla(0, 0%, 100%, 0.5);
  }
`;

export const TextFieldContainer = styled.div`
  flex: 1;
  display: flex;
  border: 1px solid #666;
  border-radius: 4px;

  &:hover {
    border: 1px solid #999;
  }

  input {
    flex: 1;
    color: rgb(217, 217, 217);
    padding: 7px;
    line-height: 16px;
    font-size: 12px;
    background-color: rgb(43, 43, 43);
    border: 0px;
    width: 100%;
    outline: none;
  }
`;

export const ColorPickerContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #666;
  border-radius: 4px;
  overflow: hidden;
  height: 30px;

  &:hover {
    border: 1px solid #999;
  }

  input {
    flex: 1;
    color: rgb(217, 217, 217);
    line-height: 16px;
    font-size: 12px;
    background-color: rgb(43, 43, 43);
    border: 0px;
    width: 100%;
    height: 100%;
    outline: none;
    padding-left: 5px;
  }
`;

export const SizeFieldContainer = styled.div`
  flex: 1;
  display: flex;
  border: 1px solid #666;

  &:hover {
    border: 1px solid #999;
  }

  input {
    flex: 1;
    color: rgb(217, 217, 217);
    padding: 4px;
    line-height: 16px;
    font-size: 11px;
    background-color: rgb(43, 43, 43);
    border: 0px;
    width: 100%;
  }

  .unit {
    max-width: 25px;
    text-transform: uppercase;
    text-align: center;
    color: #999;
  }
`;

export const SquareButtonGroup = styled.div`
  border: 1px solid rgb(51, 51, 51);
  flex: 1;
  display: flex;
  border-radius: 2px;
  box-shadow: rgb(51, 51, 51) 0px 0px 0px 1px inset;
`;

export const SquareButton = styled.a`
  display: inline-block;
  padding: 5px;
  background-color: rgb(94, 94, 94);
  color: rgb(217, 217, 217);
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 14px;
  border-right: 1px solid rgb(51, 51, 51);

  &:last-child {
    border-right: 0px;
  }

  &:hover {
    color: rgb(217, 217, 217);
    background-color: rgb(102, 102, 102);
  }

  &.active {
    background-color: rgb(43, 43, 43) !important;
  }
`;
