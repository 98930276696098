import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Formik, Field } from "formik";

import CreatableSelect from "react-select/creatable";

import {
  ROOT_COMPONENTS_QUERY,
  CREATE_OR_UPDATE_PAGE,
  CREATE_CATEGORY
} from "./queries";

import Loader from "../../components/common/Loader";
import PageEditor from "./PageEditor";

import generateMarkup from "./generateMarkup";

import { SaveBar } from "./styles";

import styled from "styled-components";

const EditorContainer = styled.div`
  margin: 20px 0px;

  label {
    color: #999;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 2px !important;
    margin-right: 10px;
  }

  .error {
    color: #ad2d39;
    font-size: 12px;
    font-weight: bold;
  }
`;

const CustomPageEditor = ({ field, form: { setFieldValue } }) => {
  return (
    <PageEditor
      json={field.value}
      onChange={data => setFieldValue("json", data)}
    />
  );
};

const NewPage = ({ projectId, project, history, refetchProject }) => {
  const [selectedComponentId, setSelectedComponentId] = useState(null);
  const [createOrUpdatePage] = useMutation(CREATE_OR_UPDATE_PAGE);
  const [createCategory] = useMutation(CREATE_CATEGORY);

  const { loading, error, data } = useQuery(ROOT_COMPONENTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  let component = null;
  let defaultJson = {};
  if (selectedComponentId) {
    component = data.rootComponents.find(
      c => c.id == parseInt(selectedComponentId)
    );

    defaultJson = generateMarkup(component);
  }

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Documentation /</h1>
            <h1 className="breadcrumb active">New Page</h1>
          </div>

          <div className="actions"></div>
        </div>
      </div>

      <div className="container">
        <Formik
          key={`formik${selectedComponentId}`}
          initialValues={{
            name: "Page",
            json: defaultJson,
            componentId: selectedComponentId,
            categoryId: null
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.json || Object.keys(values.json) == 0) {
              errors.json = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const response = await createOrUpdatePage({
              variables: {
                ...values,
                componentId: parseInt(values.componentId),
                projectId: parseInt(projectId)
              }
            });
            if (
              response &&
              response.data &&
              response.data.createOrUpdatePage &&
              response.data.createOrUpdatePage.success
            ) {
              const page = response.data.createOrUpdatePage.page;
              history.push(`/docs/${page.id}`);
              return;
            }
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
            /* and other goodies */
          }) => {
            return (
              <form onSubmit={handleSubmit} className="form">
                <SaveBar>
                  <div>
                    <label className="label">Name</label>
                    {errors.name && touched.name && (
                      <span className="error">{errors.name}</span>
                    )}
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </div>

                  <div>
                    <label className="label">Component</label>
                    <select
                      name="componentId"
                      className="form-select"
                      defaultValue={values.componentId}
                      onChange={e => {
                        handleChange(e);
                        setSelectedComponentId(e.target.value);
                      }}
                    >
                      <option value="">No Component</option>
                      {data.rootComponents.map(c => {
                        return (
                          <option key={`component${c.id}`} value={c.id}>
                            {c.selector}
                          </option>
                        );
                      })}
                    </select>
                    {errors.componentId &&
                      touched.componentId &&
                      errors.componentId}
                  </div>

                  <div>
                    <label className="label">Category</label>
                    <div>
                      <Field name="categoryId">
                        {({
                          field, // { name, value, onChange, onBlur }
                          form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          meta
                        }) => {
                          let selectedCategory = project.categories.find(
                            c => c.id == field.value
                          );

                          return (
                            <div>
                              <CreatableSelect
                                isClearable
                                isMulti={false}
                                classNamePrefix="react-select"
                                value={
                                  selectedCategory
                                    ? {
                                        value: selectedCategory.id,
                                        label: selectedCategory.label
                                      }
                                    : null
                                }
                                onChange={async data => {
                                  if (!data) {
                                    setFieldValue("categoryId", null);
                                    return;
                                  }

                                  let newCategory;
                                  if (data.__isNew__) {
                                    const response = await createCategory({
                                      variables: {
                                        projectId: parseInt(projectId),
                                        label: data.value
                                      }
                                    });
                                    await refetchProject();
                                    let category =
                                      response.data.createCategory.category;
                                    newCategory = {
                                      value: category.id,
                                      label: category.slug
                                    };
                                  } else {
                                    newCategory = data;
                                  }
                                  setFieldValue(
                                    "categoryId",
                                    newCategory.value
                                  );
                                }}
                                options={project.categories.map(c => {
                                  return {
                                    value: c.id,
                                    label: c.label
                                  };
                                })}
                              />
                              {errors.categoryId && touched.categoryId && (
                                <p className="hint error">
                                  {errors.categoryId}
                                </p>
                              )}
                            </div>
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <div className="action">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      Save Page
                    </button>
                  </div>
                </SaveBar>

                <EditorContainer>
                  <div>
                    <label className="label">Documentation</label>
                    {errors.json && touched.json && (
                      <span className="error">{errors.json}</span>
                    )}
                  </div>
                  <Field
                    key={`editor${selectedComponentId}`}
                    name="json"
                    component={CustomPageEditor}
                  />
                </EditorContainer>
              </form>
            );
          }}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default NewPage;
