import { fontToId, idToFont, isProjectFont } from "./fonts";
import { isProjectColor, colorToId, idToColor } from "./colors";

const jsonToCss = (json, fonts, colors) => {
  const keys = Object.keys(json);
  let css = "";
  if (keys.length > 0) {
    keys.forEach(key => {
      let value = json[key];

      // Replace fonts
      if (key == "font-family") {
        if (isProjectFont(value)) {
          const fontId = fontToId(value);
          const font = fonts.find(f => f.id == fontId);
          if (font) {
            value = font.value;
          }
        }
      }

      // Replace colors
      if (
        key == "color" ||
        key == "background-color" ||
        key == "border-color" ||
        key == "border-top-color" ||
        key == "border-left-color" ||
        key == "border-right-color" ||
        key == "border-bottom-color"
      ) {
        if (isProjectColor(value)) {
          const colorId = colorToId(value);
          const color = colors.find(c => c.id == colorId);
          if (color) {
            value = color.value;
          }
        }
      }

      css += `${key}: ${value};\n`;
    });
  }
  return css;
};

export default jsonToCss;
