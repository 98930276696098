import React from "react";

const Input = ({
  className,
  component,
  style,
  onClick,
  onDoubleClick,
  onMouseOver,
  onMouseOut,
  layerRef
}) => {
  const htmlTagAttributes = component.htmlTagAttributes || {};
  const type = htmlTagAttributes["type"] || "text";
  const placeholder = htmlTagAttributes["placeholder"] || null;

  return (
    <input
      ref={layerRef}
      type={type}
      placeholder={placeholder}
      className={className}
      style={style}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
    />
  );
};

export default Input;
