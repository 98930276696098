import React from "react";

import classnames from "classnames";

import EditorGroup from "../../../../common/Editors/EditorGroup";

import styled from "styled-components";

const SymbolState = styled.div`
  text-align: center;
  background-color: #2b2b2b;
  color: #ccc;
  padding: 25px;

  i {
    font-size: 32px;
    margin-bottom: 15px;
  }

  .notice {
    font-size: 14px;
    line-height: 20px;
  }

  .action {
    margin-top: 15px;
  }
`;

const Variations = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 5px;

  a {
    flex: 1;
    margin: 5px;
  }
`;

const SymbolEditor = ({
  setActiveComponentId,
  setSymbolId,
  setSymbolShadowId,
  setDropComponentId,
  activeComponent,
  childComponents,
  updateComponent
}) => {
  const symbolComponent = childComponents.find(
    c => c.id == activeComponent.externalComponentId
  );

  const setVariation = async variationId => {
    await updateComponent({
      variables: {
        id: activeComponent.id,
        externalVariationId: variationId
      }
    });
  };

  return (
    <div>
      <div style={{ padding: "15px 25px" }}>
        <SymbolState>
          <i className="fas fa-cube"></i>

          <div className="notice">
            This is a Symbol
            <br />
            Double click to edit it
          </div>

          <div className="action">
            <a
              className="btn btn-primary"
              onClick={() => {
                setSymbolId(activeComponent.external.id);
                setSymbolShadowId(activeComponent.id);
                setActiveComponentId(activeComponent.external.id);
                setDropComponentId(null);
              }}
            >
              Edit This
            </a>
          </div>
        </SymbolState>
      </div>

      <EditorGroup title="Choose Variation">
        <Variations>
          <a
            className={classnames({
              btn: true,
              "btn-dark": !!activeComponent.externalVariationId,
              "btn-primary": !activeComponent.externalVariationId
            })}
            onClick={() => setVariation(null)}
          >
            {symbolComponent.selector}
          </a>
          {symbolComponent.variations.map(v => {
            return (
              <a
                className={classnames({
                  btn: true,
                  "btn-dark": activeComponent.externalVariationId != v.id,
                  "btn-primary": activeComponent.externalVariationId == v.id
                })}
                key={`externalvariation${v.id}`}
                onClick={() => setVariation(v.id)}
              >
                {v.selector}
              </a>
            );
          })}
        </Variations>
      </EditorGroup>
    </div>
  );
};

export default SymbolEditor;
