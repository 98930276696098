import React, { useState } from "react";

import Loader from "../../../components/common/Loader";
import Modal from "../../common/Modal";
import Color from "./Color";

import { CompactPicker } from "react-color";
import { Formik, Field } from "formik";
import { useQuery, useMutation } from "@apollo/client";

import {
  GET_COLORS,
  CREATE_COLOR,
  UPDATE_COLOR,
  DELETE_COLOR
} from "./queries";

const Colors = ({ projectId }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editColor, setEditColor] = useState({});
  const [createColor] = useMutation(CREATE_COLOR);
  const [updateColor] = useMutation(UPDATE_COLOR);
  const [deleteColor] = useMutation(DELETE_COLOR);

  const { loading, error, data, refetch } = useQuery(GET_COLORS, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const onEditColor = color => {
    setEditColor(color);
    setModalIsOpen(true);
  };

  const onDeleteColor = async colorId => {
    if (window.confirm("Are you sure?")) {
      await deleteColor({
        variables: {
          id: colorId
        }
      });
      refetch();
    }
  };

  return (
    <div>
      <div className="settings-header">
        <div className="title">Colors</div>
        <div className="action">
          <a
            className="btn btn-primary"
            onClick={() => {
              setEditColor({});
              setModalIsOpen(true);
            }}
          >
            New Color
          </a>
        </div>
      </div>

      {data && data.colors.length == 0 && (
        <div
          style={{
            padding: 20,
            textAlign: "center",
            color: "#999",
            fontWeight: "bold"
          }}
        >
          No Colors Found
        </div>
      )}

      {data && data.colors.length > 0 && (
        <div className="list">
          {data &&
            data.colors.map(c => {
              return (
                <Color
                  key={`color${c.id}`}
                  id={c.id}
                  name={c.name}
                  value={c.value}
                  onEditColor={onEditColor}
                  onDeleteColor={onDeleteColor}
                />
              );
            })}
        </div>
      )}

      <Modal isOpen={modalIsOpen} closeModal={() => setModalIsOpen(false)}>
        <Formik
          initialValues={{
            name: "",
            value: "",
            ...editColor
          }}
          validate={values => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            if (!values.value) {
              errors.value = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting }) => {
            if (editColor && editColor.id) {
              await updateColor({
                variables: {
                  id: parseInt(editColor.id),
                  ...values
                }
              });
            } else {
              await createColor({
                variables: {
                  projectId,
                  ...values
                }
              });
            }

            refetch();
            setSubmitting(false);
            setModalIsOpen(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="modal-top">
                <div className="modal-title">
                  <h3 className="headline">
                    {editColor.id ? "Edit" : "New"} Color
                  </h3>
                </div>
                <div className="modal-body">
                  <div className="form-group">
                    <label className="label">Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <p className="hint error">{errors.name}</p>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="label">Color</label>
                    <Field name="value">
                      {({
                        field, // { name, value, onChange, onBlur }
                        form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                        meta
                      }) => (
                        <div>
                          <CompactPicker
                            color={field.value}
                            onChangeComplete={color => {
                              setFieldValue("value", color.hex);
                            }}
                          />
                          {meta.touched && meta.error && (
                            <p className="hint error">{meta.error}</p>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button className="btn" onClick={() => setModalIsOpen(false)}>
                  Cancel
                </button>{" "}
                <button
                  className="btn btn-primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
    </div>
  );
};

export default Colors;
