import React, { useState, useEffect, useRef } from "react";

import classnames from "classnames";

import Select from "react-select";

import {
  EditorContainer,
  SizeFieldContainer,
  UnitDropdownContainer
} from "../styles";

import styled from "styled-components";

const SpacingContainer = styled.div`
  display: flex;
  flex: 1;
  padding: 0px 5px;
  position: relative;

  .label {
    position: absolute;
    left: 5px;
    top: 2px;
    z-index: 4;
    color: #999;
    font-size: 10px;
    text-transform: uppercase;
  }

  .value {
    flex: 1;
    text-align: center;
    font-size: 11px;

    span {
      padding: 2px 5px;
    }

    &.hasValue {
      span {
        color: rgb(250, 181, 134);
        background-color: rgba(240, 139, 72, 0.15);
      }
    }

    &.changed {
      span {
        color: rgb(139, 190, 250);
        background-color: rgba(76, 152, 241, 0.15);
      }
    }
  }
`;

const MarginContainer = styled.div`
  flex: 1;
  height: 130px;
  position: relative;
  border: 1px solid rgb(43, 43, 43);

  .margin-left,
  .margin-right,
  .margin-top,
  .margin-bottom {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      z-index: 3;
      background-color: #444;
    }
  }

  .margin-left,
  .margin-right {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #333;
    width: 40px;
    z-index: 2;
  }

  .margin-left {
    left: 0;
  }

  .margin-right {
    right: 0;
  }

  .margin-top,
  .margin-bottom {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #333;
    height: 25px;
    z-index: 1;
  }

  .margin-top {
    top: 0;
  }

  .margin-bottom {
    bottom: 0;
  }
`;

const PaddingContainer = styled.div`
  position: absolute;
  top: 30px;
  bottom: 30px;
  left: 45px;
  right: 45px;

  .padding-left,
  .padding-right,
  .padding-top,
  .padding-bottom {
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      z-index: 3;
      background-color: #444;
    }
  }

  .padding-left,
  .padding-right {
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #333;
    width: 40px;
    z-index: 2;
  }

  .padding-left {
    left: 0;
  }

  .padding-right {
    right: 0;
  }

  .padding-top,
  .padding-bottom {
    position: absolute;
    left: 0;
    right: 0;
    background-color: #333;
    height: 25px;
    z-index: 1;
  }

  .padding-top {
    top: 0;
  }

  .padding-bottom {
    bottom: 0;
  }
`;

const ActiveEditor = styled.div`
  position: absolute;
  left: 5px;
  top: 0;
  right: 5px;
  bottom: 0;
  z-index: 5;
  background: rgba(255, 255, 255, 0.7);
  display: flex;

  .editor-container {
    flex: 1;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .value-editor-container {
    flex: 1;

    label {
      color: #444;
      font-size: 11px;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`;

const ValueEditor = ({ name, value, onChange, isChanged, hasValue }) => {
  const input = useRef(null);

  const marginValues = [
    { label: "px", value: "px" },
    { label: "%", value: "%" },
    { label: "auto", value: "auto" }
  ];
  const paddingValues = [
    { label: "px", value: "px" },
    { label: "%", value: "%" }
  ];
  const values = name.indexOf("margin") > -1 ? marginValues : paddingValues;

  const unit = value ? value.replace(/[0-9]/g, "") : "px";
  const intValue = value ? value.replace(/\D/g, "") : null;

  const activeUnit = values.find(v => v.value == unit);

  useEffect(() => {
    if (input && input.current) {
      input.current.focus();
    }
  }, []);

  return (
    <div className="value-editor-container">
      <label>{name}</label>
      <SizeFieldContainer>
        {unit != "auto" && (
          <input
            ref={input}
            type="text"
            defaultValue={intValue}
            placeholder="0"
            onClick={e => {
              e.stopPropagation();
            }}
            onChange={e =>
              onChange(name, e.target.value ? `${e.target.value}${unit}` : null)
            }
          />
        )}

        <UnitDropdownContainer
          full={unit == "auto"}
          long={name.indexOf("margin") > -1}
          onClick={e => e.stopPropagation()}
        >
          <Select
            defaultValue={activeUnit}
            isClearable={false}
            isSearchable={false}
            name={name}
            onChange={data =>
              onChange(
                name,
                data.value == "auto" ? "auto" : `${intValue}${data.value}`
              )
            }
            options={values}
            classNamePrefix="react-select"
          />
        </UnitDropdownContainer>
      </SizeFieldContainer>
    </div>
  );
};

const PropertyEditor = ({
  name,
  values,
  setActiveStyle,
  isChanged,
  hasValue
}) => {
  const value = values[name];
  const unit = value ? value.replace(/[0-9]/g, "") : "px";
  const intValue = value ? value.replace(/\D/g, "") || 0 : 0;

  let label = unit == "auto" ? "auto" : intValue;

  return (
    <div className={name} onClick={() => setActiveStyle(name)}>
      <div
        className={classnames({
          value: true,
          changed: isChanged(name),
          hasValue: hasValue(name)
        })}
      >
        <span>{label}</span>
      </div>
    </div>
  );
};

const Editor = ({ style, onChange, project }) => {
  const [activeStyle, setActiveStyle] = useState(null);

  const { active, inherited } = style;

  const values = {
    ...inherited,
    ...active
  };

  const isChanged = name => !!active[name];
  const hasValue = name => !!values[name];

  const propertyProps = {
    setActiveStyle,
    isChanged,
    hasValue,
    values
  };

  return (
    <EditorContainer>
      <SpacingContainer>
        {!!activeStyle && (
          <ActiveEditor>
            <div
              className="editor-container"
              onClick={e => {
                e.stopPropagation();
                setActiveStyle(null);
              }}
            >
              <ValueEditor
                name={activeStyle}
                value={values[activeStyle]}
                onChange={onChange}
                isChanged={isChanged(activeStyle)}
                hasValue={hasValue(activeStyle)}
              />
            </div>
          </ActiveEditor>
        )}
        <MarginContainer>
          <div className="label">Margin</div>
          <PropertyEditor {...propertyProps} name="margin-top" />
          <PropertyEditor {...propertyProps} name="margin-left" />
          <PaddingContainer>
            <div className="label">Padding</div>
            <PropertyEditor {...propertyProps} name="padding-top" />
            <PropertyEditor {...propertyProps} name="padding-left" />
            <PropertyEditor {...propertyProps} name="padding-right" />
            <PropertyEditor {...propertyProps} name="padding-bottom" />
          </PaddingContainer>
          <PropertyEditor {...propertyProps} name="margin-right" />
          <PropertyEditor {...propertyProps} name="margin-bottom" />
        </MarginContainer>
      </SpacingContainer>
    </EditorContainer>
  );
};
export default Editor;
