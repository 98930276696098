import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CreatableSelect from "react-select/creatable";

import {
  EditorContainer,
  EditorTitle,
  DropdownContainer,
  TextFieldContainer
} from "../../../../common/Editors/styles";

import {
  isProjectUtilityClass,
  utilityClassToId
} from "../../../../common/utils";

import { gql, useQuery } from "@apollo/client";

export const UTILITIES_QUERY = gql`
  query getUtilities($projectId: Int!) {
    utilities(projectId: $projectId) {
      id
      selector
    }
  }
`;

const Container = styled.div`
  padding: 10px;
`;

const CssClasses = ({
  projectId,
  activeComponent,
  updateComponent,
  refetchRootComponent,
  refetchChildComponents
}) => {
  const [cssClasses, setCssClasses] = useState([]);
  const [selector, setSelector] = useState(activeComponent.selector);

  const { loading, error, data, refetch } = useQuery(UTILITIES_QUERY, {
    variables: { projectId: parseInt(projectId) }
  });

  useEffect(() => {
    setCssClasses(activeComponent.customCssClasses);
  }, [activeComponent.id]);

  useEffect(() => {
    setSelector(activeComponent.selector);
  }, [activeComponent.selector]);

  if (loading) return <div>Loading</div>;
  if (error) return <div>Error</div>;

  const styles = {
    multiValueRemove: (base, state) => {
      return state.data.value == activeComponent.selector
        ? { ...base, display: "none" }
        : base;
    }
  };

  return (
    <>
      <EditorContainer>
        <EditorTitle>Main</EditorTitle>
        <TextFieldContainer>
          <input
            type="text"
            defaultValue={selector}
            onChange={async e => {
              const newSelector = e.target.value.trim();
              setSelector(newSelector);
              await updateComponent({
                variables: {
                  id: activeComponent.id,
                  selector: newSelector
                }
              });
              await refetchRootComponent();
              await refetchChildComponents();
            }}
          />
        </TextFieldContainer>
      </EditorContainer>

      <EditorContainer>
        <EditorTitle>Classes</EditorTitle>
        <DropdownContainer>
          <CreatableSelect
            isMulti
            classNamePrefix="react-select"
            styles={styles}
            value={cssClasses.map(cssClass => {
              let label = cssClass;
              if (isProjectUtilityClass(cssClass)) {
                let utilityClassId = utilityClassToId(cssClass);
                let utilityClass = data.utilities.find(
                  u => u.id == utilityClassId
                );
                label = utilityClass.selector;
              }

              return {
                value: cssClass,
                label: label
              };
            })}
            onChange={async data => {
              const newClasses = data
                ? data
                    .map(cssClass => {
                      return cssClass.value;
                    })
                    .filter(cssClass => cssClass != activeComponent.selector)
                : [];
              setCssClasses(newClasses);
              await updateComponent({
                variables: {
                  id: activeComponent.id,
                  customCssClasses: newClasses
                }
              });
            }}
            options={data.utilities.map(u => {
              return {
                value: `[utility id=${u.id}]`,
                label: u.selector
              };
            })}
          />
        </DropdownContainer>
      </EditorContainer>
    </>
  );
};
export default CssClasses;
