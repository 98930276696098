import React from "react";
import { Link, NavLink, Switch, Route, useRouteMatch } from "react-router-dom";

import General from "./General";
import Fonts from "./Fonts";
import Colors from "./Colors";
import Images from "./Images";
import Dependencies from "./Dependencies";
import Variables from "./Variables";
import Reset from "./Reset";
import Team from "./Team";

import styled from "styled-components";

const Container = styled.div`
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 3px;
  padding: 25px 25px 50px;

  .settings-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding: 20px 0px;
    margin-bottom: 20px;

    .title {
      font-size: 18px;
      flex: 1;
    }

    .action {
      flex: 1;
      text-align: right;
    }
  }
`;

const Tabs = styled.div`
  margin-top: 25px;

  .settings-link {
    display: inline-block;
    color: #999;
    text-decoration: none;
    padding: 10px 15px;

    &:hover {
      color: #333;
    }

    &.active {
      color: #333;
      border-bottom: 2px solid #999;
    }
  }
`;

const Settings = props => {
  let { path, url } = useRouteMatch();

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb active">Settings</h1>
          </div>

          <div className="actions"></div>
        </div>
      </div>

      <div className="container">
        <Tabs>
          <NavLink
            exact
            to={"/settings"}
            className="settings-link"
            activeClassName="active"
          >
            General
          </NavLink>
          <NavLink
            to={`${path}/team`}
            className="settings-link"
            activeClassName="active"
          >
            Members
          </NavLink>
          <NavLink
            to={`${path}/fonts`}
            className="settings-link"
            activeClassName="active"
          >
            Fonts
          </NavLink>
          <NavLink
            to={`${path}/colors`}
            className="settings-link"
            activeClassName="active"
          >
            Colors
          </NavLink>
          <NavLink
            to={`${path}/images`}
            className="settings-link"
            activeClassName="active"
          >
            Images
          </NavLink>
          <NavLink
            to={`${path}/dependencies`}
            className="settings-link"
            activeClassName="active"
          >
            Dependencies
          </NavLink>
          <NavLink
            to={`${path}/variables`}
            className="settings-link"
            activeClassName="active"
          >
            Variables
          </NavLink>
          <NavLink
            to={`${path}/reset`}
            className="settings-link"
            activeClassName="active"
          >
            Reset
          </NavLink>
        </Tabs>

        <Container>
          <Switch>
            <Route exact path={`${path}/colors`}>
              <Colors {...props} />
            </Route>
            <Route exact path={`${path}/fonts`}>
              <Fonts {...props} />
            </Route>
            <Route exact path={`${path}/images`}>
              <Images {...props} />
            </Route>
            <Route exact path={`${path}/dependencies`}>
              <Dependencies {...props} />
            </Route>
            <Route exact path={`${path}/variables`}>
              <Variables {...props} />
            </Route>
            <Route exact path={`${path}/reset`}>
              <Reset {...props} />
            </Route>
            <Route exact path={`${path}/team`}>
              <Team {...props} />
            </Route>
            <Route exact path={path}>
              <General {...props} />
            </Route>
          </Switch>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Settings;
