import React, { useEffect } from "react";
import styled from "styled-components";

const blueColor = "#178df7";
const greenColor = "#27d86e";

const LayerTitle = styled.div`
  position: absolute;
  background-color: ${props => (props.isSymbol ? greenColor : blueColor)};
  color: #fff;
  font-size: 12px;
  padding: 5px;
  z-index: 100;

  span {
    cursor: move;
  }
`;

const LayerOutline = ({
  iframeRef,
  layerRef,
  component,
  rootComponent,
  hover,
  active,
  symbolId
}) => {
  const domComponent = layerRef ? layerRef.current : null;
  const iframeEl =
    iframeRef && iframeRef.current ? iframeRef.current.node : null;

  if (!domComponent || !iframeEl) return null;

  const iframeRect = iframeEl.getBoundingClientRect();
  const rect = domComponent.getBoundingClientRect();

  const outlineWidth = component.id == rootComponent.id ? 4 : 2;

  const fromTop = iframeRect.top + rect.top;
  const fromLeft = iframeRect.left + rect.left;

  const isExternal = component && !!component.externalComponentId;
  const isSymbol = isExternal && !!component.external.containerComponentId;
  const isActiveSymbol = symbolId == component.id;

  const backgroundColor = hover ? blueColor : isSymbol ? greenColor : blueColor;

  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          width: outlineWidth,
          height: rect.height,
          top: fromTop,
          left: fromLeft,
          backgroundColor,
          pointerEvents: "none"
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          width: outlineWidth,
          height: rect.height,
          top: fromTop,
          left: fromLeft + rect.width - outlineWidth,
          backgroundColor,
          pointerEvents: "none"
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          width: rect.width,
          height: outlineWidth,
          top: fromTop,
          left: fromLeft,
          backgroundColor,
          pointerEvents: "none"
        }}
      ></div>

      <div
        style={{
          position: "absolute",
          width: rect.width,
          height: outlineWidth,
          top: fromTop + rect.height - outlineWidth,
          left: fromLeft,
          backgroundColor,
          pointerEvents: "none"
        }}
      ></div>

      {isSymbol && (
        <div
          style={{
            position: "absolute",
            top: fromTop,
            left: fromLeft,
            width: rect.width,
            height: rect.height,
            backgroundColor: "rgba(0,255,0,0.2)",
            pointerEvents: "none"
          }}
        ></div>
      )}

      <LayerTitle
        active={active}
        isSymbol={isSymbol}
        style={{
          top: fromTop - 25,
          left: fromLeft
        }}
      >
        {isExternal
          ? `${component.external.htmlTag}${component.external.selector}`
          : `${component.htmlTag}${component.selector}`}
      </LayerTitle>
    </React.Fragment>
  );
};

export default LayerOutline;
