import React, { useEffect, useRef, useState, useCallback } from "react";
import { useLazyQuery } from "@apollo/client";

import Layer from "./Preview/Layer";
import ExternalLayer from "./Preview/ExternalLayer";
import SymbolLayer from "./Preview/SymbolLayer";

import Tabs from "./Tabs";
import Styles from "./Styles";

import CreateNew from "./CreateNew";
import Selectors from "./Selectors";

import Canvas from "./Canvas";

import styled from "styled-components";
import { isEqual } from "lodash";

const Container = styled.div`
  display: flex;
  height: calc(100vh - 140px);
  background-color: #282828;
`;

const Preview = styled.div`
  flex: 1;
  padding: 20px 40px;
`;

let childComponentsCache = [];
let cacheVersion = 1;
let layerRefs = {};
let dragRefs = {};

const Component = props => {
  const {
    getChildComponents,
    rootComponent,
    childComponents,
    activeComponent,
    activeComponentId,
    hoverComponent,
    hoverComponentId,
    dropComponent,
    dropComponentId,
    symbolId,
    symbolShadowId,
    activeVariationId,
    createModalIsOpen
  } = props;
  const [renderCount, updateRenderCount] = useState(0);
  const forceUpdate = () => {
    updateRenderCount(renderCount + 1);
  };

  const iframeRef = useRef(null);

  layerRefs[rootComponent.id] = useRef(null);

  useEffect(() => {
    forceUpdate();
  }, [activeComponentId]);

  useEffect(() => {
    childComponentsCache = childComponents;
    childComponents.forEach(c => {
      if (!layerRefs[c.id]) layerRefs[c.id] = React.createRef();
      if (!dragRefs[c.id]) dragRefs[c.id] = React.createRef();
    });
    forceUpdate();
  }, [cacheVersion]);

  if (!isEqual(childComponents, childComponentsCache)) {
    childComponentsCache = childComponents;
    cacheVersion++;
  }

  const renderComponent = component => {
    const isExternal = !!component.externalComponentId;
    const isSymbol = isExternal && !!component.external.containerComponentId;
    const isActiveSymbol = component.id == symbolShadowId;

    const childComponents = getChildComponents(
      isActiveSymbol ? symbolId : component.id
    );

    let Cursor = Layer;
    let componentId = component.id;
    let layerComponent = component;
    let variationId = activeVariationId;
    if (isActiveSymbol) {
      componentId = symbolId;
      layerComponent = component.external;
    } else if (isSymbol) {
      Cursor = SymbolLayer;
    } else if (isExternal) {
      Cursor = ExternalLayer;
    }

    return (
      <Cursor
        layerRef={layerRefs[componentId]}
        dragRef={dragRefs[componentId]}
        key={`component${componentId}`}
        component={layerComponent}
        variationId={variationId}
        {...childProps}
      >
        {childComponents.length > 0 &&
          childComponents.map(c => renderComponent(c))}
        {childComponents.length == 0 && layerComponent.textContent}
      </Cursor>
    );
  };

  const childProps = {
    ...props,
    iframeRef,
    layerRefs,
    forceUpdate
  };

  return (
    <Container>
      <Tabs {...childProps} />

      <Preview>
        <Selectors {...childProps} key={`selectors${activeVariationId}`} />

        <Canvas {...childProps}>{renderComponent(rootComponent)}</Canvas>
      </Preview>

      <Styles {...childProps} />

      {createModalIsOpen && <CreateNew {...childProps} />}
    </Container>
  );
};

export default Component;
