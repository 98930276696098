import React from "react";
import Tag from "./Tag";
import TagGroup from "./TagGroup";

import Loader from "../../../common/Loader";

import { useQuery } from "@apollo/client";

import { ROOT_COMPONENTS_QUERY } from "../../queries";

const Tags = props => {
  const { projectId, rootComponent, allChildComponents, symbolId } = props;
  const { loading, error, data } = useQuery(ROOT_COMPONENTS_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      projectId: parseInt(projectId)
    }
  });

  if (loading) return <Loader />;
  if (error) return <div>Error</div>;

  const components = data.rootComponents
    .filter(c => c.id != rootComponent.id) // Exclude self
    .filter(c => !c.containerComponentId); // Exclude symbols

  const symbols = allChildComponents
    .filter(
      c =>
        !!c.containerComponentId && c.containerComponentId == rootComponent.id // Exclude components that have a container and its not this one (symbols for other components)
    )
    .filter(c => c.id != symbolId); // symbol cannot be inserted in itself

  return (
    <div style={{ marginTop: 0 }}>
      {symbols.length > 0 && (
        <TagGroup title="Symbols">
          {symbols.map(c => (
            <Tag
              {...props}
              key={`tag${c.id}`}
              componentId={c.id}
              name={c.selector}
              htmlTag="div"
              iconClass="fas fa-cube"
            />
          ))}
        </TagGroup>
      )}

      <TagGroup title="Container">
        <Tag
          {...props}
          htmlTag="div"
          iconClass="fas fa-square-full"
          name="Div"
        />
        <Tag {...props} htmlTag="span" iconClass="far fa-square" name="Span" />
      </TagGroup>

      <TagGroup title="Typography">
        <Tag
          {...props}
          htmlTag="p"
          iconClass="fas fa-paragraph"
          name="Paragraph"
        />
        <Tag {...props} htmlTag="a" iconClass="fas fa-link" name="Link" />
        <Tag
          {...props}
          htmlTag="h1"
          iconClass="fas fa-heading"
          name="Heading"
        />
      </TagGroup>

      <TagGroup title="Media">
        <Tag {...props} htmlTag="img" iconClass="fas fa-image" name="Image" />
      </TagGroup>

      <TagGroup title="Form">
        <Tag {...props} htmlTag="form" iconClass="fab fa-wpforms" name="Form" />
        <Tag {...props} htmlTag="label" iconClass="fas fa-font" name="Label" />
        <Tag
          {...props}
          htmlTag="input"
          htmlTagAttributes={{ type: "text", placeholder: "Type text here..." }}
          iconClass="far fa-minus-square"
          name="Text Field"
        />
        <Tag
          {...props}
          htmlTag="textarea"
          htmlTagAttributes={{ placeholder: "Type text here..." }}
          iconClass="far fa-square"
          name="Text Area"
        />
        <Tag
          {...props}
          htmlTag="input"
          htmlTagAttributes={{ type: "radio" }}
          iconClass="fas fa-dot-circle"
          name="Radio"
        />
        <Tag
          {...props}
          htmlTag="input"
          htmlTagAttributes={{ type: "checkbox" }}
          iconClass="fas fa-check-square"
          name="Checkbox"
        />
        <Tag
          {...props}
          htmlTag="select"
          iconClass="fas fa-chevron-square-down"
          name="Select"
        />
        <Tag
          {...props}
          htmlTag="option"
          iconClass="fas fa-list-alt"
          name="Option"
        />
      </TagGroup>

      <TagGroup title="List">
        <Tag {...props} htmlTag="ul" iconClass="fas fa-list" name="Unordered" />
        <Tag
          {...props}
          htmlTag="ol"
          iconClass="fas fa-list-ol"
          name="Ordered"
        />
        <Tag
          {...props}
          htmlTag="li"
          iconClass="fas fa-horizontal-rule"
          name="List Item"
        />
      </TagGroup>

      <TagGroup title="Table">
        <Tag {...props} htmlTag="table" iconClass="fal fa-table" name="Table" />
        <Tag {...props} htmlTag="tr" iconClass="fal fa-th-large" name="Row" />
        <Tag {...props} htmlTag="td" iconClass="far fa-square" name="Cell" />
      </TagGroup>

      {components.length > 0 && (
        <TagGroup title="Components">
          {components.map(c => (
            <Tag
              {...props}
              key={`tag${c.id}`}
              componentId={c.id}
              name={c.selector}
              htmlTag="div"
            />
          ))}
        </TagGroup>
      )}
    </div>
  );
};
export default Tags;
