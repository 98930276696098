import React from "react";
import { Redirect, Switch, Route, useRouteMatch } from "react-router-dom";

import Components from "./Components";
import Component from "./Component";
import NewComponent from "./NewComponent";
import Utilities from "./Utilities";

const ComponentsContainer = props => {
  let { path, url } = useRouteMatch();

  const childProps = {
    ...props
  };

  return (
    <Switch>
      <Route path={`${path}/utilities`}>
        <Utilities {...props} />
      </Route>
      <Route exact path={`${path}/new`}>
        <NewComponent {...childProps} />
      </Route>
      <Route exact path={`${path}/:componentId`}>
        <Component {...childProps} />
      </Route>
      <Route exact path={path}>
        <Components {...childProps} />
      </Route>
    </Switch>
  );
};

export default ComponentsContainer;
