import React from "react";

const Dependency = ({
  id,
  name,
  url,
  type,
  position,
  onEditDependency,
  onDeleteDependency
}) => {
  return (
    <div className="list-item">
      <div className="list-info">
        <div className="title">{name}</div>
        <div className="description">{url}</div>
      </div>

      <div className="actions">
        <a
          className="action-item"
          onClick={() => onEditDependency({ id, name, position, type, url })}
        >
          <i className="action-icon fas fa-edit"></i>
        </a>
        <a className="action-item" onClick={() => onDeleteDependency(id)}>
          <i className="action-icon fas fa-trash"></i>
        </a>
      </div>
    </div>
  );
};

export default Dependency;
