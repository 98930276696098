import React, { useState } from "react";
import styled from "styled-components";

import Select from "react-select";

const Container = styled.div``;

const Component = ({ activeComponentState, setActiveComponentState }) => {
  const values = [
    { label: ":hover", value: "hover" },
    { label: ":active", value: "active" },
    { label: ":visited", value: "visited" },
    { label: ":focus", value: "focus" },
    { label: ":first-child", value: "first-child" },
    { label: ":last-child", value: "last-child" }
  ];

  return (
    <Container>
      <Select
        value={
          activeComponentState
            ? values.find(w => w.value == activeComponentState)
            : ""
        }
        isClearable={true}
        isSearchable={true}
        onChange={data => setActiveComponentState(data ? data.value : "")}
        options={values}
        classNamePrefix="react-select"
      />
    </Container>
  );
};
export default Component;
