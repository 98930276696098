import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
  isProjectColor,
  colorToId,
  idToColor,
  isRgb,
  hexToRgb,
  colorToRGBA
} from "../../utils";

import classnames from "classnames";

import {
  EditorContainer,
  EditorTitle,
  DropdownContainer,
  ColorPickerContainer
} from "../styles";

import SketchPicker from "react-color/lib/Sketch";
import Trigger from "rc-trigger";

const PaletteContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px 10px;
  justify-content: flex-start;

  .color {
    margin: 4px;
    height: 25px;
    border-right: 0px;
    border: 1px solid #ccc;
  }
`;

const Color = styled.div`
  flex: 1;
  height: 100%;
  max-width: 28px;
  min-width: 28px;
  background-color: ${props => props.color};
  border-right: 2px solid #666;
  cursor: pointer;

  &.transparent {
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
      linear-gradient(-45deg, #eee 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #eee 75%),
      linear-gradient(-45deg, transparent 75%, #eee 75%);
  }
`;

const ColorPickerPopover = styled.div`
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.15) 0px 8px 16px;

  .sketch-picker {
    box-shadow: none !important;
  }

  label {
    color: #999;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    padding: 0px 10px;
    margin-bottom: 0px;
  }
`;

const ColorReset = styled.div`
  flex: 1;
  height: 25px;
  max-width: 25px;
  min-width: 25px;
  background-color: #ccc;
  color: #ff0000;
  margin: 4px;
  border: 1px solid #ccc;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;

  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
  background-image: linear-gradient(45deg, #eee 25%, transparent 25%),
    linear-gradient(-45deg, #eee 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #eee 75%),
    linear-gradient(-45deg, transparent 75%, #eee 75%);

  i {
    flex: 1;
  }
`;

const Editor = ({
  name,
  title,
  value,
  onChange,
  isChanged,
  hasValue,
  project
}) => {
  const debounceTimer = useRef();

  const { colors } = project;

  let currentColor = value;
  const isTransparent = !currentColor;

  const [activeColor, setActiveColor] = useState(currentColor);

  useEffect(() => {
    if (activeColor == currentColor) return;
    if (debounceTimer.current) clearTimeout(debounceTimer.current);
    debounceTimer.current = setTimeout(() => {
      onChange(name, activeColor);
    }, 100);
  }, [activeColor]);

  let colorPickerValue = activeColor;
  let displayColorValue = activeColor;
  if (colorPickerValue) {
    if (isProjectColor(activeColor)) {
      const colorId = colorToId(activeColor);
      const color = colors.find(c => c.id == colorId);
      if (color) {
        colorPickerValue = color.value;
        displayColorValue = color.name;
      }
    } else {
      if (!isRgb(colorPickerValue)) {
        colorPickerValue = colorToRGBA(colorPickerValue);
      }
      displayColorValue = colorPickerValue;
    }
  }

  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>

      <DropdownContainer>
        <ColorPickerContainer>
          <Trigger
            action={["click"]}
            popup={
              <ColorPickerPopover>
                <SketchPicker
                  color={colorPickerValue}
                  presetColors={[]}
                  onChange={color => {
                    const rgba = color.rgb;
                    const newColor = `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
                    setActiveColor(newColor);
                  }}
                />

                <hr />
                {colors.length > 0 && (
                  <React.Fragment>
                    <label>Saved Colors</label>
                    <PaletteContainer>
                      <ColorReset onClick={() => setActiveColor("")}>
                        <i className="fas fa-slash"></i>
                      </ColorReset>
                      {colors.map(c => {
                        return (
                          <Color
                            className="color"
                            key={`color${name}${c.id}`}
                            color={c.value || "#CCC"}
                            onClick={() => setActiveColor(idToColor(c.id))}
                          ></Color>
                        );
                      })}
                    </PaletteContainer>
                  </React.Fragment>
                )}
              </ColorPickerPopover>
            }
            popupStyle={{
              width: 220
            }}
            zIndex={120}
            popupPlacement="left"
            maskClosable={true}
            destroyPopupOnHide={true}
            builtinPlacements={{
              left: {
                points: ["tr", "tl"]
              },
              right: {
                points: ["cl", "cr"]
              }
            }}
            popupAlign={{
              offset: [0, 3],
              overflow: { adjustX: 1, adjustY: 1 }
            }}
          >
            <div style={{ display: "flex", height: "100%", width: "100%" }}>
              <Color
                color={colorPickerValue || "#CCC"}
                className={isTransparent ? "transparent" : ""}
              />
              <input
                type="text"
                value={displayColorValue}
                readOnly
                onChange={e => {
                  const newColor = e.target.value.trim();
                  setActiveColor(newColor);
                }}
              />
            </div>
          </Trigger>
          {false && (
            <ColorReset onClick={() => onChange(name, "")}>
              <i className="fas fa-slash"></i>
            </ColorReset>
          )}
        </ColorPickerContainer>
      </DropdownContainer>
    </EditorContainer>
  );
};
export default Editor;
