export const isProjectUtilityClass = str => {
  if (!str) return false;
  return !!str.match(/\[utility\sid=(\d+)\]/);
};

export const utilityClassToId = str => {
  if (!str) return null;
  return parseInt(str.match(/\[utility\sid=(\d+)\]/)[1]);
};

export const idToUtilityClass = id => {
  return `[utility id=${id}]`;
};
