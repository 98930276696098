import Text from "./Text";
import FontFamily from "./FontFamily";
import FontWeight from "./FontWeight";
import ColorPicker from "./ColorPicker";
import Display from "./Display";
import Align from "./Align";
import Size from "./Size";
import Decoration from "./Decoration";
import Overflow from "./Overflow";
import Position from "./Position";
import Cursor from "./Cursor";
import Spacing from "./Spacing";
import TextTransform from "./TextTransform";
import Border from "./Border";
import BorderRadius from "./BorderRadius";

export default {
  text: Text,
  font_family: FontFamily,
  font_weight: FontWeight,
  color: ColorPicker,
  display: Display,
  align: Align,
  size: Size,
  decoration: Decoration,
  overflow: Overflow,
  position: Position,
  cursor: Cursor,
  spacing: Spacing,
  text_transform: TextTransform,
  border: Border,
  border_radius: BorderRadius
};
