import React, { useState } from "react";
import styled from "styled-components";

import Select from "react-select";

const Container = styled.div``;

const Component = ({ activeDevice, setActiveDevice, setHideLayerOutlines }) => {
  const options = [
    {
      label: "Desktop",
      value: "desktop"
    },
    {
      label: "Mobile",
      value: "mobile"
    }
  ];

  return (
    <Container>
      <Select
        value={options.find(o => o.value == activeDevice)}
        isClearable={false}
        onChange={data => {
          setHideLayerOutlines(true);
          setActiveDevice(data.value);
          setTimeout(() => {
            setHideLayerOutlines(false);
          }, 1000);
        }}
        options={options}
        classNamePrefix="react-select"
      />
    </Container>
  );
};
export default Component;
