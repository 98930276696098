import styled from "styled-components";

export const SaveBar = styled.div`
  display: flex;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-top: 20px;

  label {
    color: #999;
    font-weight: bold;
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 2px !important;
    margin-right: 10px;
  }

  .error {
    color: #ad2d39;
    font-size: 12px;
    font-weight: bold;
  }

  > div {
    flex: 1;
    margin: 0px 15px;

    &:last-child {
      margin-right: 0px;
    }

    &:first-child {
      margin-left: 0px;
    }
  }

  .action {
    max-width: 100px;
    text-align: right;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    button {
      flex: 1;
    }
  }

  .react-select__control {
    font-size: 16px;
    min-height: 30px;
  }

  .react-select__menu {
    z-index: 10;
  }

  .react-select__option {
    padding: 10px;
  }

  .react-select__value-container {
    padding: 1px 8px;
  }

  .react-select__indicators > div {
    padding: 4px;
  }
`;
