import React from "react";

import classnames from "classnames";

import {
  EditorContainer,
  SquareButton,
  SquareButtonGroup,
  EditorTitle
} from "../styles";

const Editor = ({ name, title, value, onChange, isChanged, hasValue }) => {
  return (
    <EditorContainer>
      <EditorTitle className={classnames({ changed: isChanged, hasValue })}>
        <span>{title || name}</span>
      </EditorTitle>
      <SquareButtonGroup>
        <SquareButton
          onClick={() => onChange(name, "none")}
          className={classnames({ active: value == "none" })}
        >
          <i className="fas fa-times"></i>
        </SquareButton>

        <SquareButton
          onClick={() => onChange(name, "line-through")}
          className={classnames({ active: value == "line-through" })}
        >
          <i className="fas fa-strikethrough"></i>
        </SquareButton>

        <SquareButton
          onClick={() => onChange(name, "underline")}
          className={classnames({ active: value == "underline" })}
        >
          <i className="fas fa-underline"></i>
        </SquareButton>

        <SquareButton
          onClick={() => onChange(name, "overline")}
          className={classnames({ active: value == "overline" })}
        >
          <i className="fas fa-overline"></i>
        </SquareButton>
      </SquareButtonGroup>
    </EditorContainer>
  );
};
export default Editor;
