import React from "react";

import { Formik } from "formik";

import { useMutation } from "@apollo/client";

import { CREATE_PROJECT } from "./queries";

import Loader from "../common/Loader";

import styled from "styled-components";

const Container = styled.div`
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dbdbdb;

  .form {
    max-width: 500px;
  }
`;

const NewProject = ({ history }) => {
  const [createProject, { data }] = useMutation(CREATE_PROJECT);

  return (
    <React.Fragment>
      <div className="page-header">
        <div className="page-header-wrapper container">
          <div className="heading">
            <h1 className="breadcrumb">Projects /</h1>
            <h1 className="breadcrumb active">New Project</h1>
          </div>
        </div>
      </div>

      <Container className="container">
        <Formik
          initialValues={{ name: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.name) {
              errors.name = "Required";
            }
            return errors;
          }}
          onSubmit={async (values, { setSubmitting, setErrors }) => {
            const response = await createProject({ variables: values });
            setSubmitting(false);

            if (
              response &&
              response.data &&
              response.data.createProject &&
              response.data.createProject.success
            ) {
              const project = response.data.createProject.project;
              history.push(`/projects/${project.id}`);
              return;
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit} className="form">
              <div className="form-group">
                <label className="label">Project Name</label>

                <input
                  className="form-control"
                  type="name"
                  name="name"
                  placeholder="My UI Kit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
                {errors.name && touched.name && (
                  <p className="hint error">{errors.name}</p>
                )}
              </div>

              <button
                className={isSubmitting ? "btn btn-dark" : "btn btn-primary"}
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <i className="fas fa-spinner fa-spin"></i>
                ) : (
                  "Create Project"
                )}
              </button>
            </form>
          )}
        </Formik>
      </Container>
    </React.Fragment>
  );
};

export default NewProject;
