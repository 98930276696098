import React, { useRef, useEffect } from "react";

import { useMutation } from "@apollo/client";

import { UPDATE_PROJECT } from "./queries";

import "codemirror/mode/css/css";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/display/autorefresh";
import CodeMirror from "codemirror/lib/codemirror";

import styled from "styled-components";

const Container = styled.div`
  .CodeMirror {
    border: 1px solid #eee;
    height: auto;
  }

  .CodeMirror-scroll {
    min-height: 250px;
  }
`;

const Reset = ({ projectId, project }) => {
  const textarea = useRef(null);
  const [updateProject] = useMutation(UPDATE_PROJECT);

  useEffect(() => {
    let editor = CodeMirror.fromTextArea(textarea.current, {
      mode: { name: "css" },
      lineNumbers: true,
      extraKeys: { "Ctrl-Space": "autocomplete" },
      autoRefresh: true
    });

    editor.on("change", async cm => {
      await updateProject({
        variables: {
          id: project.id,
          resetCss: cm.getValue()
        }
      });
    });
  }, [project.id]);

  return (
    <Container>
      <div className="settings-header">
        <div className="title">Reset CSS</div>
        <div className="action">
          <a className="btn btn-primary">Save</a>
        </div>
      </div>

      <textarea
        ref={textarea}
        onChange={e => console.log(e.target.value)}
        defaultValue={project.resetCss}
      ></textarea>
    </Container>
  );
};

export default Reset;
