export const isProjectFont = str => {
  if (!str) return false;
  return !!str.match(/\[font\sid=(\d+)\]/);
};

export const fontToId = str => {
  if (!str) return null;
  return parseInt(str.match(/\[font\sid=(\d+)\]/)[1]);
};

export const idToFont = id => {
  return `[font id=${id}]`;
};
