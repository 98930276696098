import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getStyles, objectDiff } from "../../../../common/utils";

import StyleEditors from "../../../../common/Editors";

const Container = styled.div``;

const Editors = props => {
  const {
    rootComponent,
    activeComponent,
    activeComponentId,
    project,
    activeVariationId,
    createOrUpdateStyle,
    activeComponentState,
    setActiveComponentState,
    activeDevice
  } = props;
  const { selector, styles, variations } = activeComponent;

  const variation = variations.find(v => v.id == activeVariationId);

  let selectors = [selector];
  if (variation) selectors.push(variation.selector);
  if (activeComponentState) selectors.push(`:${activeComponentState}`);

  let style = getStyles(styles, selectors, activeVariationId, activeDevice);

  const updateProperty = async (name, value) => {
    let newStyles = {
      ...style.active
    };

    if (!!value) {
      newStyles[name] = value;
    } else {
      delete newStyles[name];
    }

    const response = await createOrUpdateStyle({
      variables: {
        componentId: activeComponent.id,
        rootComponentId: rootComponent.id,
        variationId: activeVariationId,
        selectors,
        device: activeDevice.toUpperCase(),
        properties: objectDiff(style.inherited, newStyles)
      }
    });
  };

  // let parentComponentStyles = {};
  // if (parentComponent) {
  //   parentComponentStyles = getStyleProperties(parentComponent.styles);
  // }

  // let containerComponentStyles = {};
  // if (containerComponent) {
  //   containerComponentStyles = getStyleProperties(containerComponent.styles);
  // }

  return (
    <Container>
      <StyleEditors
        editorKey={`${activeComponent.id}:${activeComponentState}:${activeVariationId}:${activeDevice}`}
        style={style}
        onChange={updateProperty}
        project={project}
      />
    </Container>
  );
};
export default Editors;
