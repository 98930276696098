import React, { useState } from "react";

import classnames from "classnames";

import Size from "./common/Size";

import { Col, Row, EditorContainer, EditorTitle } from "../styles";

import styled from "styled-components";

const RadiusSides = styled.div`
  font-size: 22px;
  padding-right: 5px;

  a {
    margin: 5px;
    color: #999;
    cursor: pointer;

    &.active {
      color: #fff;
    }
  }
`;

const RadiusSidesContainer = styled.div`
  .row {
    margin-bottom: 5px;
  }
`;

const RadiusSideContainer = styled.div`
  display: flex;
  align-items: center;

  .side {
    width: 15px;
    height: 15px;
    border: 2px solid #999;
    margin-right: 5px;

    &.top-left {
      border-left-color: #fff;
      border-top-color: #fff;
    }

    &.top-right {
      border-right-color: #fff;
      border-top-color: #fff;
    }

    &.bottom-left {
      border-left-color: #fff;
      border-bottom-color: #fff;
    }

    &.bottom-right {
      border-right-color: #fff;
      border-bottom-color: #fff;
    }
  }
`;

const Editor = ({ style, onChange, project }) => {
  const { active, inherited } = style;

  const values = {
    ...inherited,
    ...active
  };

  const isChanged = name => !!active[name];
  const hasValue = name => !!values[name];

  const borderSideChanged =
    isChanged("border-top-left-radius") ||
    isChanged("border-top-right-radius") ||
    isChanged("border-bottom-left-radius") ||
    isChanged("border-bottom-right-radius");
  const borderSideHasValue =
    hasValue("border-top-left-radius") ||
    hasValue("border-top-right-radius") ||
    hasValue("border-bottom-left-radius") ||
    hasValue("border-bottom-right-radius");
  const [showSides, setShowSides] = useState(
    borderSideChanged || borderSideHasValue
  );

  return (
    <>
      <EditorContainer>
        <EditorTitle
          className={classnames({
            changed: isChanged("border-radius") || borderSideChanged,
            hasValue: hasValue("border-radius") || borderSideHasValue
          })}
        >
          <span>Radius</span>
        </EditorTitle>
        <RadiusSides>
          <a
            className={showSides ? "" : "active"}
            onClick={() => setShowSides(false)}
          >
            <i className="fad fa-border-outer"></i>
          </a>
          <a
            className={showSides ? "active" : ""}
            onClick={() => setShowSides(true)}
          >
            <i className="fad fa-border-style-alt"></i>
          </a>
        </RadiusSides>
        <Size
          key="border-radius"
          name="border-radius"
          title="Radius"
          value={values["border-radius"]}
          isChanged={isChanged("border-radius")}
          hasValue={hasValue("border-radius")}
          onChange={onChange}
        />
      </EditorContainer>
      {showSides && (
        <EditorContainer>
          <div style={{ flex: 1, minWidth: 70 }}></div>
          <RadiusSidesContainer>
            <Row className="row">
              <Col>
                <RadiusSideContainer>
                  <div className="side top-left"></div>
                  <Size
                    key="border-top-left-radius"
                    name="border-top-left-radius"
                    value={values["border-top-left-radius"]}
                    isChanged={isChanged("border-top-left-radius")}
                    hasValue={hasValue("border-top-left-radius")}
                    onChange={onChange}
                  />
                </RadiusSideContainer>
              </Col>
              <Col>
                <RadiusSideContainer>
                  <div className="side top-right"></div>
                  <Size
                    key="border-top-right-radius"
                    name="border-top-right-radius"
                    value={values["border-top-right-radius"]}
                    isChanged={isChanged("border-top-right-radius")}
                    hasValue={hasValue("border-top-right-radius")}
                    onChange={onChange}
                  />
                </RadiusSideContainer>
              </Col>
            </Row>
            <Row>
              <Col>
                <RadiusSideContainer>
                  <div className="side bottom-left"></div>
                  <Size
                    key="border-bottom-left-radius"
                    name="border-bottom-left-radius"
                    value={values["border-bottom-left-radius"]}
                    isChanged={isChanged("border-bottom-left-radius")}
                    hasValue={hasValue("border-bottom-left-radius")}
                    onChange={onChange}
                  />
                </RadiusSideContainer>
              </Col>
              <Col>
                <RadiusSideContainer>
                  <div className="side bottom-right"></div>
                  <Size
                    key="border-bottom-right-radius"
                    name="border-bottom-right-radius"
                    value={values["border-bottom-right-radius"]}
                    isChanged={isChanged("border-bottom-right-radius")}
                    hasValue={hasValue("border-bottom-right-radius")}
                    onChange={onChange}
                  />
                </RadiusSideContainer>
              </Col>
            </Row>
          </RadiusSidesContainer>
        </EditorContainer>
      )}
    </>
  );
};
export default Editor;
