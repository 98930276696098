import { gql } from "@apollo/client";

export const GET_UTILITIES = gql`
  query getUtilities($projectId: Int!) {
    utilities(projectId: $projectId) {
      id
      name
      selector
      styles {
        id
        utilityId
        variationId
        device
        selectors
        properties
      }
    }
  }
`;

export const GET_UTILITY = gql`
  query getUtility($id: Int!) {
    utility(id: $id) {
      id
      name
      selector
      styles {
        id
        utilityId
        variationId
        device
        selectors
        properties
        utility {
          id
          styles {
            id
            properties
          }
        }
      }
    }
  }
`;

export const CREATE_UTILITY = gql`
  mutation CreateUtility($projectId: Int!, $selector: String!) {
    createUtility(input: { projectId: $projectId, selector: $selector }) {
      success
      error
      utility {
        id
        name
        selector
      }
    }
  }
`;

export const UPDATE_UTILITY = gql`
  mutation UpdateUtility($id: Int!, $selector: String) {
    updateUtility(input: { id: $id, selector: $selector }) {
      success
      error
      utility {
        id
        name
        selector
      }
    }
  }
`;

export const DELETE_UTILITY = gql`
  mutation DeleteUtility($id: Int!) {
    deleteUtility(input: { id: $id }) {
      success
      error
    }
  }
`;

export const CREATE_OR_UPDATE_UTILITY_STYLE = gql`
  mutation CreateOrUpdateUtilityStyle(
    $id: Int
    $utilityId: Int
    $variationId: Int
    $device: Device
    $selectors: [String!]
    $properties: JSON
  ) {
    createOrUpdateUtilityStyle(
      input: {
        id: $id
        utilityId: $utilityId
        variationId: $variationId
        device: $device
        selectors: $selectors
        properties: $properties
      }
    ) {
      success
      error
      style {
        id
        utilityId
        variationId
        device
        selectors
        properties
        utility {
          id
          styles {
            id
            properties
          }
        }
      }
    }
  }
`;
