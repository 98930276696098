import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Formik } from "formik";

import styled from "styled-components";

import { useQuery, useMutation, gql } from "@apollo/client";

const CREATE_USER = gql`
  mutation CreateUser(
    $name: String
    $email: String!
    $password: String!
    $inviteCode: String!
  ) {
    createUser(
      input: {
        email: $email
        name: $name
        password: $password
        inviteCode: $inviteCode
      }
    ) {
      success
      error
      token
      user {
        id
        email
      }
    }
  }
`;

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  .auth {
    flex: 1;
    background-color: #fff;
    padding: 30px;
    border-radius: 25px;
    box-shadow: 1px 1px 5px #ccc;
  }

  label {
    color: #999;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 3px;
  }

  .main-error {
    background-color: #ad2d39;
    border-radius: 4px;
    color: #fff;
    padding: 10px;
    margin-bottom: 20px;
  }

  .footer {
    margin-top: 25px;
    font-size: 14px;
  }

  .error {
    color: red;
    font-size: 12px;
  }
`;

const Register = () => {
  const [error, setError] = useState(false);
  const [createUser, { data }] = useMutation(CREATE_USER);

  return (
    <Container className="container">
      <div className="auth">
        <div className="header">
          <img className="logo" src="/logo.png" />
          <h2 className="title">Sign up for an account</h2>
        </div>

        <div className="body">
          <Formik
            initialValues={{
              email: "",
              name: "",
              password: "",
              inviteCode: "",
            }}
            validate={(values) => {
              const errors = {};
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }

              if (!values.name) {
                errors.name = "Required";
              }

              if (!values.password) {
                errors.password = "Required";
              }

              if (!values.inviteCode) {
                errors.inviteCode = "Required";
              }

              return errors;
            }}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              setError(false);

              const response = await createUser({ variables: values });

              if (
                response &&
                response.data &&
                response.data.createUser &&
                response.data.createUser.error
              ) {
                setError(response.data.createUser.error);
              }

              if (
                response &&
                response.data &&
                response.data.createUser &&
                response.data.createUser.success
              ) {
                localStorage.setItem(
                  "monad:token",
                  response.data.createUser.token
                );
                window.location.replace("/components");
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit} className="form">
                {error && <div className="main-error">{error}</div>}

                <div className="form-group">
                  <label>
                    Full name
                    {errors.name && touched.name && (
                      <>
                        {" "}
                        &mdash; <span className="error">{errors.name}</span>
                      </>
                    )}
                  </label>
                  <input
                    className="form-control"
                    placeholder="Name"
                    type="text"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Email Address
                    {errors.email && touched.email && (
                      <>
                        {" "}
                        &mdash; <span className="error">{errors.email}</span>
                      </>
                    )}
                  </label>
                  <input
                    className="form-control"
                    placeholder="Email address"
                    type="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Password
                    {errors.password && touched.password && (
                      <>
                        {" "}
                        &mdash; <span className="error">{errors.password}</span>
                      </>
                    )}
                  </label>
                  <input
                    className="form-control"
                    placeholder="Password"
                    type="password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </div>

                <div className="form-group">
                  <label>
                    Invite Code
                    {errors.inviteCode && touched.inviteCode && (
                      <>
                        {" "}
                        &mdash;{" "}
                        <span className="error">{errors.inviteCode}</span>
                      </>
                    )}
                  </label>
                  <input
                    className="form-control"
                    placeholder="Invite Code"
                    type="text"
                    name="inviteCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.inviteCode}
                  />
                </div>

                <div className="button">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={isSubmitting}
                  >
                    Sign Up
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>

        <div className="footer">
          <p>
            Already have an account? <Link to="/login">Sign In</Link>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default Register;
